/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Input, Table, Button, Spinner } from 'reactstrap';

import { smsTemplateService } from '../../../services/sms-template-service';

class SmsTemplates extends Component {

    INITIAL_STATE = {
        lotterySmsTemplates: [],
        packageSmsTemplates: [],
        commonSmsTemplates: [],
        showLoadingSpinner: true
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.INITIAL_STATE
        }
    }

    componentDidMount = () => {
        smsTemplateService.getLotterySmsTemplates().then((result) => {
            this.setState({
                lotterySmsTemplates: result.data,
                showLoadingSpinner: false
            });


        }).catch((error) => {
            console.log(error);
        });

        smsTemplateService.getPackageSmsTemplates().then((result) => {
            this.setState({
                packageSmsTemplates: result.data,
                showLoadingSpinner: false
            });

        }).catch((error) => {
            console.log(error);
        });

        smsTemplateService.getCommonSmsTemplates().then((result) => {
            this.setState({
                commonSmsTemplates: result.data,
                showLoadingSpinner: false
            });

        }).catch((error) => {
            console.log(error);
        });

    }

    handleSubmit = () => {
        //TODO: Save
    }

    templateTextChangeHandler = (e, index) => {
        let smsTemplates = { ...this.state.smsTemplates };
        smsTemplates[index][e.target.name] = e.target.value;
        this.setState([smsTemplates]);
    }

    validateAndRetrieveState = () => {
        const { smsTemplates } = this.state;
        // if there is any validations
        return smsTemplates;
    }

    renderLotterySmsTemplates = () => {
        const { lotterySmsTemplates } = this.state;
        if (this.state.showLoadingSpinner) {
            return (<tr className="text-center"><td colSpan="4"><Spinner color="primary" /></td></tr>);
        } else {
            return (
                lotterySmsTemplates && lotterySmsTemplates.map((template, index) => {
                    return <tr key={index}>
                        <th className="font-weight-normal">{template.name}</th>
                        <td>
                            <Input type="textarea" rows="4" name="en" value={template.en} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="ta" value={template.ta} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="si" value={template.si} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                    </tr>
                })
            )
        }
    }

    renderPackageSmsTemplates = () => {
        const { packageSmsTemplates } = this.state;
        if (this.state.showLoadingSpinner) {
            return (<tr className="text-center"><td colSpan="4"><Spinner color="primary" /></td></tr>);
        } else {
            return (
                packageSmsTemplates && packageSmsTemplates.map((template, index) => {
                    return <tr key={index}>
                        <th className="font-weight-normal">{template.name}</th>
                        <td>
                            <Input type="textarea" rows="4" name="en" value={template.en} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="ta" value={template.ta} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="si" value={template.si} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                    </tr>
                })
            )
        }
    }

    renderCommonSmsTemplates = () => {
        const { commonSmsTemplates } = this.state;
        if (this.state.showLoadingSpinner) {
            return (<tr className="text-center"><td colSpan="4"><Spinner color="primary" /></td></tr>);
        } else {
            return (
                commonSmsTemplates && commonSmsTemplates.map((template, index) => {
                    return <tr key={index}>
                        <th className="font-weight-normal">{template.name}</th>
                        <td>
                            <Input type="textarea" rows="4" name="en" value={template.en} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="ta" value={template.ta} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="si" value={template.si} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                    </tr>
                })
            )
        }
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <h3>SMS Templates</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <label style={{ paddingLeft: '10px' }}><h6>Lottery SMS Templates</h6></label>
                        <div className="border bg-white pb-4 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>
                            <Table className="text-muted">
                                <thead>
                                    <tr>
                                        <th className="border-0">SMS Name</th>
                                        <th className="border-0">English</th>
                                        <th className="border-0">Tamil</th>
                                        <th className="border-0">Sinhala</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderLotterySmsTemplates()}
                                </tbody>
                            </Table>

                            <div className="text-right pr-3">
                                {/* <Button color="primary" onClick={this.handleSubmit}>Submit</Button> */}
                            </div>
                        </div>
                        <br />
                        <label style={{ paddingLeft: '10px' }}><h6>Package SMS Templates</h6></label>
                        <div className="border bg-white pb-4 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>
                            <Table className="text-muted">
                                <thead>
                                    <tr>
                                        <th className="border-0">SMS Name</th>
                                        <th className="border-0">English</th>
                                        <th className="border-0">Tamil</th>
                                        <th className="border-0">Sinhala</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderPackageSmsTemplates()}
                                </tbody>
                            </Table>

                            <div className="text-right pr-3">
                                {/* <Button color="primary" onClick={this.handleSubmit}>Submit</Button> */}
                            </div>
                        </div>
                        <br />
                        <label style={{ paddingLeft: '10px' }}><h6>Common SMS Templates</h6></label>
                        <div className="border bg-white pb-4 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>
                            <Table className="text-muted">
                                <thead>
                                    <tr>
                                        <th className="border-0">SMS Name</th>
                                        <th className="border-0">English</th>
                                        <th className="border-0">Tamil</th>
                                        <th className="border-0">Sinhala</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderCommonSmsTemplates()}
                                </tbody>
                            </Table>

                            <div className="text-right pr-3">
                                {/* <Button color="primary" onClick={this.handleSubmit}>Submit</Button> */}
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        )
    }
}

export default SmsTemplates;
