/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import Toast from '../common/custom-controls/Toast';
import { errorCode } from "../../utils/error-code";
import CustomGrid from '../common/custom-controls/CustomGrid';
import { subscriberService } from '../../services/subscriber-service';
import '../custom.css';
import NicChangeFiles from './NicChangeFiles';

class SubscribersEdit extends Component {

    GRID_CONFIG = {
        columns: [

            {
                id: 2,
                name: 'changeReason',
                headerTitle: 'Reason',
                canSort: true,
                width: '30%'
            },
            {
                id: 3,
                name: 'remarks',
                headerTitle: 'Remarks',
                canSort: false,
                width: '35%'
            },
            {
                id: 4,
                name: 'changeDate',
                headerTitle: 'Change Date',
                canSort: true,
                width: '20%'
            },
            {
                id: 5,
                name: 'createdByName',
                headerTitle: 'Changed By',
                canSort: true,
                width: '15%'
            }

        ],
        gridData: [],
        meta: {
            pageSize: 8,
            showLoadingSpinner: true
        }

    }

    INITIAL_STATE = {
        showModal: false,
        submitted: false,
        toast: {
            type: '',
            message: ''
        },
        gridConfig: this.GRID_CONFIG,
    }

    validator = null;

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE,
            id: props.subscriberId,
            showModal: props.showModal,
            onClose: props.onClose
        }
        this.toggle = this.toggle.bind(this);
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    componentDidMount = () => {
        this.updateGridState();
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    updateGridState = () => {
        let { gridConfig, id } = this.state;
        subscriberService.nicChangeHistory(id).then((results) => {
            if (results.statusCode === 200) {
                gridConfig.gridData = results.data.map(data => {
                    data.changeDate = data.changeDate.substring(0, 10);     //Display date only
                    return data;
                })
                gridConfig.meta.showLoadingSpinner = false;
                this.setState({ gridConfig });
            } else {
                gridConfig.meta.showLoadingSpinner = false;
                this.setState({
                    gridConfig,
                    toast: {
                        type: 'warning',
                        message: results.data.description
                    }
                })
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            this.setState({
                gridConfig,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }


    renderImageContent(src, index) {
        return (
            // <div> onClick={(e) => this.openModal(e, index)}>
            <div>
                <img src={src} key={src} />
            </div>
        )
    }

    renderGrid = () => {
        const { gridConfig } = this.state;

        if (gridConfig.gridData) {
            return (
                <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick}
                    selectHandler={this.handleResponce} toggleModal={'collapse'}></CustomGrid>
            )
        }
    }

    render() {
        const { toast } = this.state;
        return (
            <Modal size="lg" isOpen={this.state.showModal} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <div className="classic-tabs" >
                    <ul className="nav tabs-primary border-bottom" id="myClassicTab" role="tablist" style={{ fontSize: '15px', padding: '5px', borderRadius: '.5rem' }}>
                        <li className="nav-item" >
                            <a className="nav-link waves-light active show text-muted" id="profile-tab-classic" data-toggle="tab" href="#history"
                                role="tab" aria-controls="profile-classic" aria-selected="true"> NIC Change History</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link waves-light text-muted" id="follow-tab-classic" data-toggle="tab" href="#image-show" role="tab"
                                aria-controls="follow-classic" aria-selected="false">NIC Change Document History</a>
                        </li>
                    </ul>

                    <div className="tab-content" id="myClassicTabContent">
                        <div className="tab-pane fade active show" id="history" role="tabpanel" aria-labelledby="profile-tab-classic">
                            <ModalBody>
                                {this.renderGrid()}
                            </ModalBody>

                        </div>
                        <div className="tab-pane fade" id="image-show" role="tabpanel" aria-labelledby="follow-tab-classic">
                            <ModalBody>
                                <NicChangeFiles id={this.state.id} />
                            </ModalBody>
                        </div>
                    </div>
                </div>
                <ModalFooter>
                    <Button outline color="danger" onClick={this.handleClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

SubscribersEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
}

export default SubscribersEdit;
