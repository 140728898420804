import React, { Component } from 'react'
import { Modal, ModalBody, Form, Row, Col, Label, FormGroup, Button, ModalHeader, ModalFooter, Badge } from 'reactstrap'
import Input from 'reactstrap/lib/Input';
import { reportService } from '../../services/report-service';
import { drawService } from '../../services/draw-service';
import { lotteryService } from '../../services/lottery-service';
import Toast from '../common/custom-controls/Toast';
import SimpleReactValidator from 'simple-react-validator';
import SubmitButton from '../common/custom-controls/SubmitButton';
import { filter } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import { days, HTTP_STATUS } from '../../utils/meta-data';

export class DrawModalView extends Component {

    validator = null;
    INIT_FORM_STATE = {
        submitted: false,
        drawDays: '',
        drawFormData: {
            lotteryId: "",
            drawNumber: "",
            drawDate: "",
            drawTime: "",
            jackpotPrize: "",
            salesStartOn: "",
            salesEndOn: "",
            txnId: uuidv1()
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            lotteries: [],
            drawStatus: [],
            toast: {
                type: '',
                message: ''
            },
            drawId: props.drawId,
            showModal: props.showModal,
            onClose: props.onClose,
            ...this.INIT_FORM_STATE
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                drawNumber: {
                    required: true
                },
                drawDate: {
                    required: true
                }
            }
        });
    }

    componentDidMount() {
        this.loadInitialData()
    }

    loadInitialData() {
        const { drawData } = this.props;
        if (this.state.drawId) {
            this.setState(prevState => ({
                drawFormData: {
                    ...prevState.drawFormData,
                    lotteryId: drawData.lotteryId,
                    drawNumber: drawData.drawNumber,
                    drawDate: drawData.drawDate,
                    drawTime: drawData.drawTime,
                    jackpotPrize: drawData.jackpotPrize,
                    salesStartOn: drawData.salesStartOn,
                    salesEndOn: drawData.salesEndOn
                }
            }));
            this.callGetLotteryDraws(drawData.lotteryId);
        } else {
            reportService.getLotteryList().then((results) => {
                if (results.statusCode === HTTP_STATUS.OK) {
                    if (results.data) {
                        results.data = filter(results.data, { isActive: true, isArchived: false });
                        this.setState({
                            lotteries: results.data
                        });
                        this.handleOnDropdownSelected(results.data[0].name);
                    }
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        }
    }

    handleFormValueChange = (e) => {
        let drawFormData = { ...this.state.drawFormData };
        if (e.target.name === 'jackpotPrize') {
            drawFormData[e.target.name] = Number(e.target.value);
        } else {
            drawFormData[e.target.name] = e.target.value;
        }
        this.setState({
            drawFormData
        });
    }

    handleToastClose = () => {
        this.setState({
            toast: {
                type: '',
                message: ''
            }
        })
    }

    handleOnDropdownSelected = (value) => {
        const { id } = filter(this.state.lotteries, { name: value })[0];
        let drawFormData = { ...this.state.drawFormData };
        if (drawFormData.lotteryId !== id) {
            drawFormData.lotteryId = id;
            this.callGetLotteryDraws(id);
        }
        this.setState({
            drawFormData
        });
    }

    submitCreateDrawDetail = (e) => {
        e.preventDefault();
        const drawFormData = { ...this.state.drawFormData };
        if (this.validator.allValid()) {
            this.setState({
                submitted: true
            });
            this.callNewDrawCreation(drawFormData);
        } else {
            this.validator.showMessages();
        }
    }

    submitUpdateDrawDetail = (e) => {
        e.preventDefault();
        const drawFormData = { ...this.state.drawFormData };
        if (this.validator.allValid()) {
            this.setState({
                submitted: true
            });
            this.callUpdateDraw(drawFormData);
        } else {
            this.validator.showMessages();
        }
    }

    callNewDrawCreation = (drawFormData) => {
        drawService.createNewDraw(drawFormData).then((drawResponse) => {
            let response = JSON.parse(JSON.stringify(drawResponse));
            console.log(response);
            if (response && (response.statusCode === HTTP_STATUS.OK) && response.data) {
                if (response.data.message) {
                    this.setState({
                        ...this.INITIAL_STATE
                    });
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'success',
                            message: response.data.message
                        }
                    });
                    this.popUpCloseDelay();
                } else {
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: "Draw creation failed."
                        }
                    });
                }
            } else {
                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: response.data.description
                    }
                });
            }
        }).catch((error) => {
            console.log(error);

            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            });
        });
    }

    callUpdateDraw = (drawFormData) => {
        drawService.updateDrawDetail(this.props.drawData.id, drawFormData).then((drawResponse) => {
            let response = JSON.parse(JSON.stringify(drawResponse));
            if (response && (response.statusCode === HTTP_STATUS.OK) && response.data) {
                if (response.data.isUpdated) {
                    this.setState({
                        ...this.INITIAL_STATE
                    });
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'success',
                            message: "Successfully updated."
                        }
                    });
                    this.popUpCloseDelay();
                } else {
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: "Update failed."
                        }
                    });
                }
            } else {
                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: response.data.description
                    }
                });
            }
        }).catch((error) => {
            console.log(error);

            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            });
        });
    }

    callGetLotteryDraws = (id) => {
        lotteryService.getAllLotteryDraws(id).then((lotteryDrawResponse) => {
            let response = JSON.parse(JSON.stringify(lotteryDrawResponse));
            if (response && response.statusCode === HTTP_STATUS.OK) {
                if (response.data.length > 0) {
                    const matchDays = response.data.map((drawDetail) => {
                        return filter(days, { id: drawDetail.drawDay })[0]
                    }).map((matchDay) => {
                        return matchDay.name;
                    }).join(", ");
                    this.setState({
                        drawDays: matchDays
                    });
                }
            } else {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: response.data.description
                    }
                });
            }
        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            });
        });
    }

    popUpCloseDelay = () => {
        setTimeout(() => {
            this.state.onClose();
            this.props.onRefresh();
        }, 2000);
    }

    render() {
        const { lotteries, toast, drawId, showModal, onClose, drawFormData, submitted } = this.state;
        const { drawData } = this.props;
        return (
            <Modal size="md" isOpen={showModal} toggle={onClose} >

                <Toast handleOnClose={this.handleToastClose} isOpen={!!toast.message} type={toast.type} message={toast.message} />

                <ModalHeader>
                    {
                        drawId ? `Edit Draw: ${drawId}` : `New Draw`
                    }
                </ModalHeader>

                <ModalBody>
                    <Form>

                        <FormGroup row>
                            <Label for="lotteryList" sm={4}>Lottery</Label>
                            <Col sm={8}>
                                {
                                    drawId ?
                                        <Input type="text" id="lotteryName" placeholder="Lottery Name" value={drawData.lotteryName} disabled /> :
                                        <Input type="select" name="lotteryName" id="lotteryList" onChange={(event) => this.handleOnDropdownSelected(event.target.value)}>
                                            {
                                                lotteries && lotteries.map((item, index) => {
                                                    if (item.isActive && !item.isArchived) {
                                                        return (
                                                            <option key={index}>{item.name}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </Input>
                                }
                            </Col>
                        </FormGroup>

                        <FormGroup row className="required">
                            <Label for="drawNumber" sm={4}>Draw Number</Label>
                            <Col sm={8}>
                                <Input type="text"
                                    id="drawNumber"
                                    name="drawNumber"
                                    min="1997-01-01" max="2020-12-31"
                                    value={drawFormData.drawNumber}
                                    onChange={this.handleFormValueChange}
                                    disabled={(drawData.statusId === 2) || (drawData.statusId === 3)} />
                                <span className="text-danger"><small>{this.validator.message('Draw Number', drawFormData.drawNumber, 'required')}</small></span>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="jackpotPrize" sm={4}>Jackpot Prize</Label>
                            <Col sm={8}>
                                <Input type="number"
                                    id="jackpotPrize"
                                    name="jackpotPrize"
                                    placeholder="Jackpot Prize"
                                    value={drawFormData.jackpotPrize}
                                    onChange={this.handleFormValueChange} />
                            </Col>
                        </FormGroup>

                        <FormGroup row className="required">
                            <Label for="drawDate" sm={4}>Draw Date</Label>
                            <Col sm={8}>
                                <Input
                                    type="date"
                                    id="drawDate"
                                    name="drawDate"
                                    placeholder="draw Date"
                                    value={drawFormData.drawDate}
                                    onChange={this.handleFormValueChange}
                                />
                                <span className="text-danger"><small>{this.validator.message('Draw Date', drawFormData.drawDate, 'required')}</small></span>
                                <Badge color="warning" className="wrap-text my-2">Draw dates : {this.state.drawDays}</Badge>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="drawTime" sm={4}>Draw Time</Label>
                            <Col sm={8}>
                                <Input
                                    type="time"
                                    name="drawTime"
                                    id="drawTime"
                                    placeholder="Draw Time"
                                    value={drawFormData.drawTime}
                                    onChange={this.handleFormValueChange}
                                />
                            </Col>
                        </FormGroup>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="salesStartOn">Sales Start On</Label>
                                    <Input
                                        type="time"
                                        name="salesStartOn"
                                        id="salesStartOn"
                                        placeholder="Sales Start On"
                                        value={drawFormData.salesStartOn}
                                        onChange={this.handleFormValueChange}
                                        disabled={(drawData.statusId === 2) || (drawData.statusId === 3)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="salesEndOn">Sales End On</Label>
                                    <Input
                                        type="time"
                                        name="salesEndOn"
                                        id="salesEndOn"
                                        placeholder="Sales End On"
                                        value={drawFormData.salesEndOn}
                                        onChange={this.handleFormValueChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button outline color="danger" onClick={onClose}>Cancel</Button>
                    <SubmitButton shouldDisable={submitted} submitted={submitted}
                        onSubmit={drawId ? this.submitUpdateDrawDetail : this.submitCreateDrawDetail}></SubmitButton>
                </ModalFooter>

            </Modal>
        )
    }
}

export default DrawModalView
