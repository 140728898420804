/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get, post, put, remove, patch, fileUpload } from '../utils/http';

const getAll = () => {
    return get(`customers`);
}

//---------------------Get All subscribers details---------------------
const getAllSubscribers = (pagination, data) => {
    return get(`customers/package-summary?` +
        `limit=${pagination.pageSize}` +
        `&pageNo=${pagination.selectedPage}` +
        `&orderBy=${pagination.orderBy}` +
        `&sortBy=${pagination.sortBy}` +
        `&mobileNumber=${data.mobile}` +
        `&status=${data.status}` +
        `&packageId=${data.package}`);
}

const getAllSubscribersLog = (pagination, data) => {
    return get(`customers/subscription-log?` +
        `limit=${pagination.pageSize}` +
        `&pageNo=${pagination.selectedPage}` +
        `&orderBy=${pagination.orderBy}` +
        `&sortBy=${pagination.sortBy}` +
        `&mobileNumber=${data.mobile}` +
        `&eventsType=${data.event}` +
        `&packageId=${data.package}`);
}

//---------------------Get Individual subscribers details---------------------
const getIndividualSubscribers = (pagination, data) => {
    return get(`customers/package-summary?` +
        `limit=${pagination.pageSize}` +
        `&pageNo=${pagination.selectedPage}` +
        `&orderBy=${pagination.orderBy}` +
        `&sortBy=${pagination.sortBy}` +
        `&mobileNumber=${data.mobile}` +
        `&status=${data.status}` +
        `&packageId=${data.package}`);
}

const getIndividualSubscribersLog = (pagination, data) => {
    return get(`customers/subscription-log?` +
        `limit=${pagination.pageSize}` +
        `&pageNo=${pagination.selectedPage}` +
        `&orderBy=${pagination.orderBy}` +
        `&sortBy=${pagination.sortBy}` +
        `&mobileNumber=${data.mobile}` +
        `&eventsType=${data.event}` +
        `&packageId=${data.package}` +
        `&dateFrom=${data.startDate}` +
        `&dateTo=${data.endDate}`);
}

const resendSMS = (id) => {
    return post(`customers/resend-sms?smsLogId=${id}`);
}

const getSubscribers = (id) => {
    return get(`customers/${id}`);
}

const getSubscribersByMobile = (number) => {
    return get(`customers?mobileNumber=${number}`);
}

const save = (subscriber) => {
    if (subscriber.id) {
        return put(`customers/${subscriber.id}`, subscriber)
    } else {
        return post(`customers`, subscriber)
    }
}


const updateSubscribers = (subscriber) => {
    return put(`customers/${subscriber.id}`, subscriber);
}

const archiveSubscribers = (subscriberId) => {
    return patch(`customers/archive/${subscriberId}`);
}

const archiveBulkSubscribers = (bulkSubscriberId, status) => {
    return put(`customers/archive/${status}/${bulkSubscriberId}`, bulkSubscriberId);
}

const deleteSubscribers = (subscriberId) => {
    return remove(`customers/${subscriberId}`);
}

const deleteBulkSubscribers = (bulkSubscriberId) => {
    return remove(`customers/archive/${bulkSubscriberId}`);
}

const singleUnsubscribe = (mobile, packageId) => {
    return put(`lottery-packages/subscribers/${mobile}/packages/${packageId}/unsubscribe`);
}

const allUnsubscribeById = (subscriberId) => {
    return put(`lottery-packages/subscribers/${subscriberId}/unsubscribe`);
}

const getSmsEventType = () => {
    return get(`customers/sms-event-types`);
}

const uploadNicEditFile = (file, id) => {
    let results = fileUpload(`customer-document/upload/${id}/NIC_CHANGE_UPLOADS`, file);
    return results;
}

//===========================================Blacklist_Customers==========================================//

const uploadBlacklistFile = (file) => {
    let results = fileUpload(`black-list-users`, file);
    return results;
}

const getAllBlacklistCustomers = (pagination, data) => {
    return get(`black-list-users?` +
        `limit=${pagination.pageSize}` +
        `&pageNo=${pagination.selectedPage}` +
        `&orderBy=${pagination.orderBy}` +
        `&sortBy=${pagination.sortBy}`);
}

const deleteBlacklistCustomer = (id) => {
    return remove(`black-list-users/${id}`);
}

//===========================================NIC Changes==========================================//

const nicChangeHistory = (id) => {
    return get(`customers/${id}/nic`);
}

const nicChangeDocuments = (id) => {
    return get(`customer-document/${id}/NIC_CHANGE_UPLOADS`);
}

const nicEdit = (data) => {

    let requestData = {
        newNIC: data.NIC,
        mobile: data.mobile,
        createNewUser: data.createNewUser,
        changeReasonId: data.changeReasonId,
        remarks: data.remarks,
        payload: {
            CUSTOMER_ID: data.id,
            NEW_NIC: data.NIC,
            OLD_NIC: data.oldNIC,
            REASON_ID: data.changeReasonId,
            REASON: data.changeReason,
            REMARKS: data.remarks,
            CREATE_NEW_USER: data.createNewUser
        }
    }

    return put(`customers/${data.id}/nic`, requestData);
}

const nicChangeReason = () => {
    return get(`common-options/NIC_CHANGE`);
}

const uploadInvalidFile = (file) => {
    let results = fileUpload(`sms-actions/process-bulk`, file);
    return results;
}

const singleUpload = (data) => {
    return post(`sms-actions/process-single`, data)
}

export const subscriberService = {
    getAll,
    getAllSubscribers,
    getAllSubscribersLog,
    getIndividualSubscribers,
    getIndividualSubscribersLog,
    getSubscribers,
    save,
    updateSubscribers,
    archiveSubscribers,
    deleteSubscribers,
    deleteBulkSubscribers,
    archiveBulkSubscribers,
    getSubscribersByMobile,
    singleUnsubscribe,
    allUnsubscribeById,
    getSmsEventType,
    resendSMS,
    nicChangeHistory,
    nicEdit,
    nicChangeReason,
    uploadNicEditFile,
    nicChangeDocuments,
    //=======================================Blacklist_Customers==========================================//
    uploadBlacklistFile,
    getAllBlacklistCustomers,
    deleteBlacklistCustomer,
    uploadInvalidFile,
    singleUpload
};

