/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, Container, Row, Col, Div, Button, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import { drawService } from '../../services/draw-service';
import { lotteryService } from '../../services/lottery-service';
import CustomDropdown from '../common/custom-controls/CustomDropdown';
import './../custom.css'
import { alphabetic } from '../../utils/meta-data'

class UploadResultsSMSPreview extends Component {

    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        },
        inputField: {
            noOfTickets: ''
        },
        firstField: {

        },
        secondField: {

        }
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE,
            files: [],
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.data
        };
        this.toggle = this.toggle.bind(this);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleSubmit = () => {
        const { drawResultsData } = this.props;
        console.log(drawResultsData);

        drawService.uploadResult(drawResultsData).then((results) => {
            if (results.statusCode === 200) {
                this.handleClose('success');
            }

            if (results.statusCode === 210) {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: results.data.customErrorCode
                    }
                })
            }
        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    componentDidUpdate = () => {
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {

        const { toast } = this.state;

        return (
            <Modal isOpen={this.state.showModal} className='raisePO' toggle={this.toggle} >
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>

                <Row style={{ padding: '10px', paddingLeft: '30px' }}>
                    <h5>Results SMS Template Preview</h5>
                </Row>

                <Row style={{ padding: '10px', paddingLeft: '30px', paddingRight: '20px' }}>
                    <p>{this.props.message}</p>
                </Row>

                <br />

                <Row style={{ padding: '25px' }}>
                    <Col >
                        <Button color="secondary" onClick={this.handleClose} > Cancel </Button>
                    </Col>

                    <Col className='text-right'>
                        <Button color="primary" onClick={this.handleSubmit} > Submit  </Button>
                    </Col>
                </Row>
            </Modal>

        );
    }

}

export default UploadResultsSMSPreview;