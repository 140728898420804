/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import Subscription from "../app/reports/Subscription"

export const nicPermissionTypes = [
    {
        id: 1,
        name: 'Do not Show'
    },
    {
        id: 2,
        name: 'Show whole Number'
    },
    {
        id: 3,
        name: 'Show only last 4 letters'
    }
]

export const permissions = [
    {
        id: 1,
        name: 'Lottery'
    },
    {
        id: 2,
        name: 'Draw'
    },
    {
        id: 3,
        name: 'Campaigns'
    },
    {
        id: 4,
        name: 'Packages'
    },
    {
        id: 5,
        name: 'Reports'
    },
    {
        id: 6,
        name: 'Admin Users'
    },
    {
        id: 7,
        name: 'SMS Template'
    },
    {
        id: 8,
        name: 'Subscribers'
    }
]


export const lotteryFormats = [
    {
        id: 1,
        name: 'Alphabetic'
    },
    {
        id: 2,
        name: 'Numeric'
    },
    {
        id: 3,
        name: 'Serial'
    },
    {
        id: 4,
        name: 'JSON'
    }
]

export const demandTypes = [
    {
        id: 1,
        name: 'On-Demand'
    },
    {
        id: 2,
        name: 'Subscription'
    }
]

export const days = [
    {
        id: 1,
        name: 'Monday'
    },
    {
        id: 2,
        name: 'Tuesday'
    },
    {
        id: 3,
        name: 'Wednesday'
    },
    {
        id: 4,
        name: 'Thursday'
    },
    {
        id: 5,
        name: 'Friday'
    },
    {
        id: 6,
        name: 'Saturday'
    },
    {
        id: 7,
        name: 'Sunday'
    }
]

export const salesFilter = [
    {
        id: 1,
        name: 'Date Range',
        status: 'Active'
    },
    {
        id: 2,
        name: 'Lottery',
        status: 'Active'
    },
    {
        id: 3,
        name: 'Draw',
        status: 'Active'
    },
    {
        id: 4,
        name: 'Operator/Agent',
        status: 'Active'
    },
    {
        id: 5,
        name: 'Mobile Number',
        status: 'Active'
    },
    {
        id: 6,
        name: 'Sales Channel',
        status: 'Active'
    },
    {
        id: 7,
        name: 'Subscription/On-Demand',
        status: 'Active'
    },
    {
        id: 8,
        name: 'Status',
        status: 'Active'
    }
]

export const salesChannel = [
    {
        id: 1,
        name: 'Mobile'
    },
    {
        id: 2,
        name: 'Web'
    },
    {
        id: 3,
        name: 'SMS'
    }
]

export const purchaseType = [
    {
        id: 1,
        name: 'Subscription'
    },
    {
        id: 2,
        name: 'On-Demand'
    }
]

export const status = [
    {
        id: 1,
        name: 'Sold'
    },
    {
        id: 2,
        name: 'Returned'
    },
    {
        id: 3,
        name: 'Unsoled'
    }
]

export const prizeDistributionFilter = [
    {
        id: 1,
        name: 'Date Range',
        status: 'Active'
    },
    {
        id: 2,
        name: 'Lottery',
        status: 'Active'
    },
    {
        id: 3,
        name: 'Draw',
        status: 'Active'
    },
    {
        id: 4,
        name: 'Operator/Agent',
        status: 'Active'
    },
    {
        id: 5,
        name: 'Mobile Number',
        status: 'Active'
    },
    {
        id: 6,
        name: 'Sales Channel',
        status: 'Active'
    },
    {
        id: 7,
        name: 'Subscription/On-Demand',
        status: 'Active'
    },
    {
        id: 8,
        name: 'Winning Amount',
        status: 'Active'
    },
    {
        id: 9,
        name: 'Prize Transfer Method',
        status: 'Active'
    },

    {
        id: 10,
        name: 'Status',
        status: 'Active'
    }
]

export const subscriptionFilter = [
    {
        id: 1,
        name: 'Status',
        status: 'Active'
    },
    {
        id: 2,
        name: 'Date Range',
        status: 'Active'
    },
    {
        id: 3,
        name: 'Lottery',
        status: 'Active'
    },

    {
        id: 4,
        name: 'Package',
        status: 'Active'
    },
    {
        id: 5,
        name: 'Operator/Agent',
        status: 'Active'
    },
    {
        id: 6,
        name: 'Mobile Number',
        status: 'Active'
    },
    {
        id: 7,
        name: 'Sales Channel',
        status: 'Active'
    }
]

export const subscriptionStatus = [
    {
        id: 1,
        name: 'Active'
    },
    {
        id: 2,
        name: 'Activated'
    },
    {
        id: 3,
        name: 'Deactivated'
    }
]


export const timeFilter = [
    {
        id: 1,
        name: 'Date'
    },
    {
        id: 2,
        name: 'Week'
    },
    {
        id: 3,
        name: 'Month'
    },
    {
        id: 4,
        name: 'Year'
    }
]

export const alphabetic = [
    {
        id: 'A',
        name: 'A'
    },
    {
        id: 'B',
        name: 'B'
    },
    {
        id: 'C',
        name: 'C'
    },
    {
        id: 'D',
        name: 'D'
    },
    {
        id: 'E',
        name: 'E'
    },
    {
        id: 'F',
        name: 'F'
    },
    {
        id: 'G',
        name: 'G'
    },
    {
        id: 'H',
        name: 'H'
    },
    {
        id: 'I',
        name: 'I'
    },
    {
        id: 'J',
        name: 'J'
    },
    {
        id: 'K',
        name: 'K'
    },
    {
        id: 'L',
        name: 'L'
    },
    {
        id: 'M',
        name: 'M'
    },
    {
        id: 'N',
        name: 'N'
    },
    {
        id: 'O',
        name: 'O'
    },
    {
        id: 'P',
        name: 'P'
    },
    {
        id: 'Q',
        name: 'Q'
    },
    {
        id: 'R',
        name: 'R'
    },
    {
        id: 'S',
        name: 'S'
    },
    {
        id: 'T',
        name: 'T'
    },
    {
        id: 'U',
        name: 'U'
    },
    {
        id: 'V',
        name: 'V'
    },
    {
        id: 'W',
        name: 'W'
    },
    {
        id: 'X',
        name: 'X'
    },
    {
        id: 'Y',
        name: 'Y'
    },
    {
        id: 'Z',
        name: 'Z'
    },
]

export const activeStatus = [
    {
        id: 0,
        name: 'All'
    },
    {
        id: 1,
        name: 'Subscribed'
    },
    {
        id: 2,
        name: 'Unsubscribed'
    },
]

export const SMS_TEMPLATE = {
    ANNOUNCE_WINNING_RESULT_WITH_BONUS: "ANNOUNCE_WINNING_RESULT_WITH_BONUS",
    ANNOUNCE_WINNING_RESULT: "ANNOUNCE_WINNING_RESULT" 
};

export const DRAW_STATUS = {
    NEW: 1,
    GOING: 2,
    HOLD: 3,
    CLOSE: 5,
    CANCEL: 7,
    DELAY: 8,
    REMOVE: 9
}

export const DRAW_ACTION = {
    REMOVE_DRAW: "12",
    DELAY_DRAW: "13",
}

export const HTTP_STATUS = {
    OK: 200
}