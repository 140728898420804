/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import SearchInput from '../common/custom-controls/SearchInput';
import BlacklistUpload from './BlacklistUpload';
import { subscriberService } from '../../services/subscriber-service';
import { GRID_LIMIT } from '../../utils/configs';
import ServerSideGrid from '../common/custom-controls/ServerSideGrid';
import { element } from 'prop-types';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
class AdminUsers extends Component {

    GRID_CONFIG = {
        columns: [

            {
                id: 1,
                name: 'id',
                headerTitle: 'Id',
                canSort: false,
                width: '10%'
            },
            {
                id: 2,
                name: 'mobile',
                headerTitle: 'Mobile',
                canSort: true,
                width: '80%'
            },
            {
                id: 4,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '10%',
                useTemplate: true,
                templates: [
                    {
                        type: 'DELETE',
                        template: `<button class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>`
                    }
                ]
            }
        ],
        gridData: [],
        meta: {
            pageSize: parseInt(GRID_LIMIT),
            showLoadingSpinner: true,
            totalItems: '',
            selectedPage: 1,
            orderBy: 'id',
            sortBy: 'asc'
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            confirmModal: false,
            gridConfig: this.GRID_CONFIG,
            userId: null,
            toast: {
                type: '',
                message: ''
            },
            gridData: []
        }
    }

    toggleModal = (userId) => {
        this.setState({
            showModal: true,
            userId: userId ? userId : null,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleModalClose = (user) => {

        if (user === 'success') {
            this.setState({
                toast: {
                    type: 'success',
                    message: 'You will receive an email once the file is processed.'
                }
            })
            this.updateGridState();
        }

        this.setState({ showModal: false });
    }

    //The grid response handled here
    handleResponce = (params) => {

        let { gridConfig } = this.state;

        gridConfig.meta.selectedPage = params.selectedPage;
        if (params.sortBy) {
            gridConfig.meta.orderBy = params.sortBy;
        }

        if (params.sortOrder) {
            gridConfig.meta.sortBy = params.sortOrder;
        }

        this.setState({ serverSideData: params, gridConfig });
        this.updateGridState();
    }

    handleDelete = (params) => {
        if (params === 'ok') {
            //To delete the black listed customers
            subscriberService.deleteBlacklistCustomer(this.state.userId).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        toast: {
                            type: 'success',
                            message: 'The user deleted successfully' //There is no successs msg coming
                        }
                    })
                    this.updateGridState()
                } else {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        }

        this.setState({ confirmModal: false });
    }


    updateGridState = () => {
        let { gridConfig, filterData } = this.state;

        //To get data from server-side pagination need to pass pageSize, selectedPage, orderBy, sortBy
        subscriberService.getAllBlacklistCustomers(gridConfig.meta, filterData).then((results) => {
            if (results.statusCode === 200) {

                let data = results.data.data.map((element, index) => {
                    element.tableId = element.id
                    element.id = ((results.data.page.pageNo - 1) * GRID_LIMIT) + (index + 1)
                    return element;
                })

                gridConfig.gridData = data;
                gridConfig.meta.totalItems = results.data.page.total;
                gridConfig.meta.showLoadingSpinner = false;
                this.setState({ gridConfig });
            } else {
                gridConfig.meta.showLoadingSpinner = false;
                this.setState({
                    gridConfig,
                    toast: {
                        type: 'warning',
                        message: results.data.description
                    }
                })
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            this.setState({
                gridConfig,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })

        });
    }



    renderGrid = () => {
        const { gridConfig } = this.state;
        if (gridConfig.gridData) {
            return (
                <ServerSideGrid gridConfig={gridConfig} actionHandler={this.handleActionClick} handleOrder={this.handleResponce} handlePage={this.handleResponce} toggleModal={'collapse'}></ServerSideGrid>
            )
        }
    }

    renderModal = () => {
        if (this.state.showModal) {
            return (
                <BlacklistUpload userId={this.state.userId} showModal={this.state.showModal} onClose={this.handleModalClose} />
            )
        }

        if (this.state.confirmModal) {
            return (
                <ConfirmModal isOpen={this.state.confirmModal} userId={this.state.userId} message={`Are you sure want to delete this?`}
                    onClose={this.handleDelete}></ConfirmModal>
            )
        }
    }

    componentDidMount = () => {
        this.updateGridState();
    }

    handleActionClick = (params) => {
        switch (params.type) {
            case 'DELETE':
                console.log(params.data);

                this.setState(prevState => ({
                    confirmModal: !prevState.confirmModal,
                    userId: params.data.tableId
                }));
                break;
            default:
                break;
        }
    }

    filterData = (filterText, filterColumn) => {
        let { gridConfig } = this.state;
        if (filterText) {
            gridConfig.gridData = this.state.gridData.filter((data) => {
                const columnData = data[filterColumn].toLowerCase();
                return columnData.indexOf(filterText.toLowerCase()) !== -1
            });
        } else {
            gridConfig.gridData = this.state.gridData;
        }

        this.setState({ gridConfig });
    };

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast } = this.state;
        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col sm={3}>
                        <label><h3>Blacklist Users</h3></label>
                    </Col>
                    <Col sm={6}>
                        <Form inline>
                            <FormGroup row>
                                {/* <SearchInput onChange={this.filterData} name={'fullName'} placeholder={'Blacklist Name'} className={'mr-3'} /> */}
                                {/* <Button color="primary" onClick={() => this.filterData('', 'fullName')}><i className="fas fa-search" /> Search</Button> */}
                            </FormGroup>

                        </Form>
                    </Col>
                    <Col sm={3}>
                        <Button color="success float-right" onClick={() => this.toggleModal()}><i className="fas fa-plus"></i> Add Blacklist Users</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {this.renderGrid()}
                    </Col>
                </Row>
                {this.renderModal()}
            </Container>);
    }
}

export default AdminUsers;
