/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Table, CustomInput, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import './CustomGrid.scss';
import Subscribers from '../../subscribers/Subscribers'
import { statusNew, statusTicketUploaded, statusOngoing, statusClosed, statusOnHold } from './action-template'

import PaginateServerside from './PaginateServerside';
import { subscriberService } from '../../../services/subscriber-service';
import { template } from '@babel/core';
import { METADATA_COLUMN } from '../../../utils/configs';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_SELECTED_PAGE = 1;
const METADATA = 'metaData';

class ServerSideGrid extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedPage: DEFAULT_SELECTED_PAGE,
            gridConfig: props.gridConfig,
            paginatedData: [],
            sortOrder: '',
            sortBy: '',
            checked: false,
            checkedAll: false,
            selectHandler: props.selectHandler,
            toggleModal: props.toggleModal,
            activeMultiSelect: props.activeMultiSelect,
            gridFunctions: []
        }
    }

    createActionMarkup = (markup) => {
        return { __html: markup };
    }

    renderActionTemplate = (data, index, rowIndex) => {
        let sele = ''
        data.drawAction.forEach(element => {
            sele += `<a class="dropdown-item" href="${element.actionKeyword}">${element.actionName}</a>`
        });

        var template = `<span>
        <button class="btn btn-squared-default btn-secondary dropdown-toggle sqr-btn-action" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size:12px, border-radius: 0 !important">Action</button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
        ${sele}</div></span>`

        return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'ACTION', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)
    }

    renderTemplates = (column, data, rowIndex, selected) => {

        switch (column.name) {
            case 'isActive':
                if (data.isActive) {
                    return (<span><i className="fas fa-check-square fa-2x text-success"></i></span>);
                } else {
                    return (<span><i className="fas fa-check-square fa-2x text-muted"></i></span>);
                }
            case 'status':
                if (data.status) {
                    return (<Button color='success' size='sm'><i className="fas fa-check fa-2xs"></i></Button>);
                } else {
                    return (<Button color='secondary' size='sm'><i className="fas fa-check fa-2xs"></i></Button>);
                }
            case 'multipleSelect':
                return (
                    <input id={data.id} className='selected' defaultChecked={this.state.checked} type="checkbox" onChange={(e) => this.handleSelectClick()} />
                );

            default:
                return (
                    column.templates && column.templates.map((action, index) => {
                        let template = '';
                        switch (action.type) {
                            case 'WINNER-ACTION':
                                // eslint-disable-next-line default-case
                                switch (data.status) {
                                    case 'Settled': template = '<a href="/winners/:id" style="font-size:13px" class="btn btn-squared-default btn-primary sqr-btn-action">View</a>'
                                        break;
                                    case 'Unsettled': template = '<a href="/winners/:id" style="font-size:13px" class="btn btn-squared-default btn-success sqr-btn-action">Settle Reward</a>'
                                        break;
                                }
                                return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'ACTION', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)

                            case 'ACTION':
                                return (
                                    <span key={index}>
                                        {this.renderActionTemplate(data, index, rowIndex)}
                                    </span>
                                );

                            case 'UNSUBSCRIBE':         // Create unscubscribe button for active customers
                                if (data.status) {
                                    template = '<a style="font-size:15px" class="text-primary text-decoration-none">Unsubscribe</a>'
                                } else {
                                    template = '-'
                                }
                                return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'UNSUBSCRIBE', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)

                            case 'RESEND':              // Create resend button for unsent sms
                                if (data.smsResend) {
                                    template = '<a style="font-size:15px" class="text-primary text-decoration-none">Resend</a>'
                                } else {
                                    template = '-'
                                }
                                return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'RESEND', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)

                            default:
                                return <span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: action.type, data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(action.template)} />
                        }
                    })
                );
        }
    }

    sortBy(event, orderBy) {
        event.preventDefault();
        let { gridConfig, sortOrder, sortBy, selectedPage, selectedValues } = this.state;

        if (gridConfig.gridData.length) {
            selectedPage = 1;
            sortBy = orderBy;
            sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            let params = {
                selectedPage, sortBy, sortOrder
            }

            this.props.handlePage(params);
            this.setState({ selectedPage, gridConfig, sortOrder, sortBy, selectedValues });
            this.handlePaginate(selectedPage, sortOrder, sortBy);
        }
    }

    renderTableHeader = () => {
        const { gridConfig, sortOrder, sortBy } = this.state;

        return (
            gridConfig.columns && gridConfig.columns.map((column, index) => {

                if (!column.hidden) {
                    return <th key={index} className="border-0 text-muted" width={column.width}>
                        {column.canSort ? <a href="#" onClick={(e) => { this.sortBy(e, column.name) }} className="text-decoration-none text-muted">{column.headerTitle}</a> :
                            <a href="#" className="text-decoration-none text-muted">{column.headerTitle}</a>}

                        {column.canSort ?
                            <span className="pl-1 position-relative">
                                <i className={'fas fa-caret-up position-absolute ' + ((sortOrder === 'asc' && column.name === sortBy) ? 'text-primary' : '')} style={{ top: '-2px' }}></i>
                                <i className={'fas fa-caret-down position-absolute ' + ((sortOrder === 'desc' && column.name === sortBy) ? 'text-primary' : '')} style={{ bottom: '-3px' }}></i>
                            </span> : ''
                        }
                    </th>
                } else {
                    return <th className="d-none" key={index}></th>;
                }
            })
        );
    }

    handleActionClick = (event, params) => {
        const { selectedPage } = this.state;
        event.preventDefault();
        // special handling redirects through grid actions
        if (event.target.tagName === 'A') {
            params['redirectTo'] = event.target.getAttribute('href');
        }

        params['selectedPage'] = selectedPage;

        this.props.actionHandler(params);
    }

    componentWillReceiveProps = () => {
        // REFACTORME: there is a perfomance impact. Not the best solution
        let { selectedPage } = this.state;
        this.handlePaginate(selectedPage);
    }

    renderTableContent = () => {
        const { gridConfig, paginatedData } = this.state;
        let count = 0;
        if (gridConfig.meta && gridConfig.meta.showLoadingSpinner) {
            return (<tr className="text-center"><td colSpan={gridConfig.columns.length}><Spinner color="primary" /></td></tr>);
        } else if (paginatedData.length > 0) {
            return (
                paginatedData && paginatedData.map((data, rowIndex) => {
                    if (!data.isArchived) {
                        return <tr key={rowIndex}>
                            {gridConfig.columns.map((column, colIndex) => {
                                var columnData = data[column.name];
                                if (data[METADATA] && (column.name === METADATA_COLUMN[0] || column.name === METADATA_COLUMN[1])){
                                    columnData = JSON.parse(data[METADATA])[column.name];
                                }
                                if(!data[METADATA] && (column.name === METADATA_COLUMN[0] || column.name === METADATA_COLUMN[1])){
                                    columnData = "-";
                                }
                                if (!column.hidden) {
                                    return <td className="py-3 align-middle" key={colIndex}>{column.useTemplate ? this.renderTemplates(column, data, rowIndex) :columnData}</td>
                                } else {
                                    return <td className="d-none" key={colIndex}></td>
                                }
                            })}
                        </tr>
                    }

                    else if (data.isArchived) {
                        ++count;
                        if (count === paginatedData.length) {
                            return (<tr className="text-center border-0"><td colSpan={gridConfig.columns.length}>No Data</td></tr>);
                        }
                    }

                })
            );

        } else {
            return (<tr className="text-center border-0"><td colSpan={gridConfig.columns.length}>No Data</td></tr>);
        }
    }

    handlePaginateResponse = (selectedPage) => {
        let { sortOrder, sortBy } = this.state;
        this.setState({ selectedPage });
        let params = {
            selectedPage, sortBy, sortOrder
        }
        this.props.handlePage(params);
    }

    handlePaginate = (selectedPage) => {
        const { gridConfig } = this.state;
        const paginatedData = gridConfig.gridData;
        this.setState({ paginatedData });
    }

    renderPaginate = () => {
        const { gridConfig, selectedPage } = this.state;
        if ((gridConfig.canPaginate === undefined || gridConfig.canPaginate) && gridConfig.meta.totalItems > gridConfig.meta.pageSize) {
            return (
                <div className="float-right">
                    <PaginateServerside activePage={selectedPage} totalItems={gridConfig.meta.totalItems} pageSize={gridConfig.meta.pageSize} onSelect={this.handlePaginateResponse} />
                </div>
            )
        }
    }

    render() {
        return (
            <div className="custom-grid">
                <Table className="my-3 rounded">

                    <thead className="border-0">
                        <tr>{this.renderTableHeader()}</tr>
                    </thead>

                    <tbody className="bg-white border">
                        {this.renderTableContent()}
                    </tbody>
                </Table>
                {this.renderPaginate()}
            </div>
        );
    }
}

ServerSideGrid.propTypes = {
    gridConfig: PropTypes.object
}

export default ServerSideGrid;
