/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get, post } from '../utils/http';

export const signIn = (username, password) => {
    return post(`users/login`, { username, password });
}

export const signOut = () => {
    return post(`users/logout`, {});
}

export const checkUser = () => {
    // return get(`users/session/me`, {});

    // const response = {
    //     data: {
    //         email: "admin@lucky.com",
    //         firstName: "lucky1",
    //     }
    // }

    // return response;

    return null;
}


export const checkUserPermission = (permissionsName, permissionsType) => {
    return true

}
