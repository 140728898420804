/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Label, Button } from 'reactstrap';
import queryString from 'query-string'

import { lotteryService } from '../../../services/lottery-service';
import LotteryBasics from './LotteryBasics';
import LotteryFormat from './LotteryFormat';
import LotteryDraws from './LotteryDraws';
import LotterySmsTemplates from './LotterySmsTemplates';
import Toast from '../../common/custom-controls/Toast';
import ConfirmModal from '../../common/custom-controls/ConfirmModal';

const DEFAULT_STEP = '1';
const STEP_HEADERS = [
    'Add Lottery Basic Information',
    'Confirm Lottery Format',
    'Configure Lottery',
    'Configure SMS Template'
]

class LotteryNew extends Component {

    INITIAL_STATE = {
        step: '',
        showModal: false,
        toast: {
            type: '',
            message: ''
        },
        lotteryBasics: {},
        lotteryFields: [],
        lotteryDraws: [],
        smsTemplates: []
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE
        }
    }

    handleSubmit = () => {

        let lottery = { ...this.state.lotteryBasics };
        lottery['lotteryFields'] = this.state.lotteryFields;
        lottery['lotteryDraws'] = this.state.lotteryDraws;
        lottery['smsTemplates'] = this.smsTemplates.validateAndRetrieveState();

        console.log(lottery)

        if (this.state.lotteryFields.length && this.state.lotteryDraws.length) {
            lotteryService.saveLottery(lottery).then((results) => {
                console.log(results)
                if (results.statusCode === 200 || results.statusCode === 201) {
                    this.setState({
                        toast: {
                            type: 'success',
                            message: 'Data has been saved.'
                        }
                    });
                    this.redirectToLotteries();
                } else if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    });
                }


            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Please add lottery field and draw'
                }
            })
        }
    }

    handleExit = () => {
        this.setState({ showModal: true })

    }

    handleSubmitClose = (state) => {
        if (state === 'ok') {
            this.redirectToLotteries();
        }

        this.setState({ showModal: false });
    }

    redirectToLotteries() {
        this.props.history.push(`/lotteries`);
    }

    retriveStepsState = () => {
        const { step } = this.state;
        // eslint-disable-next-line default-case
        switch (step) {
            case '1':
                let { lotteryBasics } = { ...this.state };
                lotteryBasics = this.lotteryBasics.validateAndRetrieveState();
                if (lotteryBasics) {
                    this.setState({ lotteryBasics });
                } else {
                    return false;
                }
                break;
            case '2':
                const lotteryFields = this.lotteryFields.validateAndRetrieveState();
                if (lotteryFields) {
                    this.setState({ lotteryFields });
                } else {
                    // Uncommnet if need any validations
                    return false;
                }
                break;
            case '3':
                const lotteryDraws = this.lotteryDraws.validateAndRetrieveState();
                if (lotteryDraws) {
                    this.setState({ lotteryDraws });
                } else {
                    // Uncommnet if need any validations
                    return false;
                }
                break;
            case '4':
                const smsTemplates = this.smsTemplates.validateAndRetrieveState();
                if (smsTemplates) {
                    this.setState({ smsTemplates });
                }
                break;
        }
        return true;
    }

    handleNavigate = (isNext) => {
        const { step } = this.state;
        const requestedStep = isNext ? (parseInt(step) + 1) : (parseInt(step) - 1);

        const validState = this.retriveStepsState();
        if (validState) {
            this.setState({ step: requestedStep.toString() });
            this.props.history.push(`/lotteries/new?step=${requestedStep}`);
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Please add the data'
                }
            })
        }
    }

    renderModal = () => {
        if (this.state.showModal) {
            return (
                <ConfirmModal isOpen={this.state.showModal} message={`Are you sure want to cancel?`}
                    onClose={this.handleSubmitClose}></ConfirmModal>
            )
        }
    }

    renderWizardStep = () => {
        const { step, lotteryBasics, lotteryFields, lotteryDraws, smsTemplates } = this.state;

        // eslint-disable-next-line default-case
        switch (step) {
            case '1':
                return <LotteryBasics lotteryBasics={lotteryBasics} ref={(ref) => this.lotteryBasics = ref}></LotteryBasics>;
            case '2':
                return <LotteryFormat lotteryFields={lotteryFields} lotteryName={lotteryBasics.name} ref={(ref) => this.lotteryFields = ref}></LotteryFormat>;
            case '3':
                return <LotteryDraws lotteryDraws={lotteryDraws} lotteryName={lotteryBasics.name} ref={(ref) => this.lotteryDraws = ref}></LotteryDraws>;
            case '4':
                return <LotterySmsTemplates smsTemplates={smsTemplates} lotteryName={lotteryBasics.name} ref={(ref) => this.smsTemplates = ref}></LotterySmsTemplates>;
        }
    }

    componentDidMount = () => {
        const { lotteryBasics } = this.state;
        const values = queryString.parse(this.props.location.search);

        if (values.step && Object.entries(lotteryBasics).length > 0) {
            this.setState({
                step: values.step ? values.step : DEFAULT_STEP
            });
        } else {
            this.setState({
                step: DEFAULT_STEP
            });
            this.props.history.push(`/lotteries/new?step=${DEFAULT_STEP}`);
        }
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { step, toast } = this.state;

        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col sm={6}>
                        <h4>Add New Lottery</h4>
                    </Col>
                    <Col sm={6} className="text-right">
                        <Label>{step} of 4 Steps - {STEP_HEADERS[(parseInt(step)) - 1]}</Label>
                    </Col>
                </Row>
                <div className="border bg-white p-4 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>

                    {this.renderWizardStep()}

                    <Row className="pt-3">
                        <Col md={12} className="text-right">
                            <Button outline color="danger" className="mr-2" onClick={this.handleExit}>Cancel</Button>
                            {step !== '1' ? <Button className="mr-2" onClick={() => this.handleNavigate(false)}>Previous</Button> : ''}
                            {step !== '4' ? <Button color="primary" onClick={() => this.handleNavigate(true)}>Next</Button> : ''}
                            {step === '4' ? <Button color="primary" onClick={this.handleSubmit}>Submit</Button> : ''}
                        </Col>
                    </Row>

                </div>
                {this.renderModal()}
            </Container >
        )
    }
}

export default LotteryNew;
