/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../../app/custom.css'
import { signOutAction } from './../../../store/actions/auth.actions';

class Header extends Component {

    INITIAL_STATE = {
        isOpen: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE
        };
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    navigateHome = (e) => {
        e.preventDefault();
        this.props.history.push('/dashboard');
    }

    signout = (e) => {
        e.preventDefault();
        this.props.signOutAction(this.props.history);
    }

    render() {
        const { user } = this.props;
        return (
            <Navbar color="white" expand="md" className="shadow-sm p-2  bg-white">
                
                <NavbarToggler onClick={this.toggle} />

                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink disabled href="#">Hi, {user.firstName}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#" onClick={this.signout}>Logout</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

Header.propTypes = {
    user: PropTypes.object.isRequired,
    signOutAction: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        isAuthenticated: auth.isAuthenticated,
        user: auth.user
    };
}

export default withRouter(connect(mapStateToProps, { signOutAction })(Header));
