import React, { Component } from 'react';
import ServerSideGrid from '../common/custom-controls/ServerSideGrid';
import { subscriberService } from '../../services/subscriber-service';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import { GRID_LIMIT } from '../../utils/configs';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input } from 'reactstrap';
import CustomDropdown from '../common/custom-controls/CustomDropdown';
import { reportService } from '../../services/report-service';
import { activeStatus } from '../../utils/meta-data';
import { errorCode } from './../../utils/error-code';
import Loader from './../common/custom-controls/Loader';

class IndividualPackageSummary extends Component {
    GRID_CONFIG = {
        columns: [
            {
                id: 1,
                name: 'name',
                headerTitle: 'Package',
                canSort: true,
                width: '15%'
            },
            {
                id: 2,
                name: 'subscribedTime',
                headerTitle: 'Subscribed Time',
                canSort: true,
                width: '15%'
            },
            {
                id: 3,
                name: 'unSubscribedTime',
                headerTitle: 'Unsubscribed Time',
                canSort: true,
                width: '15%'
            },
            {
                id: 4,
                name: 'status',
                headerTitle: 'Status',
                canSort: false,
                useTemplate: true,
                width: '10%'
            },
            {
                id: 5,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '15%',
                useTemplate: true,
                templates: [
                    {
                        type: 'UNSUBSCRIBE',
                        template: ``
                    }
                ]
            },
        ],
        gridData: [],
        meta: {
            pageSize: parseInt(GRID_LIMIT),
            showLoadingSpinner: true,
            totalItems: 0,
            selectedPage: 1,
            orderBy: 'id',
            sortBy: 'desc'
        }
    }

    INITIAL_STATE = {
        showModal: false,
        gridConfig: this.GRID_CONFIG,
        toast: {
            type: '',
            message: ''
        },
        gridData: [],
        serverSideData: '',
        filterData: {
            mobile: '',
            status: '',
            package: ''
        },
        packageList: [],
        subscriberMobile: '',
        subscriberPackage: '',
        showLoadingSpinner: false,
        reloader: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE,
            userData: props.userData
        }
    }

    //The unsubscription modal close handle here
    handleUnsubClose = (params) => {
        const { subscriberMobile, subscriberPackage } = this.state;

        let toast = {
            type: '',
            message: ''
        }

        if (params !== 'cancel') {
            subscriberService.singleUnsubscribe(subscriberMobile, subscriberPackage).then((results) => {
                if (results.statusCode === 200) {
                    toast = {
                        type: 'success',
                        message: 'Successfully unsubscribed.'
                    }
                    this.props.onSearch(toast);
                    this.updateGridState();
                } else {
                    //Business logic error here
                    toast = {
                        type: 'warning',
                        message: 'Invalid Request'
                    }
                    this.props.onSearch(toast);
                }
            }).catch((error) => {
                toast = {
                    type: 'warning',
                    message: 'Invalid Request'
                }
                this.props.onSearch(toast);
            });

        }
        this.setState({ showModal: false })
    }

    renderConfirmModal = () => {
        const { showModal } = this.state;
        if (showModal) {
            return (
                <ConfirmModal isOpen={showModal} message={'Are you sure you want to unsubscribe selected package?'} onClose={this.handleUnsubClose}></ConfirmModal>
            )
        }
    }

    //The grid response handled here
    handleResponce = (params) => {

        let { gridConfig } = this.state;

        gridConfig.meta.selectedPage = params.selectedPage;
        if (params.sortBy) {
            gridConfig.meta.orderBy = params.sortBy;
        }

        if (params.sortOrder) {
            gridConfig.meta.sortBy = params.sortOrder;
        }

        this.setState({ serverSideData: params, gridConfig, showLoadingSpinner: true });
        this.updateGridState();
    }

    //Input value change handle here
    handleValueChange = (e) => {
        let filterData = { ...this.state.filterData };
        filterData[e.target.name] = e.target.value;
        this.setState({
            filterData
        });
    }

    //Dropdown select handle here
    handleOnDropdownSelected = (selected, type) => {
        let filterData = { ...this.state.filterData };
        this.gridLoader();
        switch (type) {
            case 'package':
                filterData.package = selected.id;
                break;
            case 'status':
                filterData.status = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            filterData,
            toast: {
                type: '',
                message: ''
            }
        });

        //This is to give a delay to update state
        setTimeout(() => { this.updateGridState() }, 250);

    }

    // This is added to load spinner between grid filter
    gridLoader = () => {
        let { gridConfig } = this.state;

        gridConfig.gridData = [];
        gridConfig.meta.totalItems = '';
        gridConfig.meta.showLoadingSpinner = true;
        gridConfig.meta.selectedPage = 1;
        gridConfig.meta.orderBy = 'id';
        gridConfig.meta.sortBy = 'desc';

        this.setState({
            gridConfig
        })
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        if (gridConfig.gridData) {
            return (
                <ServerSideGrid gridConfig={gridConfig}
                    actionHandler={this.handleActionClick}
                    handleOrder={this.handleResponce}
                    handlePage={this.handleResponce}
                    toggleModal={'collapse'} />
            )
        }
    }

    updateGridState = () => {
        let { gridConfig, gridData, filterData, userData } = this.state;
        let toast = {}
        //Change the params as access by api
        switch (gridConfig.meta.orderBy) {
            case 'subscribedTime': gridConfig.meta.orderBy = 'startOn'; break;
            case 'unSubscribedTime': gridConfig.meta.orderBy = 'endOn'; break;
            case 'name': gridConfig.meta.orderBy = 'packageName'; break;
        }

        filterData.mobile = userData.mobileNumber;

        //To get data from server-side pagination need to pass pageSize, selectedPage, orderBy, sortBy
        subscriberService.getIndividualSubscribers(gridConfig.meta, filterData).then((results) => {
            if (results.statusCode === 200) {
                gridConfig.gridData = results.data.data;
                gridConfig.meta.totalItems = results.data.page.total;
                gridConfig.meta.showLoadingSpinner = false;
                gridData = results.data.data;
                this.setState({ gridConfig, gridData, showLoadingSpinner: false });
                this.props.onSearch(toast);
            } else {
                gridConfig.meta.showLoadingSpinner = false;
                toast = {
                    type: 'warning',
                    message: results.data.description
                }
                this.props.onSearch(toast);
                this.setState({ gridConfig })
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            toast = {
                type: 'warning',
                message: error.message
            }
            this.setState({
                gridConfig
            })
        });
    }

    componentDidMount = () => {
        let { packageList } = this.state;

        reportService.getPackageList().then((results) => {
            if (results.statusCode === 200) {
                packageList = results.data
                packageList.unshift({ id: 0, name: 'All' });  //Added 'all' at the beginning of the drop-down

                this.setState({
                    packageList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
        this.updateGridState();
    }

    // Handle refresh button click here
    handleRefresh = () => {
        this.setState({
            reloader: true,
            filterData: {
                mobile: '',
                status: '',
                package: ''
            }
        });
        setTimeout(() => {
            this.setState({
                reloader: false,
            });
            this.gridLoader();
            this.updateGridState();
        }, 1);
    }

    handleActionClick = (params) => {
        const { packageList } = this.state;
        const day = packageList.find((day) => {
            return day.name === params.data.name;
        });

        switch (params.type) {
            case 'UNSUBSCRIBE':
                if (params.data.status) {
                    let subscriberId = params.data.id;
                    this.setState({
                        showModal: true,
                        subscriberId: subscriberId,
                        subscriberMobile: params.data.mobile,
                        subscriberPackage: day.id,
                        toast: {
                            type: '',
                            message: ''
                        }
                    })
                }
                break;

            default:
                break;
        }
    }

    render() {
        const { packageList, reloader } = this.state;

        return (
            <div>
                {this.state.showLoadingSpinner ?
                    <Loader /> : ''}
                <Row style={{ padding: '10px', paddingLeft: '20px' }}>
                    <Col md={4}>
                        {reloader ? '' :
                            <CustomDropdown
                                type={'package'}
                                placeholder={{ name: 'Package' }}
                                options={packageList}
                                handleOnSelected={this.handleOnDropdownSelected} />}
                    </Col>
                    <Col md={3}>
                        {reloader ? '' :
                            <CustomDropdown
                                type={'status'}
                                placeholder={{ name: 'Status' }}
                                options={activeStatus}
                                handleOnSelected={this.handleOnDropdownSelected} />}
                    </Col>
                    <Col md={5} className='text-right'>
                        <Button className='btn-rounded' color='secondary' onClick={() => this.handleRefresh()}>
                            <i className="fas fa-redo"></i>
                        </Button>
                    </Col>
                </Row>
                {reloader ? '' : this.renderGrid()}
                {this.renderConfirmModal()}
            </div>

        )
    }
}

export default IndividualPackageSummary;
