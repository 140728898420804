/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get, put, post, remove, patch } from '../utils/http';
import { getFileByUrl } from './file-service';
import { element } from 'prop-types';

const getAllDraws = () => {
    return get(`draws`);
}

const getAllDrawStatus = () => {
    return get(`draws/draw-statuses`);
}

const ticketReturnManual = (data) => {
    // console.log(post(`ticket-return`, data))
    return post(`ticket-return`, data);
}

const getDrawById = (drawId, lotteryId) => {
    return get(`draws/${drawId}?lotteryId=${lotteryId}`);
}

const uploadResult = (data) => {
    return post(`results`, data);
}

const actionStatusChange = (actionId, drawId, lotteryId) => {
    console.log(actionId, drawId, lotteryId);
    // let data = {
    //     drawId: drawId,
    //     lotteryId: lotteryId
    // }
    // switch (actionId) {
    //     case 2: // Start sales
    //         return post(`v2/ticket-sales/draw/${drawId}/start`);   //salesStartType = 'Manual'
    //         break;
    //     case 5: // Close sales
    //         return put(`draws/sales-stop/Manual`, data);   //salesStartType = 'Manual'
    //         break;

    //     default:
    //         return patch(`draws/${drawId}/status/${actionId}?lotteryId=${lotteryId}`);
    //         break;
    // }

    return patch(`draws/${drawId}/status/${actionId}?lotteryId=${lotteryId}`);
}

const getAllPurchaseOrdersForDraw = (lotteryId, drawId, limit, offset) => {
    return get(`purchase-order?lotteryId=${lotteryId}&drawId=${drawId}&limit=${limit}&offset=${offset}`);
}

const getDrawsBySearch = (data) => {
    return get(`draws?status=${data.statusList}&drawNumber=${data.drawId}&lotteryId=${data.lotteriesList}`);
}


const createNewDraw = (data) => {
    console.log(data)
    return post(`draws`, data);
}

const updateDrawDetail = (drawId, data) => {
    return put(`draws/${drawId}`, data);
}

export const drawService = {
    getAllDraws,
    getAllDrawStatus,
    ticketReturnManual,
    getDrawById,
    uploadResult,
    actionStatusChange,
    getAllPurchaseOrdersForDraw,
    getDrawsBySearch,
    createNewDraw,
    updateDrawDetail
};
