/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner, CustomInput } from 'reactstrap';

import { lotteryService } from '../../../services/lottery-service';
import Toast from '../../common/custom-controls/Toast';

class LotteryView extends Component {

    INITIAL_STATE = {
        lotteryId: '',
        showLoadingSpinner: true,
        toast: {
            type: '',
            message: ''
        },
        lotteryBasics: {},
        lotteryFields: [],
        lotteryDraws: [],
        smsTemplates: []
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE
        }
    }

    redirectToLotteries() {
        this.props.history.push(`/lotteries`);
    }

    handleExit = () => {
        this.redirectToLotteries();
    }

    componentDidMount = () => {
        if (this.props.match.params) {
            const params = this.props.match.params;

            lotteryService.getLotteryById(params.id).then((results) => {
                /**
                 * This getAllLotteries not retriving the data from the API
                 * Only Basics datas coming 
                 */
                console.log(results)
                if (results.statusCode === 200) {
                    const lotteryData = results.data;
                    this.setState({
                        showLoadingSpinner: false,
                        lotteryBasics: lotteryData.lotteryBasics,
                        lotteryFields: lotteryData.lotteryFields,
                        lotteryDraws: lotteryData.lotteryDraws,
                        smsTemplates: lotteryData.smsTemplates
                    });
                }
            }).catch((error) => {
                console.log(error)
                this.setState({
                    showLoadingSpinner: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.redirectToLotteries();
        }
    }

    renderLotteryInformation() {
        const { lotteryBasics } = this.state;
        console.log(lotteryBasics);

        if (lotteryBasics) {
            return (
                <ul className="list-group list-group-flush text-muted">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <strong>Bonus Fields:</strong>
                        <span style={{ left: '40%' }} className="position-absolute">{lotteryBasics.bonusFields ? lotteryBasics.bonusFields : '-'}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <strong>Buy Price:</strong>
                        <span style={{ left: '40%' }} className="position-absolute">{lotteryBasics.buyingPrice} LKR</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <strong>Ticket Price:</strong>
                        <span style={{ left: '40%' }} className="position-absolute">{lotteryBasics.ticketPrice} LKR</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <strong>Ticket Validity:</strong>
                        <span style={{ left: '40%' }} className="position-absolute">{lotteryBasics.validityDays} Days</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <strong>App ID:</strong>
                        <span style={{ left: '40%' }} className="position-absolute">{lotteryBasics.appId}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <strong>Number Mask</strong>
                        <span style={{ left: '40%' }} className="position-absolute">{lotteryBasics.numberMask}</span>
                    </li>
                </ul>
            )
        }
    }

    renderLotteryFields() {
        const { lotteryFields } = this.state;
        if (lotteryFields) {
            return (
                <div className="border p-4 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>
                    <h5 className="text-muted pb-2">Lottery Fields</h5>

                    {lotteryFields && lotteryFields.map((field, index) => {
                        return <span key={index} >

                            {field.formatType === 3 ?
                                <div className="bg-light text-center my-3 rounded p-1">
                                    <span className="text-monospace pr-2">Serial</span>
                                    <span className="text-monospace">{field.fieldValue}</span>
                                </div> :
                                field.formatType === 4 ?
                                    <div className="d-inline-block pr-3">
                                        <div style={{ width: '75px', height: '75px' }} className="bg-primary rounded-circle text-center pt-4">
                                            <span className="text-white">{Object.keys(JSON.parse(field.fieldValue))[0]}</span>
                                        </div>
                                        <div className="text-muted small text-center">{field.formatTypeName}</div>
                                    </div> :
                                    <div className="d-inline-block pr-3">
                                        <div style={{ width: '75px', height: '75px' }} className="bg-primary rounded-circle text-center pt-4">
                                            <span className="text-white">{field.fieldValue}</span>
                                        </div>
                                        <div className="text-muted small text-center">{field.formatTypeName}</div>
                                    </div>
                            }
                        </span>
                    })}
                </div>
            )
        }
    }

    renderLotteryDraws() {
        const { lotteryDraws } = this.state;
        if (lotteryDraws) {
            return (
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Draw Day</th>
                            <th scope="col">Time</th>
                            <th scope="col">Start-On</th>
                            <th scope="col">End-On</th>
                            <th scope="col">Return-On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lotteryDraws && lotteryDraws.map((draw, index) => {
                            return <tr key={index}>
                                <th scope="row">{draw.drawDayInText}</th>
                                <td> <strong>{draw.drawTime}</strong></td>
                                <td> <strong>{draw.salesStartOn}</strong></td>
                                <td> <strong>{draw.salesEndOn}</strong></td>
                                <td> <strong>{draw.returnOn}</strong></td>
                            </tr>
                        })}
                    </tbody>
                </table>

            )
        }
    }

    renderSmsTemplates() {
        const { smsTemplates } = this.state;
        if (smsTemplates) {
            return (
                <div className="accordion text-muted">
                    {smsTemplates && smsTemplates.map((template, index) => {
                        return <div key={index} className="card mb-3">
                            <div style={{ cursor: 'pointer' }} className="card-header mb-0" id={`heading-${index}`} data-toggle="collapse" data-target={`#collapse-${index}`}>
                                <strong style={{ textTransform: 'lowercase' }}>{template.name.replace(/_/g, " ")}</strong>
                            </div>
                            <div id={`collapse-${index}`} className="collapse">
                                <div className="card-body border-bottom pb-1">
                                    <p>{template.en}</p>
                                    <p>{template.si}</p>
                                    <p>{template.ta}</p>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            )
        }
    }

    handleActivation = () => {
        let { lotteryBasics } = this.state;
        lotteryBasics.isActive = !lotteryBasics.isActive;
        // this.setState({ lotteryBasics });

        lotteryService.activateLottery(lotteryBasics.id, lotteryBasics.isActive).then((results) => {
            if (results.statusCode === 200) {
                this.setState({
                    lotteryBasics,
                    toast: {
                        type: 'success',
                        message: 'Record has been updated.'
                    }

                })
            }

            if (results.statusCode === 210) {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: results.data.description
                    }
                });

                this.componentDidMount();
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            });
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast, showLoadingSpinner, lotteryBasics } = this.state;

        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                {showLoadingSpinner ?
                    <Row className="border bg-white px-3 pt-3 pb-2 mt-2 shadow-sm">
                        <Col md={12} className="text-center">
                            <Spinner color="primary" />
                        </Col>
                    </Row> :
                    <div>
                        <div className="border bg-white px-3 pt-3 pb-2 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>
                            <Row>
                                <Col sm={10}>
                                    <h4>{lotteryBasics.name}</h4>
                                </Col>
                                <Col sm={2}>
                                    <CustomInput className="text-right" type="switch" id="isActive" name="isActive" checked={lotteryBasics.isActive} onChange={this.handleActivation} label="Activate" />
                                </Col>
                            </Row>
                        </div>

                        <div className="border bg-white p-4 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>
                            <Row>
                                <Col md={6}>
                                    {this.renderLotteryInformation()}
                                </Col>
                                <Col md={6}>
                                    {this.renderLotteryFields()}
                                </Col>
                            </Row>

                            <Row className="pt-4 pb-1">
                                <Col md={12} >
                                    <h5 className="text-muted"> <strong>Lotteries</strong></h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {this.renderLotteryDraws()}
                                </Col>
                            </Row>

                            <Row className="pt-4 pb-3">
                                <Col md={12} >
                                    <h5 className="text-muted"> <strong>SMS Templates</strong></h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    {this.renderSmsTemplates()}
                                </Col>
                            </Row>


                            <Row className="pt-3">
                                <Col md={12} className="text-right">
                                    <Button onClick={this.handleExit}>Back</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </Container >
        )
    }
}

export default LotteryView;
