/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Row } from 'reactstrap';
import FileUpload from './FileUpload';
import SingleUpload from './SingleUpload';
import { SUBSCRIPTION_OPTIONS } from '../../store/constants/common';

class SmsSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: SUBSCRIPTION_OPTIONS.SINGLE
        }
    }
    handleOptionChange(changeEvent) {
        this.setState({
            selectedOption: changeEvent.target.value
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <div className='smsSubscriptionStyle'>
                        <label class="radio-inline">
                            <input type="radio" value={SUBSCRIPTION_OPTIONS.SINGLE}
                                checked={this.state.selectedOption === SUBSCRIPTION_OPTIONS.SINGLE}
                                onChange={(e) => this.handleOptionChange(e)} />
                            {' Single'}
                        </label>

                        <label class="radio-inline">
                            <input type="radio" value={SUBSCRIPTION_OPTIONS.BATCH}
                                checked={this.state.selectedOption === SUBSCRIPTION_OPTIONS.BATCH}
                                onChange={(e) => this.handleOptionChange(e)} />
                            {' Batch'}
                        </label>
                    </div>

                </Row>
                <Row style={{ padding: '10px', paddingLeft: '20px' }}>
                    {this.state.selectedOption === SUBSCRIPTION_OPTIONS.BATCH ?
                        <FileUpload /> : <SingleUpload />}
                </Row>
            </div>
        )
    }
}

export default SmsSubscription;
