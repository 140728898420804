/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Button, Row, Col, Input, FormGroup, Label, CustomInput, Fade } from 'reactstrap';
import PropTypes, { element } from 'prop-types';

import { lotteryFormats } from '../../../utils/meta-data';
import CustomDropdown from './../../common/custom-controls/CustomDropdown';
import CustomGrid from './../../common/custom-controls/CustomGrid';
import SimpleReactValidator from 'simple-react-validator';
import '../../../styles/css/symbols.css'

class LotteryFormat extends Component {

    isEditView = false;
    selectedData = {};

    INITIAL_STATE = {
        lotteryField: {
            id: '',
            fieldName: '',
            isSpecialField: false,
            specialFieldValue: '',
            formatType: 1,
            fieldValue: '',
            isArchived: false
        },
        gridConfig: {
            columns: [
                {
                    id: 1,
                    name: 'id',
                    headerTitle: '',
                    canSort: false,
                    width: '0%',
                    hidden: true
                },
                {
                    id: 2,
                    name: 'fieldName',
                    headerTitle: 'Field',
                    canSort: true,
                    width: '20%'
                },
                {
                    id: 3,
                    name: 'formatTypeName',
                    headerTitle: 'Format',
                    canSort: true,
                    width: '25%'
                },
                {
                    id: 4,
                    name: 'fieldValue',
                    headerTitle: 'Values',
                    canSort: true,
                    width: '30%'
                },
                {
                    id: 5,
                    name: 'actions',
                    headerTitle: 'Actions',
                    canSort: false,
                    width: '15%',
                    useTemplate: true,
                    templates: [
                        {
                            type: 'DELETE',
                            template: `<button class="btn btn-sm btn-danger"><i class="fas fa-trash"/></button>`
                        },
                        {
                            type: 'EDIT',
                            template: `<button class="btn btn-sm btn-primary"><i class="fas fa-pen"/></button>`
                        }
                    ]
                }
            ],
            gridData: [],
            meta: {
                pageSize: 10
            }
        }
    }

    validator = null;

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                specialField: {
                    message: 'The :attribute is required.',
                    rule: (value) => {
                        if (this.state.lotteryField.isSpecialField) {
                            return value !== ''
                        }
                    },
                    required: true
                },
                isFieldNameExist: {
                    message: 'The :attribute already exists.',
                    rule: (value) => {
                        return !this.state.gridConfig.gridData.some((el) => {
                            if (el.fieldName === value) {
                                if (this.isEditView) {
                                    if (value === el.fieldName && value !== this.selectedData.fieldName) {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }
                            }
                        });
                    },
                    required: true
                }
            }
        });

        this.state = {
            ...this.INITIAL_STATE
        }
    }

    componentDidMount = () => {
        if (this.props.lotteryFields.length > 0) {
            let { gridConfig } = this.state;

            // converting the string of JSON type value to JSON here
            // this.props.lotteryFields.forEach((element, index) => {
            //     if (element.formatTypeName === 'JSON') {
            //         this.props.lotteryFields[index].fieldValue = JSON.parse(element.fieldValue);
            //     }
            // });

            gridConfig.gridData = [...this.props.lotteryFields];

            this.setState({
                gridConfig
            })
        }
    }

    addFieldHandler = () => {
        if (this.validator.allValid()) {
            this.validator.hideMessages();

            let { lotteryField, gridConfig } = this.state;



            const format = lotteryFormats.find((format) => {
                return format.id === lotteryField.formatType;
            });

            lotteryField['formatTypeName'] = format.name;

            // converting the string of JSON type value to JSON here
            // if (lotteryField.formatTypeName === 'JSON') {
            //     lotteryField.fieldValue = JSON.parse(lotteryField.fieldValue);
            // }

            if (this.isEditView) {
                let editIndex = -1;
                if (this.selectedData.id) {
                    editIndex = gridConfig.gridData.findIndex(el => el.id === this.selectedData.id);
                } else {
                    editIndex = gridConfig.gridData.findIndex(el => el.fieldName === this.selectedData.fieldName);
                }
                gridConfig.gridData.splice(editIndex, 1, lotteryField);
            } else {
                gridConfig.gridData.push(lotteryField);
            }

            this.setState({
                lotteryField: { ...this.INITIAL_STATE.lotteryField },
                gridConfig
            });

            this.isEditView = false;
        } else {
            this.validator.showMessages();
        }
    }

    handleValueChange = (e) => {
        let lotteryField = { ...this.state.lotteryField };
        lotteryField[e.target.name] = e.target.value;
        this.setState({
            lotteryField
        });
    }

    handleSpecialField = () => {
        let lotteryField = { ...this.state.lotteryField };
        lotteryField.isSpecialField = !lotteryField.isSpecialField;
        this.setState({
            lotteryField
        });

        if (lotteryField.isSpecialField) {
            lotteryField.specialFieldValue = '';
        }
    }

    handleOnDropdownSelected = (selected, type) => {
        let lotteryField = { ...this.state.lotteryField };
        switch (type) {
            case 'lotteryFormats':
                lotteryField.formatType = selected.id;
                break;
            default:
                break;
        }
        this.setState({
            lotteryField
        })
    }

    validateAndRetrieveState = () => {
        const { gridConfig } = this.state;
        // place here if there is any validations
        let minData = 0;

        for (let index = 0; index < gridConfig.gridData.length; index++) {
            if (gridConfig.gridData[index].isArchived === false) {
                minData += 1
            }
        }

        return minData ? gridConfig.gridData : false
    }

    handleActionClick = (params) => {
        let { gridConfig } = this.state;
        switch (params.type) {
            case 'EDIT':
                this.isEditView = true;
                this.selectedData = { ...params.data };
                this.setState({ lotteryField: { ...params.data } });
                break;
            case 'DELETE':
                // gridConfig.gridData.splice(params.index, 1);
                let taget = gridConfig.meta.pageSize * (params.selectedPage - 1) + params.index;
                // gridConfig.gridData.splice(taget, 1);
                gridConfig.gridData[taget].isArchived = true;
                this.setState({
                    lotteryField: { ...this.INITIAL_STATE.lotteryField },
                    gridConfig
                });
                break;
            default:
                break;
        }
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        return (
            <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick}></CustomGrid>
        )
    }

    render() {
        const { lotteryField } = this.state;
        const { lotteryName } = this.props;

        return (
            <div>
                <FormGroup>
                    <Label for="lotteryName">Lottery Name</Label>
                    <Input type="text" name="lotteryName" id="lotteryName" value={lotteryName} readOnly />
                </FormGroup>
                <Row className="py-4">
                    <Col md={12} >
                        <div className="border-bottom"><h5>Add Fields</h5></div>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} className="border-right">
                        <Row form>
                            <Col md={12}>
                                <FormGroup className="required">
                                    <Label for="fieldName">Name</Label>
                                    <Input type="text" name="fieldName" id="fieldName" value={lotteryField.fieldName} onChange={this.handleValueChange} />
                                    <span className="text-danger"><small>{this.validator.message('Field Name', lotteryField.fieldName, 'required')}</small></span>
                                    {/* <span className="text-danger"><small>{this.validator.message('Field Name', lotteryField.fieldName, 'isFieldNameExist')}</small></span> */}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <FormGroup className="required">
                                    <CustomInput type="checkbox" id="specialField" label="Special Field" checked={lotteryField.isSpecialField} onChange={this.handleSpecialField} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {lotteryField.isSpecialField ?
                            <Fade in={lotteryField.isSpecialField} timeout={100}>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="specialFieldValue">Special Field Description</Label>
                                            <Input type="text" name="specialFieldValue" id="specialFieldValue" value={lotteryField.specialFieldValue} onChange={this.handleValueChange} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Fade> : ''}
                        <Row form>
                            <Col md={12}>
                                <FormGroup className="required">
                                    <Label for="fieldFormat">Format</Label>
                                    {lotteryFormats.length ?
                                        <CustomDropdown type={'lotteryFormats'} selectedId={lotteryField.formatType} options={lotteryFormats} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                                        : ''
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <FormGroup className="required">
                                    <Label for="fieldValue">Value</Label>
                                    <Input type="text" name="fieldValue" id="fieldValue" value={lotteryField.fieldValue} onChange={this.handleValueChange} />
                                    <span className="text-danger"><small>{this.validator.message('Field Value', lotteryField.fieldValue, 'required')}</small></span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col md={12} className="text-right">
                                <Button color="success" onClick={this.addFieldHandler}><i className={this.isEditView ? 'fas fa-save' : 'fas fa-plus'}></i> {this.isEditView ? 'Update' : 'Add'}</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={7}>
                        {this.renderGrid()}
                    </Col>
                </Row>
            </div>
        )
    }
}

LotteryFormat.propTypes = {
    lotteryName: PropTypes.string.isRequired,
    lotteryFields: PropTypes.array.isRequired
}

export default LotteryFormat;
