/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, CustomInput, ModalFooter, Table } from 'reactstrap';
import { reportService } from '../../services/report-service';
import Toast from '../common/custom-controls/Toast';
import CustomDropdown from './../common/custom-controls/CustomDropdown';
import SimpleReactValidator from 'simple-react-validator';
import { winnersService } from '../../services/winners-service';
import './../custom.css'
import { green } from '@material-ui/core/colors';
import { element } from 'prop-types';

class DrawSettle extends Component {
    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        },
        inputField: {
            settleDate: '',
            price: ''
        },
        prizeTransferList: '',
        settledAmount: ''
    }

    validator = null;


    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this
        });

        this.state = {
            ...this.INITIAL_STATE,
            id: props.drawId,
            showModal: props.showModal,
            onClose: props.onClose,
            winnersData: props.winnersData,
        }
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleOnDropdownSelected = (selected, type) => {
        let inputField = { ...this.state.inputField };

        switch (type) {
            case 'rewardMethod':
                inputField.rewardMethod = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            inputField,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleValueChange = (e) => {
        let inputField = { ...this.state.inputField };
        inputField[e.target.name] = e.target.value;
        this.setState({
            inputField
        });
    }

    handleSubmit = () => {
        let { inputField, winnersData } = this.state;

        let settlement = {
            settlementMethodId: inputField.rewardMethod,
            settlementDate: inputField.settleDate,
            price: inputField.price
        }

        if (this.validator.allValid()) {
            winnersService.winnersSettle(winnersData.id, settlement).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        toast: {
                            type: 'success',
                            message: 'Payment Settled'
                        }
                    });

                    this.handleClose('success');
                }

                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    showLoadingSpinner: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.validator.showMessages();
        }
    }

    componentWillMount() {

        const { winnersData } = this.state;

        //Calculate settled amount
        let settledAmount = 0
        winnersData.settlements && winnersData.settlements.map(element => {
            settledAmount += parseFloat(element.amount);
        })

        this.setState({ settledAmount });

        reportService.prizeTransferMethodList().then((results) => {
            if (results.statusCode === 200) {

                var prizeTransferList = results.data;
                //This is added to give clarification for the user.align-text-bottom
                //If the db changes need to change here also
                prizeTransferList[0].name = prizeTransferList[0].name + ' (Real Time)'
                this.setState({
                    prizeTransferList
                });
            }

        }).catch((error) => {
            this.setState({
                showLoadingSpinner: false,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast, winnersData, inputField, prizeTransferList, settledAmount } = this.state;

        return (
            <Modal isOpen={this.state.showModal} className={'drawView-modal'}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <ModalBody className={'drawView-body'}>
                    <Row>
                        <h5>
                            Settle Prize Amount
                        </h5>
                    </Row>
                    <br />
                    <Row>
                        <h6>
                            Settle remaining {winnersData.price - settledAmount} of Prize amount to {winnersData.mobile}. Please select the Method Below.
                        </h6>
                    </Row>

                    <br />

                    <Row>
                        <Col>
                            <FormGroup className="required">
                                <Label for="firstName">Reward Method</Label>
                                {prizeTransferList ? <CustomDropdown type={'rewardMethod'} placeholder={{ name: 'Select Reward Method' }} options={prizeTransferList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown> : ''}
                                <span className="text-danger"><small>{this.validator.message('Reward method', inputField.rewardMethod, 'required')}</small></span>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup className="required">
                                <Label for="firstName">Settlement Date</Label>
                                <Input type="date" name="settleDate" id="settleDate" value={inputField.settleDate} onChange={this.handleValueChange} placeholder="Draw Id" />
                                <span className="text-danger"><small>{this.validator.message('Date', inputField.settleDate, 'required')}</small></span>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup className="required">
                                <Label for="firstName">Settlement Amount</Label>
                                <Input type="number" name="price" id="price" value={inputField.price} onChange={this.handleValueChange} placeholder='Amount' />
                                <span className="text-danger"><small>{this.validator.message('Date', inputField.price, 'required')}</small></span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br /><br /><br />
                    <Row>
                        <Col md={6} className='text-left'>
                            <Button outline color="secondary" onClick={this.handleClose}>Cancel</Button>
                        </Col>
                        <Col md={6} className='text-right'>
                            <Button outline color="primary" onClick={this.handleSubmit}>Confirm</Button>
                        </Col>
                    </Row>

                </ModalBody>

            </Modal >
        )
    }
}


export default DrawSettle;
