/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { AUTHENTICATE, AUTHENTICATE_ERROR, UNAUTHENTICATE, CHECK_AUTHENTICATED_USER, CHECK_USER_PERMISSION } from './../constants/action-types';

const INITIAL_STATE = {
    isAuthenticated: false,
    username: '',
    error: '',
    user: {},
    hari: ''
};

export const authReducer = (
    state = INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AUTHENTICATE:
            return {
                ...state,
                ...action.payload
            };
        case AUTHENTICATE_ERROR:
            return {
                ...state,
                ...action.payload
            };
        case UNAUTHENTICATE:
            return {
                ...state,
                ...action.payload
            };
        case CHECK_AUTHENTICATED_USER:
            return {
                ...state,
                ...action.payload
            };
        case CHECK_USER_PERMISSION:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state
    }
}
