/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import fetchIntercept from 'fetch-intercept';

// include urls which need to be excluded by adding headers
const blackListedUrls = [

]

const isBlackListedUrl = (url) => {
    return blackListedUrls.some((substring) => { return url.indexOf(substring) >= 0; })
}

fetchIntercept.register({
    request: (request, config) => {
        let headers = {};

        if (!isBlackListedUrl(request.url)) {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        config = {
            headers: headers
        }

        if (localStorage.getItem('token')) {
            config.headers['authorization'] = `${localStorage.getItem('token')}`;
        }

        return [request, config];
    },

    requestError: (error) => {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: (response) => {
        // Modify the reponse object
        return response;
    },

    responseError: (error) => {
        // Handle an fetch error
        return Promise.reject(error);
    }
});
