/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get, put, post, remove, fileUpload } from '../utils/http';

const getAllWinnersFilter = (drawId, lotteryId) => {
    return get(`winners/filter?drawId=${drawId}&lotteryId=${lotteryId}`);
}

const uploadWinnerFiles = (winnersFile) => {
    let results = fileUpload(`winners/upload`, winnersFile);
    return results;
}

const serialNumberVarification = (serialNum) => {
    return post(`winners-validate`, serialNum);
}

const otpVarification = (serialNum, otp) => {
    return post(`winners-validate/${serialNum}`, otp);
}

const resendSerialNumberVarification = (serialNum) => {
    return post(`winners-validate/resend-otp`, serialNum);
}

const winnersSettle = (id, data) => {
    return put(`winners/${id}/settlement`, data);
}

const updateCustomerDetails = (customerId,data) => {
    return put(`customers/${customerId}/name-and-address`, data);
}


export const winnersService = {
    getAllWinnersFilter,
    serialNumberVarification,
    resendSerialNumberVarification,
    otpVarification,
    uploadWinnerFiles,
    winnersSettle,
    updateCustomerDetails
};