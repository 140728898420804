/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get, put, post, remove, fileUpload } from '../utils/http';
import { lotteryFormats, days } from '../utils/meta-data';
import { compose } from 'recompose';

const mapLoteryFields = (results) => {

    let lotteryData = {};
    let { id, name, ticketRowCount, appId, bonusFields, buyingPrice, mainImage, mobileImage, ticketPrice, lotteryColor, initialSearchVisibility, searchBand, validityDays, isActive, numberMask } = results;

    lotteryData['lotteryBasics'] = { id, name, appId, bonusFields, buyingPrice, mainImage, mobileImage, ticketPrice, lotteryColor, initialSearchVisibility, searchBand, validityDays, isActive, numberMask, ticketRowCount };
    lotteryData['lotteryFields'] = results.lotteryFields ? results.lotteryFields.map((element) => {

        const lotteryField = {
            id: element.id,
            fieldName: element.name,
            formatTypeName: lotteryFormats[(element.formatType - 1)].name,
            formatType: element.formatType,
            isSpecialField: element.isSpecialField,
            fieldValue: element.values,
            isArchived: element.isArchived
        }

        return lotteryField;
    }).sort((a, b) => (a.formatType > b.formatType) ? -1 : 1) : [];

    lotteryData['lotteryDraws'] = results.draws ? results.draws.map((element) => {
        const lotteryDraw = {
            id: element.id,
            drawDay: element.drawDay,
            drawDayInText: days[(element.drawDay - 1)].name,
            drawTime: element.drawTime,
            firstIssueDate: element.firstIssueDate,
            returnOn: element.returnOn,
            salesEndOn: element.salesEndOn,
            salesStartOn: element.salesStartOn,
            isArchived: element.isArchived
        }
        return lotteryDraw;
    }) : [];

    lotteryData['smsTemplates'] = results.SMSTemplates ? results.SMSTemplates.map((element) => {
        const smsTemplate = {
            id: element.id,
            name: element.name,
            si: element.si,
            ta: element.ta,
            en: element.en
        }
        return smsTemplate;
    }) : [];

    return lotteryData;
}

//values from field convert to JSON here
const changeJsonObject = (lotteryFields) => {
    lotteryFields = lotteryFields.map((element) => {
        let fieldValue = element.formatTypeName === 'JSON' ?
            JSON.parse(element.fieldValue) : element.fieldValue;

        const lotteryField = {
            id: element.id,
            fieldName: element.fieldName,
            formatTypeName: element.formatTypeName,
            formatType: element.formatType,
            isSpecialField: element.isSpecialField,
            fieldValue: fieldValue,
            isArchived: element.isArchived,
        }
        return lotteryField;
    })
    return lotteryFields;
}

const getAllLotteries = () => {
    return get(`lotteries`);
}

const getLotteryById = (lotteryId) => {
    return new Promise((resolve, reject) => {
        get(`lotteries/${lotteryId}`).then((results) => {
            results.data = mapLoteryFields(results.data);
            resolve(results)
        }).catch((error) => {
            reject(error);
        })
    });
}

const saveLottery = (lottery) => {
    lottery['lotteryFields'] = changeJsonObject(lottery['lotteryFields']);
    return post(`lotteries`, lottery);
}

const updateLottery = (lottery) => {
    return put(`lotteries/${lottery.id}`, lottery);
}

const archiveLottery = (lotteryId) => {
    return put(`lotteries/${lotteryId}`);
}

const activateLottery = (lotteryId, status) => {
    return put(`lotteries/${lotteryId}/status/${status}`);
}

/*lottery fields */

const getAllLotteryFields = (lotteryId) => {
    return get(`lotteries/${lotteryId}/fields`);
}

const getLotteryFieldById = (lotteryId, lotteryFieldId) => {
    return get(`lotteries/${lotteryId}/fields/${lotteryFieldId}`);
}

const saveLotteryField = (lotteryId, lotteryField) => {
    return post(`lotteries/${lotteryId}/fields`, lotteryField);
}

const updateLotteryField = (lotteryId, lotteryField) => {
    return put(`lotteries/${lotteryId}/fields/${lotteryField.id}`, lotteryField);
}

const updateLotteryFields = (lotteryId, lotteryFields) => {
    lotteryFields = changeJsonObject(lotteryFields);
    return put(`lotteries/${lotteryId}/fields`, lotteryFields);
}

// const updateLotteryFields = (lotteryId, lotteryFields) => {
//     return post(`lotteries/${lotteryId}/fields`, lotteryFields);
// }
const deleteLotteryField = (lotteryId, lotteryFieldId) => {
    return remove(`lotteries/${lotteryId}/fields/${lotteryFieldId}`);
}

/* draws */

const getAllLotteryDraws = (lotteryId) => {
    return get(`lotteries/${lotteryId}/draws`);
}

const getLotteryDrawById = (lotteryId, lotteryDrawId) => {
    return get(`lotteries/${lotteryId}/draws/${lotteryDrawId}`);
}

const saveLotteryDraw = (lotteryId, lotteryDraw) => {
    return post(`lotteries/${lotteryId}/draws`, lotteryDraw);
}

const updateLotteryDraw = (lotteryId, lotteryDraw) => {
    return put(`lotteries/${lotteryId}/draws/${lotteryDraw.id}`, lotteryDraw);
}

const updateLotteryDraws = (lotteryId, lotteryDraws) => {
    // console.log(lotteryDraws)
    return put(`lotteries/${lotteryId}/draws`, lotteryDraws);
}

const deleteLotteryDraw = (lotteryId, lotteryDrawId) => {
    return remove(`lotteries/${lotteryId}/draws/${lotteryDrawId}`);
}

const uploadDrawFiles = (drawFile) => {
    let results = fileUpload(`ticket-upload`, drawFile);
    return results;
}


/* sms templates */

const getAllLotterySmsTemplates = (lotteryId) => {
    return get(`lotteries/${lotteryId}/sms-templates`);
}

const getLotterySmsTemplateById = (lotteryId, smsTemplateId) => {
    return get(`lotteries/${lotteryId}/sms-templates/${smsTemplateId}`);
}

const saveLotterySmsTemplate = (lotteryId, lotterySmsTemplate) => {
    return post(`lotteries/${lotteryId}/sms-templates`, lotterySmsTemplate);
}

const updateLotterySmsTemplate = (lotteryId, lotterySmsTemplate) => {
    return put(`lotteries/${lotteryId}/sms-templates/${lotterySmsTemplate.id}`, lotterySmsTemplate);
}

const updateLotterySmsTemplates = (lotteryId, lotterySmsTemplates) => {
    return put(`lotteries/${lotteryId}/sms-templates`, lotterySmsTemplates);
}

// const updateLotterySmsTemplates = (lotteryId, lotterySmsTemplates) => {
//     return post(`lotteries/${lotteryId}/sms-templates`, lotterySmsTemplates);
// }

const deleteLotterySmsTemplate = (lotteryId, smsTemplateId) => {
    return remove(`lotteries/${lotteryId}/sms-templates/${smsTemplateId}`);
}

export const lotteryService = {
    getAllLotteries,
    getLotteryById,
    saveLottery,
    updateLottery,
    archiveLottery,
    activateLottery,
    getAllLotteryFields,
    getLotteryFieldById,
    saveLotteryField,
    updateLotteryField,
    updateLotteryFields,
    deleteLotteryField,
    getAllLotteryDraws,
    getLotteryDrawById,
    saveLotteryDraw,
    updateLotteryDraw,
    updateLotteryDraws,
    deleteLotteryDraw,
    uploadDrawFiles,
    getAllLotterySmsTemplates,
    getLotterySmsTemplateById,
    saveLotterySmsTemplate,
    updateLotterySmsTemplate,
    updateLotterySmsTemplates,
    deleteLotterySmsTemplate
};
