/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

class PaginateServerside extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: this.props.activePage,
            firstPaginationNumber: 1
        }
        this.pages = this.getNumberOfPages(this.props);
    }

    componentWillReceiveProps(props) {
        this.pages = this.getNumberOfPages(props);
        this.setState({
            activePage: props.activePage
        })
        this.handlePaginationNumber(props.activePage);
        this.forceUpdate();
    }

    getNumberOfPages = (props) => {
        const auxPages = props.totalItems / props.pageSize;
        let pages = parseInt(auxPages, 10);
        pages += pages !== auxPages ? 1 : 0;
        return pages;
    }

    paginationItems = () => {
        let items = [];
        this.lastPaginationNumber = this.getLastPaginationNumber();
        items.push(this.firstOrLastPagItem('first', 1));
        items.push(this.nextOrPreviousPagItem('previous', 1, 'left'));
        for (var i = this.state.firstPaginationNumber; i <= this.lastPaginationNumber; i++) {
            items.push(this.numberedPagItem(i));
        }
        items.push(this.nextOrPreviousPagItem('next', this.pages, 'right'));
        items.push(this.firstOrLastPagItem('last', this.pages));
        return items;
    }

    getLastPaginationNumber = () => {
        const minNumberPages = Math.min(this.pages, this.props.maxPaginationNumbers);
        return this.state.firstPaginationNumber + minNumberPages - 1;
    }

    numberedPagItem = (i) => {
        // console.log(i)
        return (
            <PaginationItem key={i} id={`pagebutton${i}`} active={this.state.activePage === i} onClick={this.handleClick}>
                <PaginationLink style={{ minWidth: '40px' }}>
                    {i}
                </PaginationLink>
            </PaginationItem>
        )
    }

    nextOrPreviousPagItem = (name, page, direction) => {
        return (
            <PaginationItem key={name} disabled={this.state.activePage === page} onClick={(e) => this.handleSelectNextOrPrevious(direction)}>
                <PaginationLink style={{ minWidth: '40px' }} first={name === 'first'} previous={name === 'previous'} next={name === 'next'} last={name === 'last'}>
                </PaginationLink>
            </PaginationItem>
        )
    }

    firstOrLastPagItem = (name, page) => {
        let event = {
            currentTarget: {
                getAttribute: () => `pagebutton${page}`
            }
        }
        return (
            <PaginationItem key={name} disabled={this.state.activePage === page} onClick={() => this.handleClick(event)}>
                <PaginationLink style={{ minWidth: '40px' }} first={name === 'first'} previous={name === 'previous'} next={name === 'next'} last={name === 'last'}>
                </PaginationLink>
            </PaginationItem>
        )
    }

    handleClick = (event) => {
        const newActivePage = parseInt(event.currentTarget.getAttribute('id').split('pagebutton').pop(), 10);
        this.setState({
            activePage: newActivePage
        })

        this.handlePaginationNumber(newActivePage);
        this.props.onSelect(newActivePage);
    }

    handleSelectNextOrPrevious = (direction) => {
        const activePage = this.state.activePage;
        if ((direction === 'right' && activePage === this.pages) || (direction === 'left' && activePage === 1))
            return;

        const newActivePage = direction === 'right' ? activePage + 1 : activePage - 1;

        this.setState({
            activePage: newActivePage
        })

        this.handlePaginationNumber(newActivePage);
        this.props.onSelect(newActivePage);
    }

    handlePaginationNumber = (activePage) => {
        const distance = Math.floor(this.props.maxPaginationNumbers / 2);
        const newFPNumber = activePage - distance;
        const newLPNumber = activePage + distance;
        if (newFPNumber <= distance) {
            if (this.state.firstPaginationNumber !== 1) {
                this.setState({
                    firstPaginationNumber: 1
                })
            }
        } else if (newLPNumber <= this.pages) {
            this.setState({
                firstPaginationNumber: newFPNumber
            })
        } else if (newLPNumber >= this.pages) {
            this.setState({
                firstPaginationNumber: this.pages - this.props.maxPaginationNumbers + 1
            })
        }
    }

    render() {
        return (
            <Pagination>
                {this.paginationItems()}
            </Pagination>
        )
    }
}

PaginateServerside.propTypes = {
    totalItems: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    maxPaginationNumbers: PropTypes.number,
    activePage: PropTypes.number
}

PaginateServerside.defaultProps = {
    maxPaginationNumbers: 5,
    activePage: 1
}

export default PaginateServerside;
