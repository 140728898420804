/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, CustomInput, ModalFooter, Table } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import queryString from 'query-string'

import CustomDropdown from '../common/custom-controls/CustomDropdown';
import PropTypes from 'prop-types';


import { subscriberService } from '../../services/subscriber-service';
import Toast from '../common/custom-controls/Toast';
import SubmitButton from '../common/custom-controls/SubmitButton';
import { errorCode } from '../../utils/error-code';
import Dropzone, { useDropzone } from 'react-dropzone'

class SubscribersNicEdit extends Component {
    INITIAL_STATE = {
        showModal: false,
        submitted: false,
        subscribersData: {
            mobile: '',
            nic: '',
            isActive: true,
            createNewUser: false,
            oldNIC: ''
        },
        toast: {
            type: '',
            message: ''
        },
        showLoadingSpinner: true,
        files: [],
    }

    validator = null;

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                nicChange: {
                    message: 'The :attribute is not Changed',
                    rule: (val) => {
                        return this.state.subscribersData.oldNIC && this.state.subscribersData.oldNIC !== val;
                    },
                    required: true
                }
            }
        });
        this.state = {
            ...this.INITIAL_STATE,
            id: props.subscriberId,
            showModal: props.showModal,
            onClose: props.onClose
        }
        this.onDrop = (files) => {
            this.setState({ files })
        };

        this.toggle = this.toggle.bind(this);
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }


    handleActiveStatusChange = () => {
        let subscribersData = { ...this.state.subscribersData };
        subscribersData.isActive = !subscribersData.isActive;
        this.setState({
            subscribersData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleFormValueChange = (e) => {
        let subscribersData = { ...this.state.subscribersData };
        subscribersData[e.target.name] = e.target.value;
        this.setState({
            subscribersData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    //Nic change files upload handled here
    handleFileUpload = () => {
        const { id, files } = this.state;
        this.setState({
            submitted: true
        })
        if (files.length && files[0].size < 1048576) {
            const fileData = new FormData();
            const imagedata = files[0];

            fileData.append('files', imagedata);
            fileData.append('tagName', 'NIC_CHANGE_UPLOADS');
            console.log(imagedata.path);

            if (checkFileType(imagedata.path)) {
                subscriberService.uploadNicEditFile(fileData, id).then((results) => {
                    if (results.status === 200) {
                        this.setState({
                            ...this.INITIAL_STATE
                        });
                        let data = { message: 'Nic change file uploaded' }
                        this.handleClose(data);
                    } else if (results.status === 210) {
                        this.setState({
                            submitted: false,
                            toast: {
                                type: 'warning',
                                message: results.data.description
                            }
                        });
                    }
                }).catch((error) => {
                    error = JSON.parse(JSON.stringify(error));
                    let code = error.message.substring(error.message.length - 3)
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: errorCode[code]
                        }
                    });
                })
            } else {
                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: 'Please upload a valid file'
                    }
                });
            }
        } else {
            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: 'Please upload a file less than 1MB'
                }
            });
        }


    }

    handleSubmit = (e) => {
        e.preventDefault();
        const subscribersData = { ...this.state.subscribersData };
        if (this.validator.allValid()) {

            subscriberService.nicEdit(subscribersData).then((results) => {
                if (results.statusCode === 200) {
                    let data = { message: '', mobile: '' }
                    if (subscribersData.createNewUser) {
                        data.mobile = subscribersData.mobile;
                        data.message = 'Nic changed and created new Acccount'
                        this.handleClose(data);
                    } else {
                        data.message = 'Nic edited successfully'
                        this.handleClose(data);
                    }
                } else {
                    this.setState({
                        showLoadingSpinner: false,
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                });
            });
        } else {
            this.validator.showMessages();
        }
    }

    componentDidMount = () => {
        const { id } = this.state;
        if (id) {
            subscriberService.nicChangeReason().then((results) => {
                if (results.statusCode === 200) {
                    let reasonsDropDownData = results.data.map(data => {
                        data.name = data.value;
                        return data;
                    })
                    this.setState({
                        reasonsDropDownData
                    });
                } else {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });

            subscriberService.getSubscribers(id).then((results) => {
                if (results.statusCode === 200) {
                    const subscribersData = results.data;
                    subscribersData.oldNIC = results.data.NIC;
                    subscribersData.createNewUser = false;
                    this.setState({
                        showLoadingSpinner: false,
                        subscribersId: id,
                        subscribersData: subscribersData,
                        oldNIC: results.data.NIC
                    });
                } else {
                    this.setState({
                        showLoadingSpinner: false,
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    showLoadingSpinner: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.redirectToSubscribers();
        }
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    handleCreateNewUser = () => {
        let subscribersData = { ...this.state.subscribersData };
        subscribersData.createNewUser = !subscribersData.createNewUser;
        this.setState({
            subscribersData
        });

        if (subscribersData.isSpecialField) {
            subscribersData.specialFieldValue = '';
        }
    }

    handleOnDropdownSelected = (selected, type) => {
        let subscribersData = { ...this.state.subscribersData };
        switch (type) {
            case 'changeReasonId':
                subscribersData.changeReasonId = selected.id;
                subscribersData.changeReason = selected.name;
                break;
            default:
                break;
        }
        this.setState({
            subscribersData
        })
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    // Render the nic edit details form
    renderNicEditBasics = () => {
        const { subscribersData, reasonsDropDownData } = this.state;

        return (
            <div>
                <Row form>
                    <Col md={12}>
                        <FormGroup className="required">
                            <Label for="nic">NIC</Label>
                            <Input type="text" name="NIC" maxLength="12" id="NIC" value={subscribersData.NIC} onChange={this.handleFormValueChange} placeholder="NIC" />
                            <span className="text-danger"><small>{this.validator.message('nic', subscribersData.NIC, 'nicChange')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={12}>
                        <FormGroup className="required">
                            <CustomInput type="checkbox" id="createNewUser" label="Create a new user" onChange={this.handleCreateNewUser} />
                        </FormGroup>
                    </Col>
                </Row>

                {
                    reasonsDropDownData ?
                        <Row form>
                            <Col md={12}>
                                <FormGroup className="required">
                                    <Label for="fieldFormat">Reason</Label>
                                    <CustomDropdown type={'changeReasonId'} placeholder={{ name: 'Select Reason' }} options={reasonsDropDownData} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                                    <span className="text-danger"><small>{this.validator.message('Reason', subscribersData.changeReasonId, 'required')}</small></span>
                                </FormGroup>
                            </Col>
                        </Row> : ''
                }

                <Row form>
                    <Col md={12}>
                        <FormGroup className="required">
                            <Label for="nic">Remarks</Label>
                            <Input type="textarea" name="remarks" maxLength="250" id="remarks" value={subscribersData.remarks} onChange={this.handleFormValueChange} placeholder="Remarks here" />
                            <span className="text-danger"><small>{this.validator.message('Remarks', subscribersData.remarks, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>

            </div>
        )

    }

    // Render the nic edit documents upload
    renderFileUpload = () => {
        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        const dragText = (
            <div>
                <h4>Drag & Drop file to upload</h4>
                <i className="fa fa-upload" style={{ fontSize: "36px" }}></i>
            </div>
        );

        const insideZone = (files.length) ? files : dragText;

        return (
            <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <section className="container"
                        style={{
                            borderBlock: ' 2px solid #CCC',
                            borderRadius: '10px',
                            flex: '1',
                            padding: '15px',
                            textAlign: 'center',
                        }}>

                        <br />
                        <Row className='justify-content-center'>
                            <Col md={10}>
                                <div {...getRootProps({ className: 'dropzone' })}
                                    style={{
                                        borderBlock: 'solid',
                                        outline: '4px dashed #0275d8',
                                        padding: '40px',
                                        textAlign: 'center',
                                        color: '#0275d8',
                                        height: '200px',
                                    }}>
                                    <input {...getInputProps()} />
                                    {insideZone}
                                </div>
                            </Col>
                        </Row>
                    </section>
                )}
            </Dropzone>
        )
    }

    render() {
        const { toast, submitted, showLoadingSpinner } = this.state;

        return (
            <Modal size="lg" isOpen={this.state.showModal} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>

                <div className="classic-tabs" >
                    <ul className="nav tabs-primary border-bottom" id="myClassicTab" role="tablist" style={{ fontSize: '15px', padding: '5px', borderRadius: '.5rem' }}>
                        <li className="nav-item" >
                            <a className="nav-link waves-light active show text-muted" id="profile-tab-classic" data-toggle="tab" href="#package-summaryy"
                                role="tab" aria-controls="profile-classic" aria-selected="true">NIC Edit Details</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link waves-light text-muted" id="follow-tab-classic" data-toggle="tab" href="#subscribers-logg" role="tab"
                                aria-controls="follow-classic" aria-selected="false">NIC Edit Documents</a>
                        </li>
                    </ul>
                    {showLoadingSpinner ?
                        <Col md={12} className="text-center">
                            <Spinner color="primary" />
                        </Col> :
                        <div className="tab-content" id="myClassicTabContent">
                            <div className="tab-pane fade active show" id="package-summaryy" role="tabpanel" aria-labelledby="profile-tab-classic">
                                <ModalBody>
                                    <Row>
                                        <Col>
                                            {this.renderNicEditBasics()}
                                        </Col>

                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={this.handleClose}>Cancel</Button>
                                    <SubmitButton shouldDisable={submitted} submitted={submitted} onSubmit={this.handleSubmit}></SubmitButton>
                                </ModalFooter>
                            </div>
                            <div className="tab-pane fade" id="subscribers-logg" role="tabpanel" aria-labelledby="follow-tab-classic">
                                <ModalBody>
                                    <Row>

                                        <Col>
                                            {this.renderFileUpload()}
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={this.handleClose}>Cancel</Button>
                                    <SubmitButton shouldDisable={submitted} submitted={submitted} onSubmit={this.handleFileUpload}></SubmitButton>
                                </ModalFooter>
                            </div>
                        </div>}
                </div>
            </Modal>
        )
    }
}

SubscribersNicEdit.propTypes = {
    subscriberId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
}

export default SubscribersNicEdit;

function checkFileType(fileName) {
    let fileExtention = fileName.split('.').pop();
    fileExtention = fileExtention.toLowerCase();
    return (
        fileExtention === 'jpeg' ||
        fileExtention === 'jpg' ||
        fileExtention === 'png' ||
        fileExtention === 'pdf' ||
        fileExtention === 'docx' ||
        fileExtention === 'doc'
    )
}