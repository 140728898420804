/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import ServerSideGrid from '../common/custom-controls/ServerSideGrid';
import { subscriberService } from '../../services/subscriber-service';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import { GRID_LIMIT } from '../../utils/configs';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input } from 'reactstrap';
import CustomDropdown from './../common/custom-controls/CustomDropdown';
import { reportService } from '../../services/report-service';
import { activeStatus } from '../../utils/meta-data';
import Loader from './../common/custom-controls/Loader';


class SubscriptionLog extends Component {
    GRID_CONFIG = {
        columns: [
            {
                id: 1,
                name: 'mobileNumber',
                headerTitle: 'Mobile Number',
                canSort: true,
                width: '12%'
            },
            {
                id: 2,
                name: 'evenDescription',
                headerTitle: 'Event Description',
                canSort: false,
                width: '45%'
            },
            {
                id: 3,
                name: 'dateSent',
                headerTitle: 'Date',
                canSort: true,
                width: '10%'
            },
            {
                id: 4,
                name: 'eventType',
                headerTitle: 'Event type',
                canSort: true,
                width: '11%'
            },
            {
                id: 5,
                name: 'salesType',
                headerTitle: 'Sales type',
                canSort: false,
                width: '11%'
            },
            {
                id: 6,
                name: 'chargeType',
                headerTitle: 'Charge type',
                canSort: false,
                width: '11%'
            },

        ],
        gridData: [],
        meta: {
            pageSize: parseInt(GRID_LIMIT),
            showLoadingSpinner: true,
            totalItems: '',
            selectedPage: 1,
            orderBy: 'id',
            sortBy: 'desc'
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            gridConfig: this.GRID_CONFIG,
            toast: {
                type: '',
                message: ''
            },
            gridData: [],
            serverSideData: '',
            filterData: {
                mobile: '',
                event: '',
                package: ''
            },
            packageList: [],
            lotteryList: [],
            newDropDownList: [],
            smsEventType: [],
            subscriberMobile: '',
            subscriberPackage: '',
            showLoadingSpinner: false,
            reloader: false,
        }

    }


    //The grid response handled here
    handleResponce = (params) => {

        let { gridConfig } = this.state;

        gridConfig.meta.selectedPage = params.selectedPage;
        if (params.sortBy) {
            gridConfig.meta.orderBy = params.sortBy;
        }

        if (params.sortOrder) {
            gridConfig.meta.sortBy = params.sortOrder;
        }

        this.setState({ serverSideData: params, gridConfig, showLoadingSpinner: true });
        this.updateGridState();
    }

    //Input value change handle here
    handleValueChange = (e) => {
        let filterData = { ...this.state.filterData };
        filterData[e.target.name] = e.target.value;
        this.setState({
            filterData
        });
    }

    //Dropdown select handle here
    handleOnDropdownSelected = (selected, type) => {
        let filterData = { ...this.state.filterData };
        this.gridLoader();
        const {  packageList, lotteryList } = this.state;
        let {  newDropDownList } = this.state;
        // newDropDownList





        switch (type) {
            case 'package':
                filterData.package = selected.id;
                break;
            case 'event':
                newDropDownList =[];
                console.log(" event ", selected.id);
                if(selected.id === 3 || selected.id === 4){
                    newDropDownList = packageList;

                }else{
                    newDropDownList = lotteryList;
                }
                this.setState({
                    newDropDownList
                });
                filterData.event = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            filterData,
            toast: {
                type: '',
                message: ''
            }
        });



        //This is to give a delay to update state
        setTimeout(() => { this.updateGridState() }, 250);

    }

    handleSearch = () => {
        const { filterData } = this.state;
        let toast = {}

        //Search when no mobile number or valid mobile number
        if (!filterData.mobile || filterData.mobile && filterData.mobile.length >= 9 && filterData.mobile.length <= 11) {
            this.gridLoader();
            this.updateGridState();
            toast = {
                type: '',
                message: ''
            }
        } else {
            toast = {
                type: 'warning',
                message: 'Please enter valid mobile number'
            }
        }

        // To handle error message
        this.props.onSearch(toast);
    }

    // Handle refresh button click here
    handleRefresh = () => {
        this.setState({
            reloader: true,
            filterData: {
                mobile: '',
                event: '',
                package: ''
            }
        });
        setTimeout(() => {
            this.setState({
                reloader: false,
            });
            this.gridLoader();
            this.updateGridState();
        }, 1);
    }

    // This is added to load spinner between grid filter
    gridLoader = () => {
        let { gridConfig } = this.state;

        gridConfig.gridData = [];
        gridConfig.meta.totalItems = '';
        gridConfig.meta.showLoadingSpinner = true;
        gridConfig.meta.selectedPage = 1;
        gridConfig.meta.orderBy = 'id';
        gridConfig.meta.sortBy = 'desc';

        this.setState({
            gridConfig
        })
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        if (gridConfig.gridData) {
            return (
                <ServerSideGrid gridConfig={gridConfig}
                    actionHandler={this.handleActionClick}
                    handleOrder={this.handleResponce}
                    handlePage={this.handleResponce}
                    toggleModal={'collapse'} />
            )
        }
    }

    updateGridState = () => {
        let { gridConfig, gridData, filterData } = this.state;
        let toast = {}

        //Change the params as access by api
        switch (gridConfig.meta.orderBy) {
            case 'eventType': gridConfig.meta.orderBy = 'eventsType'; break;
        }

        //To get data from server-side pagination need to pass pageSize, selectedPage, orderBy, sortBy
        subscriberService.getAllSubscribersLog(gridConfig.meta, filterData).then((results) => {
            if (results.statusCode === 200) {
                gridConfig.gridData = results.data.data;
                gridConfig.meta.totalItems = results.data.page.total;
                gridConfig.meta.showLoadingSpinner = false;
                gridData = results.data.data;
                this.setState({ gridConfig, gridData, showLoadingSpinner: false });
                this.props.onSearch(toast);
            } else {
                gridConfig.meta.showLoadingSpinner = false;
                toast = {
                    type: 'warning',
                    message: results.data.description
                }
                this.props.onSearch(toast);
                this.setState({ gridConfig })
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            toast = {
                type: 'warning',
                message: error.message
            }
            this.props.onSearch(toast);
            this.setState({ gridConfig })

        });
    }

    componentDidMount = () => {
        let { packageList, smsEventType, lotteryList, newDropDownList } = this.state;

        reportService.getPackageList().then((results) => {
            if (results.statusCode === 200) {
                packageList = results.data

                packageList.unshift({ id: 0, name: 'All' });  //Added 'all' at the beginning of the drop-down
                newDropDownList = [];
                newDropDownList = packageList;
                this.setState({
                    packageList
                });
                this.setState({
                    newDropDownList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        reportService.getLotteryList().then((results) => {
            if (results.statusCode === 200) {
                lotteryList = results.data

                lotteryList.unshift({ id: 0, name: 'All' });  //Added 'all' at the beginning of the drop-down

                this.setState({
                    lotteryList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        subscriberService.getSmsEventType().then((results) => {
            if (results.statusCode === 200) {
                smsEventType = results.data.map(data => {
                    let name = toCamelCase(data.name);
                    data.name = name
                    return data;
                })
                smsEventType.splice(5, 1);
                smsEventType.unshift({ id: 0, name: 'All' });  //Added 'all' at the beginning of the drop-down

                this.setState({
                    smsEventType
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
        this.updateGridState();
    }

    handleActionClick = (params) => {
        switch (params.type) {

            default:
                break;
        }
    }

    render() {
        const { filterData, packageList,newDropDownList, smsEventType, reloader } = this.state;

        return (
            <div>
                {this.state.showLoadingSpinner ?
                    <Loader /> : ''}
                <Row style={{ padding: '10px', paddingLeft: '20px' }}>

                    <Col md={3}>
                        {reloader ? '' :
                            <CustomDropdown
                                type={'event'}
                                placeholder={{ name: 'Event Type' }}
                                options={smsEventType}
                                handleOnSelected={this.handleOnDropdownSelected} />}
                    </Col>
                    <Col md={4}>
                        {reloader ? '' :
                            <CustomDropdown
                                type={'package'}
                                placeholder={{ name: 'Package Or Lottery' }}
                                options={newDropDownList}
                                handleOnSelected={this.handleOnDropdownSelected} />}
                    </Col>
                    <Col md={2}>
                        <Input
                            className='form-control-rounded'
                            type="number"
                            name={'mobile'}
                            maxLength="10"
                            id="mobile"
                            onChange={this.handleValueChange}
                            value={filterData.mobile}
                            placeholder={'Mobile Number'}></Input>
                    </Col>
                    <Col md={2}>
                        <Button className='btn-rounded' color="primary" onClick={() => this.handleSearch()} >
                            <i className="fas fa-search"></i> {'Search'}
                        </Button>
                    </Col>
                    {/* <Col md={1} className='text-right'>
                        <Button disabled hidden color="" onClick={() => this.handleSearch()} ><i className="fas fa-download"></i></Button>
                    </Col> */}
                    <Col md={1} className='text-right'>
                        <Button className='btn-rounded' color='secondary' onClick={() => this.handleRefresh()}>
                            <i className="fas fa-redo"></i>
                        </Button>
                    </Col>
                </Row>
                {reloader ? '' : this.renderGrid()}
            </div>

        )
    }
}

export default SubscriptionLog;

function toCamelCase(str) {
    return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
    });
}
