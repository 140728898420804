/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input } from 'reactstrap';
import './../custom.css'

import SubscribersEdit from './SubscribersEdit';
import CustomGrid from '../common/custom-controls/CustomGrid';
import Toast from '../common/custom-controls/Toast';
import { subscriberService } from '../../services/subscriber-service';
// import { userService } from '../../services/user-service';
import SearchInput from '../common/custom-controls/SearchInput';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import FileUpload from './FileUpload';
import SimpleReactValidator from 'simple-react-validator';
import { mobileValidation } from './../../utils/validations';

class Subscribers extends Component {
    validator = null;
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                telephone: {
                    message: 'The Mobile Number is invalid',
                    rule: (val) => {
                        return mobileValidation(val);
                    },
                    required: true
                }
            }
        });
        this.state = {
            showModal: false,
            gridConfig: this.GRID_CONFIG,
            userId: null,
            toast: {
                type: '',
                message: ''
            },
            gridData: [],
            searchInputField: '',
            searchData: []
          //  userData :{}
        }
    }

    handleValueChange = (e) => {
        let searchInputField = { ...this.state.searchInputField };
        searchInputField[e.target.name] = e.target.value;
        this.setState({
            searchInputField
        });

    }
    handleSearch = () => {
        const { searchInputField } = this.state;
        if (this.validator.allValid()) {
            if (searchInputField.mobile && mobileValidation(searchInputField.mobile)) {
               // const userData = { mobile: searchInputField.mobile}
               // this.props.userData =userData;
              //  this.setState({ userData});
                subscriberService.getSubscribersByMobile(searchInputField.mobile).then((results) => {
                    if (results.statusCode === 200) {
                        this.props.history.push(`/subscribers/${results.data.id}`, { mobile: searchInputField.mobile});
                    } else {
                        this.setState({
                            toast: {
                                type: 'warning',
                                message: results.data.description
                            }
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        toast: {
                            type: 'warning',
                            // message: error.message
                            message: 'Number is not found'
                        }
                    })
                });
            } else {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: 'Mobile number should be more than 9 characters'
                    }
                })
            }
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: this.validator.errorMessages.Mobile
                }
            })
            // console.log(this.validator.errorMessages.Mobile)
            // this.validator.showMessages();

        }


    }

    redirectToSummary = () => {
        this.props.history.push('/subscribers/all-subscribers');
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast, searchInputField } = this.state;

        return (
            <Container fluid className='sub-first'>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col md={8}>
                        <label><h4>Subscribers</h4></label>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{ borderRadius: '.5rem' }} className="border bg-white shadow-sm mt-2">
                            <Form>
                                <Row>
                                    <Col md={4}>
                                        <Input type="tel" name={'mobile'} maxLength="11" className="form-control-rounded" onChange={this.handleValueChange} value={searchInputField.mobile} placeholder={'Telephone Number'}></Input>
                                        <span className="text-danger"><small>{this.validator.message('Mobile', searchInputField.mobile, 'telephone')}</small></span>
                                    </Col>
                                    <Col md={3}>
                                        <Button color="primary" block className="btn-rounded" onClick={() => this.handleSearch()}> Search</Button>
                                    </Col>
                                    <Col className="text-center" md={1}>
                                        <h3>or</h3>
                                    </Col>
                                    <Col md={4} className='outline-btn' >
                                        <Button color="primary" outline className="btn-rounded" block onClick={() => this.redirectToSummary()}>View All Subscribers</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>);
    }
}

export default Subscribers;
