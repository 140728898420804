import React, { Component } from 'react';
import ServerSideGrid from '../common/custom-controls/ServerSideGrid';
import { subscriberService } from '../../services/subscriber-service';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import { GRID_LIMIT } from '../../utils/configs';
import { Button, Row, Col } from 'reactstrap';
import CustomDropdown from '../common/custom-controls/CustomDropdown';
import { reportService } from '../../services/report-service';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { errorCode } from './../../utils/error-code';
import Loader from './../common/custom-controls/Loader';

class IndividualSubscriptionLog extends Component {
    GRID_CONFIG = {
        columns: [
            {
                id: 1,
                name: 'evenDescription',
                headerTitle: 'Event Description',
                canSort: false,
                width: '45%'
            },
            {
                id: 2,
                name: 'dateSent',
                headerTitle: 'Date',
                canSort: true,
                width: '11%'
            },
            {
                id: 3,
                name: 'eventType',
                headerTitle: 'Event type',
                canSort: true,
                width: '11%'
            },
            {
                id: 4,
                name: 'salesType',
                headerTitle: 'Sales type',
                canSort: false,
                width: '11%'
            },
            {
                id: 5,
                name: 'chargeType',
                headerTitle: 'Charge type',
                canSort: false,
                width: '11%'
            },
            {
                id: 6,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '11%',
                useTemplate: true,
                templates: [
                    {
                        type: 'RESEND',
                        template: ``
                    }
                ]
            }

        ],
        gridData: [],
        meta: {
            pageSize: parseInt(GRID_LIMIT),
            showLoadingSpinner: true,
            totalItems: 0,
            selectedPage: 1,
            orderBy: 'id',
            sortBy: 'desc'
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            gridConfig: this.GRID_CONFIG,
            toast: {
                type: '',
                message: ''
            },
            gridData: [],
            serverSideData: '',
            filterData: {
                mobile: '',
                event: '',
                package: '',
                startDate: '',
                endDate: '',
            },
            smsId: '',
            packageList: [],
            smsEventType: [],
            subscriberMobile: '',
            subscriberPackage: '',
            userData: props.userData,
            showLoadingSpinner: false,
            reloader: false,
        }
    }


    //The grid response handled here
    handleResponce = (params) => {

        let { gridConfig } = this.state;

        gridConfig.meta.selectedPage = params.selectedPage;
        if (params.sortBy) {
            gridConfig.meta.orderBy = params.sortBy;
        }

        if (params.sortOrder) {
            gridConfig.meta.sortBy = params.sortOrder;
        }

        this.setState({ serverSideData: params, gridConfig, showLoadingSpinner: true });
        this.updateGridState();
    }

    //Input value change handle here
    handleValueChange = (e) => {
        let filterData = { ...this.state.filterData };
        filterData[e.target.name] = e.target.value;
        this.setState({
            filterData
        });
    }

    //Dropdown select handle here
    handleOnDropdownSelected = (selected, type) => {
        let filterData = { ...this.state.filterData };
        this.gridLoader();
        switch (type) {
            case 'package':
                filterData.package = selected.id;
                break;
            case 'event':
                filterData.event = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            filterData,
            toast: {
                type: '',
                message: ''
            }
        });

        //This is to give a delay to update state
        setTimeout(() => { this.updateGridState() }, 250);

    }

    // This is added to load spinner between grid filter
    gridLoader = () => {
        let { gridConfig } = this.state;

        gridConfig.gridData = [];
        gridConfig.meta.totalItems = '';
        gridConfig.meta.showLoadingSpinner = true;
        gridConfig.meta.selectedPage = 1;
        gridConfig.meta.orderBy = 'id';
        gridConfig.meta.sortBy = 'desc';

        this.setState({
            gridConfig
        })
    }

    // Handle refresh button click here
    handleRefresh = () => {
        this.setState({
            reloader: true,
            filterData: {
                mobile: '',
                event: '',
                package: '',
                startDate: '',
                endDate: '',
                customerId: '',
            }
        });
        setTimeout(() => {
            this.setState({
                reloader: false,
            });
            this.gridLoader();
            this.updateGridState();
        }, 1);
    }

    updateGridState = () => {
        let { gridConfig, gridData, filterData, userData } = this.state;
        let toast = {}

        //Change the params as access by api
        switch (gridConfig.meta.orderBy) {
            case 'eventType': gridConfig.meta.orderBy = 'eventsType'; break;
        }

        filterData.mobile = userData.mobileNumber;
        console.log(" userData " , userData);

        //To get data from server-side pagination need to pass pageSize, selectedPage, orderBy, sortBy
        subscriberService.getIndividualSubscribersLog(gridConfig.meta, filterData).then((results) => {
            if (results.statusCode === 200) {
                gridConfig.gridData = results.data.data;
                gridConfig.meta.totalItems = results.data.page.total;
                gridConfig.meta.showLoadingSpinner = false;
                gridData = results.data.data;
                this.setState({ gridConfig, gridData, showLoadingSpinner: false });
                this.props.onSearch(toast);
            } else {
                gridConfig.meta.showLoadingSpinner = false;
                toast = {
                    type: 'warning',
                    message: results.data.description
                }
                this.props.onSearch(toast);
                this.setState({ gridConfig })
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            toast = {
                type: 'warning',
                message: error.message
            }
            this.props.onSearch(toast);
            this.setState({ gridConfig })

        });
    }

    componentDidMount = () => {
        let { packageList, smsEventType } = this.state;

        reportService.getPackageList().then((results) => {
            if (results.statusCode === 200) {
                packageList = results.data
                packageList.unshift({ id: 0, name: 'All' });  //Added 'all' at the beginning of the drop-down

                this.setState({
                    packageList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        subscriberService.getSmsEventType().then((results) => {
            if (results.statusCode === 200) {
                smsEventType = results.data.map(data => {
                        let name = toCamelCase(data.name);
                        data.name = name
                        return data;
                })

                smsEventType.splice(5, 1);
                smsEventType.unshift({ id: 0, name: 'All' });  //Added 'all' at the beginning of the drop-down
                this.setState({
                    smsEventType
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
        this.updateGridState();
    }

    //Handle date-range picker input
    handleEvent = (event, picker) => {
        let filterData = { ...this.state.filterData };

        filterData.startDate = picker.startDate.format('YYYY-MM-DD') + ' 00:00:00';
        filterData.endDate = picker.endDate.format('YYYY-MM-DD') + ' 23:59:59';

        this.setState({
            filterData
        })

        setTimeout(() => { this.updateGridState() }, 50);
        this.gridLoader();
    }

    handleActionClick = (params) => {
        const { packageList } = this.state;
        const day = packageList.find((day) => {
            return day.name === params.data.name;
        });

        switch (params.type) {
            case 'RESEND':
                if (params.data.smsResend) {
                    this.setState({
                        showModal: true,
                        smsId: params.data.id,
                        toast: {
                            type: '',
                            message: ''
                        }
                    })
                }
                break;

            default:
                break;
        }
    }

    //The confirm modal close handle here
    handleConfirmModalClose = (params) => {
        const { smsId } = this.state;

        let toast = {
            type: '',
            message: ''
        }

        if (params !== 'cancel') {
            subscriberService.resendSMS(smsId).then((results) => {
                if (results.statusCode === 200) {
                    toast = {
                        type: 'success',
                        message: results.data.description
                    }
                    this.props.onSearch(toast);
                    this.updateGridState();
                } else {
                    //Business logic error here
                    toast = {
                        type: 'warning',
                        message: results.data.description
                    }
                    this.props.onSearch(toast);
                }
            }).catch((error) => {
                toast = {
                    type: 'warning',
                    message: error.message
                }
                this.props.onSearch(toast);
            });

        }
        this.setState({ showModal: false })
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        if (gridConfig.gridData) {
            return (
                <ServerSideGrid gridConfig={gridConfig}
                    actionHandler={this.handleActionClick}
                    handleOrder={this.handleResponce}
                    handlePage={this.handleResponce}
                    toggleModal={'collapse'} />
            )
        }
    }

    renderConfirmModal = () => {
        const { showModal } = this.state;
        if (showModal) {
            return (
                <ConfirmModal isOpen={showModal} message={'Are you sure you want to Resend?'} onClose={this.handleConfirmModalClose} />
            )
        }
    }

    render() {
        const { smsEventType, reloader } = this.state;

        return (
            <div>
                {this.state.showLoadingSpinner ?
                    <Loader /> : ''}
                <Row style={{ padding: '10px', paddingLeft: '20px' }}>
                    <Col md={4}>
                        {reloader ? '' :
                            <CustomDropdown
                                type={'event'}
                                placeholder={{ name: 'Event Type' }}
                                options={smsEventType}
                                handleOnSelected={this.handleOnDropdownSelected} />}
                    </Col>
                    <Col md={3}>
                        {reloader ? '' :
                            <DateRangePicker onApply={this.handleEvent}>
                                <Button className='btn-rounded' outline >Date Range <i className="fas fa-calendar-alt"></i></Button>
                            </DateRangePicker>}
                    </Col>
                    <Col md={5} className='text-right'>
                        <Button className='btn-rounded' color='secondary' onClick={() => this.handleRefresh()}>
                            <i className="fas fa-redo"></i>
                        </Button>
                    </Col>
                </Row>
                {reloader ? '' : this.renderGrid()}
                {this.renderConfirmModal()}
            </div>

        )
    }
}

export default IndividualSubscriptionLog;

function toCamelCase(str) {
    return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
    });
}