/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input, Label } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import SearchInput from '../common/custom-controls/SearchInput';
import CustomGrid from '../common/custom-controls/CustomGrid';
import CustomDropdown from './../common/custom-controls/CustomDropdown';
import { reportService } from '../../services/report-service';
import { drawService } from '../../services/draw-service';
import { winnersService } from '../../services/winners-service';
import DrawSettle from './DrawSettle'
import WinnersView from './WinnersView'
import './../custom.css'
class Winners extends Component {

    GRID_CONFIG = {
        columns: [
            {
                id: 1,
                name: 'drawDate',
                headerTitle: 'Date',
                canSort: true,
                width: '9%'
            },
            {
                id: 2,
                name: 'drawType',
                headerTitle: 'Winning Chance',
                canSort: true,
                width: '5%'
            },
            {
                id: 3,
                name: 'mobile',
                headerTitle: 'Mobile Number',
                canSort: true,
                width: '9%'
            },
            {
                id: 4,
                // id:9,
                name: 'cusNIC',
                headerTitle: 'Customer NIC',
                canSort: true,
                width:'12%'
            },
            {
                id: 3,
                name: 'serialNumber',
                headerTitle: 'Serial Number',
                canSort: true,
                width: '9%'
            },
            {
                id: 5,
                name: 'ticketNumber',
                headerTitle: 'Ticket Number',
                canSort: true,
                width: '9%'
            },
            {
                id: 6,
                name: 'prizeType',
                headerTitle: 'Prize Type',
                canSort: true,
                width: '5%'
            },
            {
                id: 7,
                name: 'prizeDescription',
                headerTitle: 'Prize Description',
                canSort: true,
                width: '9%'
            },
            {
                id: 8,
                name: 'price',
                headerTitle: 'Prize (LKR)',
                canSort: true,
                width: '9%'
            },
            {
                id: 9,
                name: 'claimStatus',
                headerTitle: 'Claim Status',
                canSort: true,
                width: '7%'
            },
            {
                id: 10,
                name: 'retryStatus',
                headerTitle: 'Auto Settlements',
                canSort: true,
                width: '9%'
            },
            // {
            //     id: 6,
            //     name: 'retryStatus',
            //     headerTitle: 'Auto Settlements',
            //     canSort: true,
            //     width: '15%'
            // },
            {
                id: 11,
                name: 'status',
                headerTitle: 'Status',
                canSort: true,
                useTemplate: true,
                width: '9%'
                // width: '12%'
            },
            {
                id: 12,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '25%',
                useTemplate: true,
                templates: [
                    {
                        type: 'WINNER-ACTION',
                        template: ``
                    }
                ]
            },

        ],
        gridData: [],
        meta: {
            pageSize: 10,
            showLoadingSpinner: false
        }

    }

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showSettleModal: false,
            showViewModal: false,
            showLandingPage: true,
            WinnersId: '',
            winnersData: '',
            gridConfig: this.GRID_CONFIG,
            toast: {
                type: '',
                message: ''
            },
            gridData: [],
            lotteriesList: [],
            statusList: [],
            actionData: [],
            inputField: {
                lotteriesList: '',
                statusList: '',
                drawId: '',
                drawDate: '',
                mobile: ''

            },
        }
    }

    updateGridState = () => {
        let { gridConfig, gridData } = this.state;

        this.setState({
            gridData: {
                gridData: ''
            }
        })
    }

    componentWillMount() {
        // let { lotteriesList } = this.state;
        drawService.getAllDrawStatus().then((results) => {
            if (results.statusCode === 200) {
                var statusList = results.data
                this.setState({
                    statusList: statusList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        reportService.getLotteryList().then((results) => {
            if (results.statusCode === 200) {
                var lotteriesList = results.data
                this.setState({
                    lotteriesList: lotteriesList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    renderGrid = () => {
        const { gridConfig } = this.state;

        if (gridConfig.gridData) {
            return (
                <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick} selectHandler={this.handleResponce} toggleModal={'collapse'}></CustomGrid>
            )
        }
    }

    handleOnDropdownSelected = (selected, type) => {
        let inputField = { ...this.state.inputField };

        switch (type) {
            case 'lotteriesList':
                inputField.lotteriesList = selected.id;
                break;
            case 'statusList':
                inputField.statusList = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            inputField,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleSearch = () => {
        let inputField = { ...this.state.inputField };
        let { gridConfig } = this.state;
        if (inputField.drawId) {
            this.setState({ showLandingPage: false });
            winnersService.getAllWinnersFilter(inputField.drawId, inputField.lotteriesList).then((results) => {
                if (results.statusCode === 200) {
                    gridConfig.gridData = results.data;
                    gridConfig.meta.showLoadingSpinner = false;
                    this.setState({ gridConfig });
                } else {
                    //TODO
                }

            }).catch((error) => {
                gridConfig.meta.showLoadingSpinner = false;
                this.setState({
                    gridConfig,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.setState({
                inputField,
                toast: {
                    type: 'warning',
                    message: 'Please enter draw number to search'
                }
            });
        }

    }

    handleActionClick = (params) => {
        switch (params.type) {

            case 'ACTION':
                if (params.data.status === 'Settled') {
                    this.setState({
                        showViewModal: true,
                        winnersData: params.data,
                        toast: {
                            type: '',
                            message: ''
                        }
                    })
                }

                if (params.data.status === 'Unsettled' && params.data.price === 'High Prize') {
                    this.setState({
                        showViewModal: true,
                        winnersData: params.data,
                        toast: {
                            type: '',
                            message: ''
                        }
                    })
                }

                if (params.data.status === 'Unsettled' && params.data.prizeType === 'ITEM') {
                    this.setState({
                        showViewModal: true,
                        winnersData: params.data,
                        toast: {
                            type: '',
                            message: ''
                        }
                    })
                }

                if (params.data.status === 'Unsettled' && !(params.data.price === 'High Prize' || params.data.prizeType === 'ITEM') ) {
                    this.setState({
                        // showSettleModal: true,
                        showViewModal: true,
                        WinnersId: params.data.id,
                        winnersData: params.data,
                        toast: {
                            type: '',
                            message: ''
                        }
                    })
                }
                break;
            case 'VIEW':
                let drawId = params.data.id;
                let drawData = params.data;
                this.setState({
                    showDrawViewModal: true,
                    drawId,
                    drawData,
                    toast: {
                        type: '',
                        message: ''
                    }
                })
                break;
            default:
                break;
        }
    }

    handleSubmitClose = (msg) => {
        let { gridConfig } = this.state;

        if (msg === 'success') {
            this.handleSearch();
            this.setState({
                toast: {
                    type: 'success',
                    message: 'Data has been saved.'
                }
            })
        }

        this.setState({
            showSettleModal: false,
            showViewModal: false
        });

        this.updateGridState();
    }

    handleValueChange = (e) => {
        let inputField = { ...this.state.inputField };
        inputField[e.target.name] = e.target.value;
        this.setState({
            inputField
        });

    }

    renderModal = () => {

        if (this.state.showSettleModal) {
            return (
                <DrawSettle winnersData={this.state.winnersData} drawId={this.state.drawId} showModal={this.state.showSettleModal} onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.showViewModal) {
            return (
                <WinnersView winnersData={this.state.winnersData} drawId={this.state.drawId} showModal={this.state.showViewModal} onClose={this.handleSubmitClose} />
            )
        }

    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {

        const { toast, lotteriesList, inputField, statusList, showLandingPage } = this.state;
        console.log(showLandingPage)
        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col md={4}>
                        <label><h4>Winners Report</h4></label>
                    </Col>
                </Row>
                <Row>
                    {/* <Col md={3}>
                        <FormGroup>
                            <Label for="firstName">Date</Label>
                            <Input type="date" name="drawDate" id="drawDate" value={inputField.drawDate} onChange={this.handleValueChange} placeholder="Draw Id" />
                        </FormGroup>
                    </Col> */}
                    <Col md={3}>
                        <FormGroup>
                            {/* <Label for="firstName">Draw Number</Label> */}
                            <Input type="number" className="form-control-rounded" name="drawId" id="drawId" value={inputField.drawId} onChange={this.handleValueChange} placeholder="Draw Number" />
                        </FormGroup>
                    </Col>
                    {/* <Col md={3}>
                        <FormGroup>
                            <Label for="firstName">Telephone Number</Label>
                            <Input type="number" maxLength="10" name="mobile" id="mobile" value={inputField.mobile} onChange={this.handleValueChange} placeholder="Telephone Number" />
                        </FormGroup>
                    </Col> */}
                    <Col md={3}>
                        <FormGroup>
                            <CustomDropdown type={'lotteriesList'} placeholder={{ name: 'Select Lottery' }} options={lotteriesList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                        </FormGroup>
                    </Col>
                    {/* <Col md={3}>
                        <FormGroup>
                            <Label for="firstName">Rewarded</Label>
                            <CustomDropdown type={'statusList'} placeholder={{ name: 'Status' }} options={statusList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                        </FormGroup>
                    </Col> */}
                    <Col md={3}>
                        <Button color="primary" className="btn-rounded" onClick={this.handleSearch} ><i className="fas fa-search"></i> Search</Button>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>

                        <div >
                            {showLandingPage ? <h6>
                                Please Enter Your Draw Number
                            </h6> : this.renderGrid()}

                        </div>
                    </Col>
                </Row>
                {this.renderModal()}
            </Container>);
    }

}



export default Winners;

