/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React from 'react';
import { Spinner } from 'reactstrap';

class Loader extends React.Component {

    // render() {
    //     return (
    //         <div style={{ position: 'absolute', zIndex: 10000, backgroundColor: 'gray', opacity: '0.5', width: '100%', height: '100%' }}>
    //             <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 11000, }}>
    //                 <Spinner style={{ width: '3rem', height: '3rem' }} color="warning" />
    //             </div>
    //         </div>

    //     )
    // }


    render() {
        return (
            <div style={{ position: 'absolute', zIndex: 10000, backgroundColor: 'gray', opacity: '0.3', width: '97%', height: '92%' }}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 11000, }}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="warning" />
                </div>
            </div>

        )
    }
}


export default Loader;