/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

class SearchInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: ''
        }
    }

    handleChange = (e) => {
        this.setState({
            filter: e.target.value
        })
        this.props.onChange(e.target.value, e.target.name)
    }

    render() {
        const { name, placeholder, className } = this.props;
        return (
            <Input type="search" id="filter" name={name} placeholder={placeholder} value={this.state.filter} onChange={this.handleChange} className={className} />
        )
    }
}

SearchInput.defaultProps = {
    placeholder: 'Search'
}

SearchInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default SearchInput;
