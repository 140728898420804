/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup } from 'reactstrap';

import CustomGrid from '../../common/custom-controls/CustomGrid';
import Toast from '../../common/custom-controls/Toast';
import { lotteryService } from '../../../services/lottery-service';
import SearchInput from '../../common/custom-controls/SearchInput';
import ConfirmModal from '../../common/custom-controls/ConfirmModal';
import { reportService } from '../../../services/report-service';

class Lotteries extends Component {

    lotteryIdToBeDeleted = 0;

    GRID_CONFIG = {
        columns: [
            // {
            //     id: 1,
            //     name: 'id',
            //     headerTitle: 'Id',
            //     canSort: true,
            //     width: '10%'
            // },
            {
                id: 2,
                name: 'name',
                headerTitle: 'Lottery Name',
                canSort: true,
                width: '55%'
            },
            {
                id: 3,
                name: 'isActive',
                headerTitle: 'Status',
                canSort: true,
                useTemplate: true,
                width: '15%'
            },
            {
                id: 4,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '20%',
                useTemplate: true,
                templates: [
                    {
                        type: 'EDIT',
                        template: `<span>
                        <button class="btn btn-sm btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-pen"></i></button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="/lotteries/:id/edit?step=1">Lottery Basics</a>
                            <a class="dropdown-item" href="/lotteries/:id/edit?step=2">Lottery Format</a>
                            <a class="dropdown-item" href="/lotteries/:id/edit?step=3">Draws</a>
                            <a class="dropdown-item" href="/lotteries/:id/edit?step=4">SMS Templates</a>
                        </div>
                  </span>`
                    },
                    {
                        type: 'VIEW',
                        template: `<a href="/lotteries/:id" class="btn btn-info btn-sm"><i class="fas fa-eye"></i></a>`
                    },
                    // {
                    //     type: 'DELETE',
                    //     template: `<button class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>`
                    // }
                ]
            }
        ],
        gridData: [],
        meta: {
            pageSize: 8,
            showLoadingSpinner: true
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            gridConfig: this.GRID_CONFIG,
            userId: null,
            toast: {
                type: '',
                message: ''
            },
            gridData: []
        }
    }

    createNewLottery = () => {
        this.props.history.push('/lotteries/new');
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        // console.log(gridConfig)
        if (gridConfig.gridData) {
            return (
                <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick}></CustomGrid>
            )
        }
    }

    updateGridState = () => {
        let { gridConfig, gridData } = this.state;

        reportService.getLotteryList().then((results) => {
            if (results.statusCode === 200) {
                gridConfig.gridData = results.data;
                gridConfig.meta.showLoadingSpinner = false;
                gridData = results.data;
                this.setState({ gridConfig, gridData });
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            this.setState({
                gridConfig,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    componentDidMount = () => {
        this.updateGridState();
    }

    handleActionClick = (params) => {
        switch (params.type) {
            case 'EDIT':
                if (params.redirectTo) {
                    const redirectTo = params.redirectTo.replace(':id', params.data.id);
                    this.props.history.push(redirectTo);
                }
                break;
            case 'VIEW':
                if (params.redirectTo) {
                    const redirectTo = params.redirectTo.replace(':id', params.data.id);
                    this.props.history.push(redirectTo);
                }
                break;
            case 'DELETE':
                this.lotteryIdToBeDeleted = params.data.id;
                this.setState({
                    showModal: true,
                    toast: {
                        type: '',
                        message: ''
                    }
                });
                break;
            default:
                break;
        }
    }

    filterData = (filterText, filterColumn) => {
        let { gridConfig } = this.state;
        if (filterText) {
            gridConfig.gridData = this.state.gridData.filter((data) => {
                const columnData = data[filterColumn].toLowerCase();
                return columnData.indexOf(filterText.toLowerCase()) !== -1
            });
        } else {
            gridConfig.gridData = this.state.gridData;
        }

        this.setState({ gridConfig });
    }

    handleDeleteConfim = (params) => {
        if (params !== 'cancel') {
            lotteryService.archiveLottery(this.lotteryIdToBeDeleted).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        toast: {
                            type: 'success',
                            message: 'Data has been archived.'
                        }
                    });
                    this.updateGridState();
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                });
            });
        }
        this.setState({ showModal: false })
    }

    renderConfirmModal = () => {
        const { showModal } = this.state;
        if (showModal) {
            return (
                <ConfirmModal isOpen={showModal} message={'Are you sure you want to delete this record?'} onClose={this.handleDeleteConfim}></ConfirmModal>
            )
        }
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast } = this.state;
        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col sm={2}>
                        <label><h3>Lotteries</h3></label>
                    </Col>
                    <Col sm={7}>
                        <Form inline>
                            <FormGroup row>
                                <SearchInput onChange={this.filterData} name={'name'} placeholder={'Search Lottery'} className={'mr-3'}></SearchInput>
                                {/* <Button color="primary"><i className="fas fa-search"></i> Search</Button> */}
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col sm={3}>
                        <Button color="success float-right text-bold" onClick={() => this.createNewLottery()}><i className="fas fa-plus"></i>  Add New Lottery</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {this.renderGrid()}
                    </Col>
                </Row>
                {this.renderConfirmModal()}
            </Container>);
    }
}

export default Lotteries;
