/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

class ConfirmModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: props.isOpen
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = (params) => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));

        if (params === 'ok') {
            this.props.onClose(params);
        } else {
            this.props.onClose('cancel');
        }
    }

    render() {
        const { okayButtonText, cancelButtonText, message } = this.props;
        return (
            <div>
                <Modal fade centered autoFocus isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody className="text-center p-4">
                        <p>{message}</p>
                        <Button color="primary" className="mr-2" onClick={() => this.toggle('ok')}>{okayButtonText}</Button>
                        <Button outline color="primary" onClick={() => this.toggle('cancel')}>{cancelButtonText}</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

ConfirmModal.defaultProps = {
    okayButtonText: 'Yes',
    cancelButtonText: 'No',
    message: 'Are you sure you want to continue?'
}

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    okayButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func.isRequired
}

export default ConfirmModal;
