/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Row, Col, Input, FormGroup, Label, CustomInput, Fade } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';
import { conditionalExpression } from '@babel/types';

class LotteryBasics extends Component {

    INITIAL_STATE = {
        lotteryBasics: {
            id: '',
            name: '',
            lotteryColor: '',
            bonusFields: '',
            mainImage: '',
            isMainImageUpdated: false,
            mobileImage: '',
            isMobileImageUpdated: false,
            buyingPrice: '',
            ticketPrice: '',
            validityDays: '',
            appId: '',
            numberMask: '',
            ticketRowCount:1
        }
    }

    fileSizeExceed = null;
    validator = null;
    fileFormatIncorrect = null;

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                keywordsEqual: {
                    message: 'Subscription Keyword and On-demand Keyword must not be equal',
                    rule: () => {
                        return this.state.lotteryBasics.onDemandKeyword && this.state.lotteryBasics.subscriptionKeyword && this.state.lotteryBasics.onDemandKeyword.toUpperCase() != this.state.lotteryBasics.subscriptionKeyword.toUpperCase();
                    },
                    required: true
                },
                acceptPng: {
                    message: 'Image format shoul be png',
                    rule: () => {
                        console.log(this.statelotteryBasics.mainImage)
                        return this.state.lotteryBasics.onDemandKeyword && this.state.lotteryBasics.subscriptionKeyword && this.state.lotteryBasics.onDemandKeyword != this.state.lotteryBasics.subscriptionKeyword;
                    },
                    required: true
                }
            }
        });

        this.state = {
            ...this.INITIAL_STATE
        }
    }

    handleValueChange = (e) => {
        let lotteryBasics = { ...this.state.lotteryBasics };
        lotteryBasics[e.target.name] = e.target.value;
        this.setState({ lotteryBasics });
    }

    validateAndRetrieveState = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return false;
        }
        return this.state.lotteryBasics;
    }

    handleImageChange = (e) => {
        e.preventDefault();

        const isMainImage = e.target.name === 'mainImage';
        let { lotteryBasics } = this.state;

        const reader = new FileReader();
        const file = e.target.files[0];

        if (file) {
            if (file.type === 'image/png') {
                // if (file.size > 2 * 1024 * 1024) {
                if (file.size > 400 * 1024) {
                    // 2 MB validation
                    this.fileSizeExceed = e.target.name;
                    this.validator.showMessageFor('mainImage');
                    if (isMainImage) {
                        lotteryBasics.mainImage = '';
                    } else {
                        lotteryBasics.mobileImage = '';
                    }
                } else {
                    reader.onloadend = () => {
                        if (isMainImage) {
                            lotteryBasics.isMainImageUpdated = true;
                            lotteryBasics.mainImage = reader.result;
                        } else {
                            lotteryBasics.isMobileImageUpdated = true;
                            lotteryBasics.mobileImage = reader.result;
                        }

                        this.setState({ lotteryBasics });
                    }

                    reader.readAsDataURL(file);
                    this.fileSizeExceed = null;
                    this.fileFormatIncorrect = null;
                }
            } else {
                this.fileFormatIncorrect = e.target.name;
                this.validator.showMessageFor('mainImage');
            }
        }
    }

    imageDeleteHandler = (imageType) => {
        let { lotteryBasics } = this.state;

        if (imageType === 'mainImage') {
            lotteryBasics.mainImage = '';
            lotteryBasics.isMainImageUpdated = true;
            document.getElementById('mainImage').value = null;
        }

        if (imageType === 'mobileImage') {
            lotteryBasics.mobileImage = '';
            lotteryBasics.isMobileImageUpdated = true;
            document.getElementById('mobileImage').value = null;
        }

        this.setState({
            lotteryBasics
        });
    }

    componentDidMount = () => {
        const lotteryProps = this.props.lotteryBasics;


        if (Object.entries(lotteryProps).length > 0) {

            let { lotteryBasics } = this.state;


            Object.entries(lotteryProps).map((value) => {
                lotteryBasics[value[0]] = value[1];
            });


            if (lotteryBasics.mainImage) {
                // if (!(lotteryBasics.mainImage.substring(0, 22) === 'data:image/png;base64,')) {
                //     lotteryBasics.mainImage = 'data:image/png;base64,' + lotteryBasics.mainImage.substring(29);
                // }
                lotteryBasics.isMainImageUpdated = true;
            }

            if (lotteryBasics.mobileImage) {
                // if (!(lotteryBasics.mobileImage.substring(0, 22) === 'data:image/png;base64,')) {
                //     lotteryBasics.mobileImage = 'data:image/png;base64,' + lotteryBasics.mobileImage.substring(29);
                // }
                lotteryBasics.isMobileImageUpdated = true;
            }

            this.setState({
                lotteryBasics
            });
        }
    }

    render() {
        const { lotteryBasics } = this.state;
        const { propertyReadOnly } = this.props;
        return (
            <div>
                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="name">Lottery Name</Label>
                            <Input type="text" maxLength="100" name="name" id="name" placeholder="Lottery Name" value={lotteryBasics.name} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Lottery Name', lotteryBasics.name, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="name">Ticket Row Count</Label>
                            <Input type="number" maxLength="100" name="ticketRowCount" id="ticketRowCount" placeholder="Ticket Row Count" value={lotteryBasics.ticketRowCount} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Ticket Row Count', lotteryBasics.ticketRowCount, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>
                
                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="lotteryColor">Lottery Color</Label>
                            <Input type="text" name="lotteryColor" id="lotteryColor" value={lotteryBasics.lotteryColor} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Lottery Color', lotteryBasics.lotteryColor, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="bonusFields">Bonus Fields</Label>
                            <Input type="number" name="bonusFields" id="bonusFields" value={lotteryBasics.bonusFields} onChange={this.handleValueChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="mainImage">Main Image</Label>
                            <CustomInput type="file" id="mainImage" name="mainImage" onChange={this.handleImageChange} accept="image/png" />
                            {this.fileSizeExceed === 'mainImage' ? <span className="text-danger"><small>{'Main Image size exceeds maximum limit 400 KB.'}</small></span> : ''}
                            {this.fileFormatIncorrect === 'mainImage' ? <span className="text-danger"><small>{'Image format should be png'}</small></span> : ''}
                            <span className="text-danger"><small>{this.validator.message('Main Image', lotteryBasics.mainImage, 'required')}</small></span>
                        </FormGroup>
                        {lotteryBasics.mainImage ? <Fade className="position-relative" in={lotteryBasics.mainImage !== ''} timeout={150}><img className="w-50 rounded border" src={lotteryBasics.mainImage} />
                            <button type="button" className="close position-absolute pl-2" onClick={() => this.imageDeleteHandler('mainImage')}>
                                <i className="far fa-times-circle"></i>
                            </button>
                        </Fade> : ''}
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="mobileImage">Mobile Image</Label>
                            <CustomInput type="file" id="mobileImage" name="mobileImage" onChange={this.handleImageChange} accept=".png" />
                            {this.fileSizeExceed === 'mobileImage' ? <span className="text-danger"><small>{'Mobile Image size exceeds maximum 400 KB.'}</small></span> : ''}
                            {this.fileFormatIncorrect === 'mobileImage' ? <span className="text-danger"><small>{'Image format should be png'}</small></span> : ''}
                            <span className="text-danger"><small>{this.validator.message('Mobile Image', lotteryBasics.mobileImage, 'required')}</small></span>
                        </FormGroup >
                        {lotteryBasics.mobileImage ? <Fade className="position-relative" in={lotteryBasics.mobileImage !== ''} timeout={150}><img className="w-50 rounded border" src={lotteryBasics.mobileImage} />
                            <button type="button" className="close position-absolute pl-2" onClick={() => this.imageDeleteHandler('mobileImage')}>
                                <i className="far fa-times-circle"></i>
                            </button>
                        </Fade> : ''}
                    </Col>
                </Row>
                <Row form className='mt-3'>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="numberMask">Number Mask</Label>
                            <Input type="text" name="numberMask" id="numberMask" step="any" value={lotteryBasics.numberMask} onChange={this.handleValueChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="py-4">
                    <Col md={12} >
                        <div className="border-bottom"><h5>Price</h5></div>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="buyingPrice">Buying Price - Rs</Label>
                            <Input type="number" name="buyingPrice" id="buyingPrice" min="1" step="any" value={lotteryBasics.buyingPrice} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Buying Price', lotteryBasics.buyingPrice, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="ticketPrice">Ticket Price - Rs</Label>
                            <Input type="number" name="ticketPrice" id="ticketPrice" min="1" step="any" value={lotteryBasics.ticketPrice} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Ticket Price', lotteryBasics.ticketPrice, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="py-4">
                    <Col md={12}>
                        <div className="border-bottom"><h5>Tickets</h5></div>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="validityDays">Ticket Validity - Days</Label>
                            <Input type="number" name="validityDays" id="validityDays" value={lotteryBasics.validityDays} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Ticket Validity', lotteryBasics.validityDays, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="appId">App Id</Label>
                            <Input type="text" name="appId" id="appId" value={lotteryBasics.appId} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('App Id', lotteryBasics.appId, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>

                <Row form hidden={propertyReadOnly ? true : false}>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="subscriptionKeyword">Subscription Keyword</Label>
                            <Input type="text" name="subscriptionKeyword" id="subscriptionKeyword" value={lotteryBasics.subscriptionKeyword} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{propertyReadOnly ? '' : this.validator.message('Subscription Keyword', lotteryBasics.subscriptionKeyword, 'keywordsEqual|required')}</small></span>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="onDemandKeyword">On-demand Keyword</Label>
                            <Input type="text" name="onDemandKeyword" id="onDemandKeyword" value={lotteryBasics.onDemandKeyword} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{propertyReadOnly ? '' : this.validator.message('On-demand Keyword', lotteryBasics.onDemandKeyword, 'keywordsEqual|required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>
                {lotteryBasics.id ?
                    <Row form>
                        <Col md={6}>
                            <FormGroup className="required">
                                <Label for="initialSearchVisibility">Initial Search Visibility</Label>
                                <Input type="text" name="initialSearchVisibility" id="initialSearchVisibility" value={lotteryBasics.initialSearchVisibility} onChange={this.handleValueChange} />
                                <span className="text-danger"><small>{this.validator.message('Initial Search Visibility', lotteryBasics.initialSearchVisibility, 'required')}</small></span>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup className="required">
                                <Label for="searchBand">Search Band</Label>
                                <Input type="text" name="searchBand" id="searchBand" value={lotteryBasics.searchBand} onChange={this.handleValueChange} />
                                <span className="text-danger"><small>{this.validator.message('Search Band', lotteryBasics.searchBand, 'required')}</small></span>
                            </FormGroup>
                        </Col>
                    </Row> : ''
                }

            </div>
        )
    }
}

LotteryBasics.propTypes = {
    lotteryBasics: PropTypes.object.isRequired
}

export default LotteryBasics;
