/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { BASE_API } from './configs';
import axios from 'axios';


//https://www.carlrippon.com/fetch-with-async-await-and-typescript/

const http = (request) => {
    return new Promise((resolve, reject) => {
        let response;
        fetch(request)
            .then(res => {
                response = res;
                return res.json();
            })
            .then(body => {
                if (response.ok) {
                    resolve({
                        statusCode: response.status,
                        data: body,
                        error: null
                    });
                } else {
                    reject(
                        {
                            statusCode: body.statusCode,
                            message: body.description,
                            error: body.name
                        }
                    );
                }
            })
            .catch(err => {
                let errorResponse = {
                    statusCode: null,
                    message: '',
                    error: err
                }

                if (!response) {
                    errorResponse.message = 'Connection error. Try again.';
                    reject(errorResponse);
                    return;
                }

                errorResponse.statusCode = response.status ? response.status : '';

                if (response.status) {
                    if (response.status === 403) {
                        // sign out user
                        errorResponse.message = 'Please do login again.';
                        window.location.replace('/login');
                    } else if (response.status === 401) {
                        // unauthorized
                    } else if (response.status === 500) {
                        errorResponse.message = 'Something went wrong. Try again.';
                    } else {
                        errorResponse.message = 'Something went wrong. Try again.';
                    }
                }
                reject(errorResponse);
            });
    });
};

export const get = async (path, args = { method: 'get' }) => {
    return await http(new Request(`${BASE_API}/${path}`, args));
};

export const post = async (path, body, args = { method: 'post', body: JSON.stringify(body) }) => {
    return await http(new Request(`${BASE_API}/${path}`, args));
};

export const put = async (path, body, args = { method: 'put', body: JSON.stringify(body) }) => {
    return await http(new Request(`${BASE_API}/${path}`, args));
};

export const patch = async (path, body, args = { method: 'PATCH', body: JSON.stringify(body) }) => {
    return await http(new Request(`${BASE_API}/${path}`, args));
};

export const remove = async (path, args = { method: 'delete' }) => {
    return await http(new Request(`${BASE_API}/${path}`, args));
};

export const fileUpload = async (path, body) => {
    return await axios.post(`${BASE_API}/${path}`, body);
};



// export const fileUpload = (path, data) => {
//     fetch(`${BASE_API}/${path}`, {
//         mode: 'no-cors',
//         method: "POST",
//         body: data
//     }).then(function (res) {
//         if (res.ok) {
//             alert("Perfect! ");
//         } else if (res.status == 401) {
//             alert("Oops! ");
//         }
//     }, function (e) {
//         alert("Error submitting form!");
//     });
// }