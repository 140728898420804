/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, CustomInput, ModalFooter, Table } from 'reactstrap';
import { drawService } from '../../services/draw-service';
import Toast from '../common/custom-controls/Toast';
import './../custom.css'
import { green } from '@material-ui/core/colors';
import { element, number } from 'prop-types';
import '../../styles/css/symbols.css';
import { currencyConvertor, currencyConvertorNoSymbol } from '../../utils/currency-convertor';

class DrawView extends Component {
    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        }

    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.INITIAL_STATE,
            id: props.drawId,
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.drawData,
            drawData: [],
            firstData: []
        }
        this.toggle = this.toggle.bind(this);
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    componentWillMount() {
        const { data } = this.state;
        if (data) {
            drawService.getDrawById(data.id, data.lotteryId).then((results) => {
                if (results.statusCode === 200) {
                    let drawData = results.data;
                    let firstData = drawData.resultsList[1];
                    let bonus = drawData.resultsList['BONUS'];
                    this.setState({ drawData: drawData, firstData: firstData });

                }


            }).catch((error) => {
                this.setState({
                    showLoadingSpinner: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.redirectToDraw();
        }
    }


    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    componentDidMount = () => {
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast, drawData, data } = this.state;
        console.log(data);

        return (
            <Modal size="lg" isOpen={this.state.showModal} className={'drawView-modal'} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                {drawData.drawsList ?
                    <ModalBody className={'drawView-body'}>
                        <Row>
                            <h5>
                                Draw No. &nbsp; {drawData.drawsList[0].drawNumber}

                            </h5>

                        </Row>

                        <Row>
                            <Col md={6} >
                                {drawData.resultsList ?
                                    <div>
                                        <Row>
                                            <h6>Winning Numbers</h6>
                                        </Row>
                                        <Row>
                                            {drawData.resultsList[1] && Object.keys(drawData.resultsList[1]).map(index => {
                                                if (index === 'RECD') {
                                                    return (
                                                        Object.keys(drawData.resultsList[1][index]).map(i => {
                                                            return <span key={index + '' + i} className="lotteryFormat badge badge-pill badge-light round-span">{drawData.resultsList[1][index][i]}</span>

                                                        })
                                                    )
                                                } if (drawData.JsonList && Object.keys(drawData.JsonList)[0] === index) {       //Check the JSON name and field name are equal
                                                    let jsonData = drawData.JsonList[index];
                                                    let symbol = '';

                                                    //Every json field have different functionalities
                                                    //Those handle here
                                                    switch (Object.keys(drawData.JsonList)[0]) {
                                                        case 'LAGNA':
                                                            jsonData && Object.keys(jsonData).map(element => {
                                                                if (jsonData[element] === drawData.resultsList[1][index]) {
                                                                    symbol = element
                                                                }
                                                            })

                                                            return <span key={index} className="lotteryFormatSymbol badge badge-pill badge-light round-span">
                                                                <span className='signs' style={{ fontSize: '24px', display: 'block', paddingBottom: '0px' }}>{symbol} </span>
                                                                <span style={{ fontSize: '8px', paddingTop: '0px' }}> {drawData.resultsList[1][index]} </span>
                                                            </span>

                                                        default:
                                                            return <span key={index} className="lotteryFormat lotteryFormat badge badge-pill badge-light round-span">{drawData.resultsList[1][index]}</span>
                                                    }
                                                } else {
                                                    return <span key={index} className="lotteryFormat lotteryFormat badge badge-pill badge-light round-span">{drawData.resultsList[1][index]}</span>
                                                }
                                            })}

                                            {drawData.resultsList['BONUS'] && Object.keys(drawData.resultsList['BONUS']).map(index => {
                                                if (true) {
                                                    return <span key={index} className="lotteryFormat badge badge-pill badge-primary round-span">{drawData.resultsList['BONUS'][index]}</span>
                                                }
                                            })}

                                            {drawData.resultsList['SPECIAL'] ?
                                                <span key={'special'} className="lotteryFormat badge badge-pill badge-light round-span">{drawData.resultsList['SPECIAL']}</span>
                                                : ''}

                                        </Row>

                                        <Row>
                                            {drawData.resultsList[2] && Object.keys(drawData.resultsList[2]).map(index => {
                                                if (true) {
                                                    return <span key={index} className="badge badge-pill badge-light round-span">{drawData.resultsList[2][index]}</span>
                                                }
                                            })}

                                        </Row>
                                    </div> : ''}

                                <br />
                                <Row>
                                    <h6>First Prize</h6>
                                </Row>
                                <Row>
                                    <h3 style={{ color: 'green', font: 'bold' }}>{currencyConvertor(drawData.drawsList[0].jackpotPrize)}</h3>
                                </Row>
                                <br />
                                <br />
                                {drawData.winnersPrizeList.length ? <Row>
                                    <Col md={6}>Prize Amount (LKR)</Col><Col md={6}>No of Winners</Col>
                                </Row> : ''}
                                {drawData.winnersPrizeList.length ? <hr /> : ''}
                                <div className="draw-view-winners-list">
                                {drawData.winnersPrizeList && Object.keys(drawData.winnersPrizeList).map((index) => {
                                    if (true) {
                                        return <Row key={index}>
                                            <Col md={6}>{typeof(drawData.winnersPrizeList[index].price) == "number"?
                                            currencyConvertorNoSymbol(drawData.winnersPrizeList[index].price):drawData.winnersPrizeList[index].price}</Col><Col md={6}>{drawData.winnersPrizeList[index].winnerCount}</Col>
                                            <hr />

                                        </Row>
                                    }
                                })}
                                </div>


                            </Col>
                            <Col md={6} className={'draw-list'}>
                                <hr />
                                <Row>
                                    <Col md={5}>Lottery:</Col><Col md={7}>{drawData.drawsList[0].lotteryName}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={5}>Draw Date:</Col><Col md={7}>{drawData.drawsList[0].drawDate}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={5}>Sales Start On:</Col><Col md={7}>{drawData.drawsList[0].salesStartOn}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={5}>Sales End On:</Col><Col md={7}>{drawData.drawsList[0].salesEndOn}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={5}>Purchased Tickets:</Col><Col md={7}>{drawData.ticketsList[0].purchasedTickets}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={5}>Sold Tickets:</Col><Col md={7}>{drawData.ticketsList[0].soldTickets}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={5}>Unsold Tickets:</Col><Col md={7}>{drawData.ticketsList[0].unsoldTickets}</Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={5}>Returned Tickets:</Col><Col md={7}>{drawData.ticketsList[0].returnedTickets}</Col>
                                </Row>
                                {(data.statusId === 5) ? '' :
                                    <hr />}

                                {(data.statusId === 5) ? '' : <Row>
                                    <Col md={5}>Available Tickets:</Col><Col md={7}>
                                        {drawData.ticketsList[0].availableTickets}
                                    </Col>
                                </Row>}

                                <hr />
                            </Col>
                        </Row>
                        <br />
                        <Row className='text-right'>
                            <Col>
                                <Button outline color="secondary" onClick={this.handleClose}>Cancel</Button>
                            </Col>

                        </Row>

                    </ModalBody>
                    :
                    <ModalBody className={'drawView-body'}>

                        <Row className='text-right'>
                            <Col>
                                <Button outline color="secondary" onClick={this.handleClose}>Cancel</Button>
                            </Col>

                        </Row>

                    </ModalBody>
                }

            </Modal >
        )
    }
}

export default DrawView;
