/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input, Spinner } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import SearchInput from '../common/custom-controls/SearchInput';
import SmsSubscription from './SmsSubscription';
import PackageSummary from './PackageSummary';
import SubscriptionLog from './SubscriptionLog';
import { subscriberService } from '../../services/subscriber-service';
import SimpleReactValidator from 'simple-react-validator';
import CustomDropdown from './../common/custom-controls/CustomDropdown';


class AllSubscribers extends Component {

    validator = null;

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this
        });
        this.state = {
            showModal: false,
            gridConfig: this.GRID_CONFIG,
            userId: null,
            toast: {
                type: '',
                message: ''
            },
            gridData: [],
            showUnsubModel: false,
            searchInputField: '',
            showLoadingSpinner: false
        }
    }

    handleSearch = () => {
        const { searchInputField } = this.state;
        subscriberService.getSubscribersByMobile(searchInputField.mobile).then((results) => {
            if (results.data.id) {
                this.props.history.push(`/subscribers/${results.data.id}`, { mobile: searchInputField.mobile});
            }
        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Number is not there'
                }
            })
        });
    }

    handleValueChange = (e) => {
        let searchInputField = { ...this.state.searchInputField };
        searchInputField[e.target.name] = e.target.value;
        this.setState({
            searchInputField
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    handleOnSearch = (toast) => {
        this.setState({
            toast
        })
    }

    render() {
        const { toast } = this.state;

        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={this.state.toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col md={8}>
                        <label><h4>Subscribers</h4></label>
                    </Col>
                    <Col md={4} className="text-right pl-2">
                        <Form inline>
                            <FormGroup row className="required">
                                {/* <Input type="number" name={'mobile'} maxLength="10" id="mobile" onChange={this.handleValueChange} value={searchInputField.mobile} placeholder={'Telephone Number'}></Input> */}
                                <span className="mr-3">
                                    {/* <Input type="text" name="userRole" id="userRole" placeholder="User Role" className="mr-3" /> */}
                                    {/* <CustomDropdown disabled={true} type={'role'} options={[]} selected={{ name: 'User Role' }} handleOnSelected={this.handleOnDropdownSelected} ></CustomDropdown> */}
                                </span>
                                {/* <Button color="primary" onClick={() => this.handleSearch()} ><i className="fas fa-search"></i> Search</Button> */}
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col sm={3}>
                        {/* <Button color="success float-right" onClick={() => this.toggleModal()}><i className="fas fa-plus"></i> Add New User</Button> */}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{ borderRadius: '.5rem' }} className="border bg-white shadow-sm mt-2">
                            <div className="classic-tabs" >
                                <ul className="nav tabs-primary border-bottom" id="myClassicTab" role="tablist" style={{ fontSize: '15px', padding: '5px' }}>
                                    <li className="nav-item" >
                                        <a className="nav-link waves-light active show text-muted" id="profile-tab-classic" data-toggle="tab" href="#package-summary"
                                            role="tab" aria-controls="profile-classic" aria-selected="true" >Package Summary</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link waves-light text-muted" id="follow-tab-classic" data-toggle="tab" href="#subscribers-log" role="tab"
                                            aria-controls="follow-classic" aria-selected="false">Subscribers Log</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link waves-light text-muted" id="follow-tab-classic" data-toggle="tab" href="#unsubscribe" role="tab"
                                            aria-controls="follow-classic" aria-selected="false">Subscribe</a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className="nav-link waves-light text-muted" id="follow-tab-classic" data-toggle="tab" href="#subscribe" role="tab"
                                            aria-controls="follow-classic" aria-selected="false">Subscribe</a>
                                    </li> */}
                                </ul>
                                <div className="tab-content" id="myClassicTabContent">
                                    <div className="tab-pane fade active show" id="package-summary" role="tabpanel" aria-labelledby="profile-tab-classic">
                                        <PackageSummary onSearch={this.handleOnSearch} />
                                    </div>
                                    <div className="tab-pane fade" id="subscribers-log" role="tabpanel" aria-labelledby="follow-tab-classic">
                                        <SubscriptionLog onSearch={this.handleOnSearch} />
                                    </div>
                                    <div className="tab-pane fade" id="unsubscribe" role="tabpanel" aria-labelledby="profile-tab-classic">
                                        <SmsSubscription />
                                    </div>
                                    <div className="tab-pane fade" id="subscribe" role="tabpanel" aria-labelledby="profile-tab-classic">
                                        <SmsSubscription />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>);
    }
}

export default AllSubscribers;
