/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Button, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import SimpleReactValidator from 'simple-react-validator';

import CustomDropdown from '../../common/custom-controls/CustomDropdown';
import CustomGrid from './../../common/custom-controls/CustomGrid';
import { days } from '../../../utils/meta-data';
import Toast from '../../common/custom-controls/Toast';

class LotteryDraws extends Component {

    INITIAL_STATE = {
        selectedDay: 1,
        lotteryDraw: {
            id: '',
            drawDay: '',
            drawDayInText: '',
            salesStartOn: '',
            salesEndOn: '',
            drawTime: '',
            returnOn: '',
            firstIssueDate: '',
            isArchived: false
        },
        toast: {
            type: '',
            message: ''
        },
        gridConfig: {
            columns: [
                {
                    id: 1,
                    name: 'id',
                    headerTitle: '',
                    canSort: false,
                    width: '0%',
                    hidden: true
                },
                {
                    id: 2,
                    name: 'drawDayInText',
                    headerTitle: 'Draw Day',
                    canSort: true,
                    width: '20%'
                },
                {
                    id: 3,
                    name: 'drawTime',
                    headerTitle: 'Draw Time',
                    canSort: true,
                    width: '25%'
                },
                {
                    id: 4,
                    name: 'actions',
                    headerTitle: 'Actions',
                    canSort: false,
                    width: '25%',
                    useTemplate: true,
                    templates: [
                        {
                            type: 'DELETE',
                            template: `<i class="fas fa-trash-alt text-danger" style="font-size:20px"></i>`
                        }
                    ]
                }
            ],
            gridData: [],
            meta: {
                pageSize: 5
            }
        }
    }

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
        });

        this.state = {
            ...this.INITIAL_STATE
        }
    }

    componentDidMount = () => {
        if (this.props.lotteryDraws.length > 0) {
            let { gridConfig } = this.state;
            gridConfig.gridData = [...this.props.lotteryDraws];
            this.setState({
                gridConfig
            })
        }
    }

    handleValueChange = (e) => {
        let lotteryDraw = { ...this.state.lotteryDraw };
        lotteryDraw[e.target.name] = e.target.value;
        this.setState({
            lotteryDraw
        });
    }

    handleOnDropdownSelected = (selected, type) => {
        let { selectedDay } = this.state;

        switch (type) {
            case 'day':
                selectedDay = selected.id;
                break;
            default:
                break;
        }

        this.setState({ selectedDay });
    }

    addFieldHandler = () => {
        if (this.validator.allValid()) {
            this.validator.hideMessages();

            let { lotteryDraw, gridConfig, selectedDay } = this.state;

            const day = days.find((day) => {
                return day.id === selectedDay;
            });

            lotteryDraw.drawDay = day.id;
            lotteryDraw.drawDayInText = day.name;

            if(!gridConfig.gridData.some(data => data.drawDay === lotteryDraw.drawDay)){
                gridConfig.gridData.push(lotteryDraw);
            }
            else{
                let filteredByDrawday = gridConfig.gridData.filter((item)=>{
                    return item.drawDay === lotteryDraw.drawDay;
                });
                let isGoingToEditLotteryDraw = filteredByDrawday.some(data => data.id != "" && data.isArchived == true);
                if(filteredByDrawday.length <= 2 && isGoingToEditLotteryDraw){
                    let indexOfArchivedLotteryDraw = gridConfig.gridData.findIndex((element)=>
                    element.drawDay === lotteryDraw.drawDay && element.isArchived == true);
                    lotteryDraw.id = gridConfig.gridData[indexOfArchivedLotteryDraw].id;
                    gridConfig.gridData.push(lotteryDraw);
                    gridConfig.gridData.splice(indexOfArchivedLotteryDraw,1);
                }
                else{
                    this.setState({
                        toast: {
                            type : 'warning',
                            message : 'Cannot add multiple lottery draws for same draw day.'
                        }
                    });
                }                
            }

            this.setState({
                selectedDay: 1,
                lotteryDraw: this.INITIAL_STATE.lotteryDraw,
                gridConfig
            });
        } else {
            this.validator.showMessages();
        }
    }

    validateAndRetrieveState = () => {
        const { gridConfig } = this.state;
        let minData = 0;

        for (let index = 0; index < gridConfig.gridData.length; index++) {
            if (gridConfig.gridData[index].isArchived === false) {
                minData += 1
            }
        }

        return minData ? gridConfig.gridData : false;
    }

    handleActionClick = (params) => {
        let { gridConfig } = this.state;
        switch (params.type) {
            case 'DELETE':
                // gridConfig.gridData.splice(params.index, 1);
                let index = (params.selectedPage - 1)*gridConfig.meta.pageSize + params.index;
                gridConfig.gridData[index].isArchived = true;
                if (gridConfig.gridData[index].id === ""){
                    gridConfig.gridData.splice(index,1);
                }
                // gridConfig.gridData[params.index].isArchived = true;

                break;
            default:
                break;
        }

        this.setState({
            gridConfig
        })
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        return (
            <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick}></CustomGrid>
        )
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { lotteryDraw, selectedDay, toast } = this.state;
        const { lotteryName } = this.props;

        console.log(lotteryDraw)
        return (
            <div>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <FormGroup >
                    <Label for="lotteryName">Lottery Name</Label>
                    <Input type="text" name="lotteryName" id="lotteryName" value={lotteryName} readOnly />
                </FormGroup>

                <Row className="py-4">
                    <Col md={12} >
                        <div className="border-bottom"><h5>Add Draw</h5></div>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="day">Day</Label>
                            {days && days.length ?
                                <CustomDropdown type={'day'} options={days} selectedId={selectedDay} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                                : ''
                            }
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="drawTime">Draw Time</Label>
                            <Input type="time" name="drawTime" id="drawTime" value={lotteryDraw.drawTime} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Draw Time', lotteryDraw.drawTime, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="salesStartOn">Sales Start On</Label>
                            <Input type="time" name="salesStartOn" id="salesStartOn" value={lotteryDraw.salesStartOn} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Sales Start On Date', lotteryDraw.salesStartOn, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="salesEndOn">Sales End On</Label>
                            <Input type="time" name="salesEndOn" id="salesEndOn" value={lotteryDraw.salesEndOn} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Sales End On Date', lotteryDraw.salesEndOn, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="firstIssueDate">First Issue Date</Label>
                            <Input type="date" name="firstIssueDate" id="firstIssueDate" value={lotteryDraw.firstIssueDate} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('First Issue Date', lotteryDraw.firstIssueDate, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="returnOn">Return On</Label>
                            <Input type="time" name="returnOn" id="returnOn" value={lotteryDraw.returnOn} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Return On', lotteryDraw.returnOn, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={12}>
                        <Button className="d-block float-right" color="success" onClick={this.addFieldHandler}><i className="fas fa-plus"></i> Add</Button>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.renderGrid()}
                    </Col>
                </Row>

            </div>
        )
    }
}

LotteryDraws.propTypes = {
    lotteryName: PropTypes.string.isRequired,
    lotteryDraws: PropTypes.array.isRequired
}

export default LotteryDraws;
