/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Table, CustomInput } from 'reactstrap';
import PropTypes, { element } from 'prop-types';
import { Spinner } from 'reactstrap';
import './CustomGrid.scss';
import Subscribers from '../../subscribers/Subscribers'
import { statusNew, statusTicketUploaded, statusOngoing, statusClosed, statusOnHold } from './action-template'

import Paginate from './Paginate';
import { subscriberService } from '../../../services/subscriber-service';
import { template } from '@babel/core';
import { userService } from '../../../services/user-service'
import { currencyConvertorNoSymbol } from '../../../utils/currency-convertor';
import { filter } from 'lodash';
import { DRAW_ACTION, DRAW_STATUS } from '../../../utils/meta-data';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_SELECTED_PAGE = 1;

class CustomGrid extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedPage: DEFAULT_SELECTED_PAGE,
            gridConfig: props.gridConfig,
            paginatedData: [],
            sortOrder: '',
            sortBy: '',
            checked: false,
            checkedAll: false,
            selectHandler: props.selectHandler,
            toggleModal: props.toggleModal,
            activeMultiSelect: props.activeMultiSelect
        }
    }

    createActionMarkup = (markup) => {
        return { __html: markup };
    }

    handleSelectClick = () => {
        this.setState({
            checked: !this.state.checked
        });

        let arr = [];
        const x = document.getElementsByClassName("selected");
        for (var i = 0; i < x.length; i++) {
            if (!x[i].checked)
                arr.push(x[i].id);
        }
        if (!arr.length) {
            this.setState({
                checkedAll: true
            });

            document.getElementsByClassName("selectAllCheck")[0].checked = true;
        } else {
            this.setState({
                checkedAll: false
            });
            document.getElementsByClassName("selectAllCheck")[0].checked = false;
        }
        this.props.selectHandler(true);
    }

    handleSelectAllClick = () => {
        this.setState({
            checkedAll: !this.state.checkedAll
        });
        const x = document.getElementsByClassName("selected");
        if (this.state.checkedAll) {
            this.setState({
                checked: false
            });
            for (var i = 0; i < x.length; i++) {
                if (x[i].type === 'checkbox')
                    x[i].checked = false;
            }
        } else {
            this.setState({
                checked: false
            });
            for (var i = 0; i < x.length; i++) {
                if (x[i].type === 'checkbox')
                    x[i].checked = true;
            }
        }
        this.props.selectHandler(true);

    }

    renderActionTemplate = (data, index, rowIndex) => {
        let sele = ''

        //Cancelled draw dont have any actions
        if (data.drawAction.length) {
            if (data.statusId === DRAW_STATUS.NEW && data.ticketsUploaded) {
                data.drawAction = filter(data.drawAction, (currentObject) => {
                    return currentObject.id !== DRAW_ACTION.REMOVE_DRAW;
                })
            }
            if (data.statusId === DRAW_STATUS.NEW && !data.ticketsUploaded) {
                data.drawAction = filter(data.drawAction, (currentObject) => {
                    return currentObject.id !== DRAW_ACTION.DELAY_DRAW;
                })
            }
            data.drawAction.forEach(element => {
                sele += `<a class="dropdown-item" href="${element.actionKeyword}">${element.actionName}</a>`
            });

            var template = `<span>
            <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size:12px, border-radius: 0 !important">Action</button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
            ${sele}</div></span>`

            return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'ACTION', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)
        } else {
            var template = `<span>
            <button class="btn btn-secondary btn-sm dropdown-toggle" disabled type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size:12px, border-radius: 0 !important">Action</button>
           </span>`

            return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'ACTION', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)
        }
    }

    renderTemplates = (column, data, rowIndex, selected) => {
        let disabled = ''

        switch (column.name) {

            case 'status':
                if (data.status == 'Settled') {
                    return (<span style={{ color: 'green' }}>{data.status}</span>);
                } else if (data.status == 'Unsettled') {
                    return (<span style={{ color: 'red' }}>{(data.price === 'High Prize' || data.prizeType !== 'CASH' ) ? '-' : data.status}</span>);
                }
            // eslint-disable-next-line no-fallthrough
            case 'winnersUploaded':         //..Ticket upload, result upload and winners upload conditions handled here
            case 'resultUploaded':          // All are returning true/false
            case 'ticketsUploaded':         // Handled together
                if (data[column.name]) {
                    return (<button className="btn btn-success btn-sm"><i className="fas fa-check"></i></button>);
                } else {
                    return (<button className="btn btn-dark btn-sm"><i className="fas fa-check"></i></button>);
                }
            case 'isActive':
                if (data.isActive) {
                    return (<button className="btn btn-success btn-sm"><i className="fas fa-check"></i></button>);
                } else {
                    return (<button className="btn btn-dark btn-sm"><i className="fas fa-check"></i></button>);
                }
            case 'multipleSelect':
                return (
                    <input id={data.id} className='selected' defaultChecked={this.state.checked} type="checkbox" onChange={(e) => this.handleSelectClick()} />
                );

            default:
                return (
                    column.templates && column.templates.map((action, index) => {
                        /**
                         * The winners table Action render here,
                         * According to the status its changing 
                        */

                        if (action.type === 'WINNER-ACTION') {
                            let template = '';

                            //High price winners cant be settled, Therefore disable it here. 'Higher Prize' -> 'High Prize'
                            if (data.price === 'High Prize' || data.prizeType !== 'CASH') {
                                disabled = true;
                            }

                            // eslint-disable-next-line default-case
                            switch (data.status) {
                                case 'Settled': template = '<a href="/winners/:id" style="font-size:13px" class="btn btn-squared-default btn-primary sqr-btn-action">View</a>'
                                    break;
                                case 'Unsettled': template = disabled ? `<a href="/winners/:id" style="font-size:13px" class="btn btn-squared-default btn-primary sqr-btn-action">View</a>` :
                                    `<a href="/winners/:id" style="font-size:13px" class="btn btn-squared-default btn-primary sqr-btn-action" >View</a>`;
                                    break;
                            }
                            return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'ACTION', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)

                        }
                        else if (action.type === 'PURCHASE-ORDER') {
                            if (userService.checkUserFullPermission('PurchaseOrder', 'view')) {
                                let template = '<a href="/draws/:lotteryId/:id" class="btn btn-info btn-sm"><i class="fas fa-history"></i></a>';
                                return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'PURCHASE-ORDER', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)
                            }
                        }
                        else if (action.type === 'EDIT-DRAW') {
                            if (userService.checkUserFullPermission('Draw', 'edit')) {
                                if ((data.statusId !== DRAW_STATUS.CLOSE) & (data.statusId !== DRAW_STATUS.CANCEL)) {
                                    let template = '<a href="/draws/:id" class="btn btn-info btn-sm"><i class="fas fa-edit"></i></a>';
                                    return (<span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: 'EDIT-DRAW', data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(template)} />)
                                }else {
                                    let template = '<a href="/draws/:id" class="btn btn-info btn-sm disabled"><i class="fas fa-edit"></i></a>';
                                    return (<span key={index} className="mr-3 action-item" dangerouslySetInnerHTML={this.createActionMarkup(template)} />)
                                }
                            }
                        }
                        else if (action.type === 'ACTION') {
                            return (
                                <span key={index}>
                                    {this.renderActionTemplate(data, index, rowIndex)}
                                </span>
                            );
                        } else {
                            return <span key={index} className="mr-3 action-item" onClick={(e) => this.handleActionClick(e, { type: action.type, data: data, index: rowIndex })} dangerouslySetInnerHTML={this.createActionMarkup(action.template)} />
                        }
                    })
                );
        }
    }

    orderByComparator(a, b) {
        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            // isn't a number so lowercase the string to properly compare
            if (a && b) {
                if (a.toString().toLowerCase() < b.toString().toLowerCase()) return -1;
                if (a.toString().toLowerCase() > b.toString().toLowerCase()) return 1;
            } else {
                if (a && !b) {
                    return 1;
                } else if (!a && b) {
                    return -1;
                } else {
                    return 0;
                }
            }
        } else {
            // parse strings as numbers to compare properly
            if (parseFloat(a) < parseFloat(b)) return -1;
            if (parseFloat(a) > parseFloat(b)) return 1;
        }
        return 0; // equal each other
    }

    sortBy(event, orderBy) {
        event.preventDefault();

        let { gridConfig, sortOrder, sortBy, selectedPage } = this.state;

        if (gridConfig.gridData.length) {
            if (sortOrder === 'asc') {
                gridConfig.gridData.sort((item1, item2) => {
                    return this.orderByComparator(item1[orderBy], item2[orderBy]);
                });
            } else {
                gridConfig.gridData.sort((item1, item2) => {
                    return this.orderByComparator(item2[orderBy], item1[orderBy]);
                });
            }

            sortBy = orderBy;
            sortOrder = sortOrder === 'asc' ? 'dsc' : 'asc';
            this.setState({ gridConfig, sortOrder, sortBy });
            this.handlePaginate(selectedPage);
        }
    }

    renderTableHeader = () => {
        const { gridConfig, sortOrder, sortBy } = this.state;
        return (
            gridConfig.columns && gridConfig.columns.map((column, index) => {
                if (column.headerTemplate) {
                    return <th key={index} className="border-0 text-muted" width={column.width}>
                        {/* <a href="#sort" onClick={(e) => { this.sortBy(e, column.name) }} className="text-decoration-none text-muted">{column.headerTitle}</a> */}
                        <input id='selectAll' className='selectAllCheck' defaultChecked={this.state.checkedAll} type="checkbox" onChange={() => this.handleSelectAllClick()} />
                    </th>
                }
                if (!column.hidden) {
                    return <th key={index} className="border-0 text-muted" width={column.width}>
                        <a href="#sort" onClick={(e) => { this.sortBy(e, column.name) }} className="text-decoration-none text-muted">{column.headerTitle}</a>
                        {column.canSort ?
                            <span className="pl-1 position-relative">
                                <i className={'fas fa-caret-up position-absolute ' + ((sortOrder === 'asc' && column.name === sortBy) ? 'text-primary' : '')} style={{ top: '-2px' }}></i>
                                <i className={'fas fa-caret-down position-absolute ' + ((sortOrder === 'dsc' && column.name === sortBy) ? 'text-primary' : '')} style={{ bottom: '-3px' }}></i>
                            </span> : ''
                        }
                    </th>
                } else {
                    return <th className="d-none" key={index}></th>;
                }
            })
        );
    }

    handleActionClick = (event, params) => {
        const { selectedPage } = this.state;
        event.preventDefault();
        // special handling redirects through grid actions
        if (event.target.tagName === 'A') {
            params['redirectTo'] = event.target.getAttribute('href');
        }

        params['selectedPage'] = selectedPage;

        this.props.actionHandler(params);
    }

    componentWillReceiveProps = () => {
        // REFACTORME: there is a perfomance impact. Not the best solution
        const { selectedPage } = this.state;
        this.handlePaginate(selectedPage);
    }

    renderTableContent = () => {
        const { gridConfig, paginatedData } = this.state;
        let count = 0;
        if (gridConfig.meta && gridConfig.meta.showLoadingSpinner) {
            return (<tr className="text-center"><td colSpan={gridConfig.columns.length}><Spinner color="primary" /></td></tr>);
        } else if (paginatedData.length > 0) {
            return (
                paginatedData && paginatedData.map((data, rowIndex) => {
                    if (!data.isArchived) {
                        return <tr key={rowIndex}>
                            {gridConfig.columns.map((column, colIndex) => {
                                if (column.name == 'price' && typeof (data.price) == 'number') {
                                    data.price = currencyConvertorNoSymbol(data.price);
                                }
                                if (!column.hidden) {

                                    //Lottery field have json object as value handle here
                                    let lotteryJSON = []
                                    if (data.formatTypeName === 'JSON' && column.name === 'fieldValue') {
                                        lotteryJSON = JSON.parse(data[column.name]);
                                    }

                                    return <td className="align-middle" key={colIndex}>{column.useTemplate ? this.renderTemplates(column, data, rowIndex) :
                                        //The condition is added to show special field with asterisk mark in the lottery field
                                        (data.isSpecialField && column.name === 'fieldName') ? data[column.name] + ' *' :
                                            (data.formatTypeName === 'JSON' && column.name === 'fieldValue') ?
                                                Object.keys(lotteryJSON)[0]
                                                : data[column.name]}</td>
                                } else {
                                    return <td className="d-none" key={colIndex}></td>
                                }
                            })}
                        </tr>
                    }

                    else if (data.isArchived) {
                        ++count;
                        if (count === paginatedData.length) {
                            return (<tr className="text-center border-0"><td colSpan={gridConfig.columns.length}>No Data</td></tr>);
                        }
                    }

                })
            );

        } else {
            return (<tr className="text-center border-0"><td colSpan={gridConfig.columns.length}>No Data</td></tr>);
        }
    }

    uncheckAll() {
        const x = document.getElementsByClassName("selected");
        for (var i = 0; i < x.length; i++) {
            if (x[i].type === 'checkbox')
                x[i].checked = false;
        }
        document.getElementsByClassName("selectAllCheck")[0].checked = false;

        this.setState({
            checked: false,
            checkedAll: false,
        });
    }

    handlePaginate = (selectedPage) => {
        const { gridConfig } = this.state;
        if (!(this.state.selectedPage === selectedPage) && this.props.activeMultiSelect) {
            this.uncheckAll();
        }
        const pageSize = gridConfig.meta.pageSize || DEFAULT_PAGE_SIZE;
        const upperLimit = selectedPage * pageSize;
        const paginatedData = gridConfig.gridData.slice((upperLimit - pageSize), upperLimit);
        this.setState({ paginatedData, selectedPage });
    }

    renderPaginate = () => {
        const { gridConfig } = this.state;

        if ((gridConfig.canPaginate === undefined || gridConfig.canPaginate) && gridConfig.gridData.length > gridConfig.meta.pageSize) {
            return (
                <div className="float-right">
                    <Paginate totalItems={gridConfig.gridData.length} pageSize={gridConfig.meta.pageSize || DEFAULT_PAGE_SIZE} onSelect={this.handlePaginate} />
                </div>
            )
        }
    }

    render() {
        return (
            <div className="custom-grid">
                <Table className="my-3 rounded">

                    <thead className="border-0">
                        <tr>{this.renderTableHeader()}</tr>
                    </thead>

                    <tbody className="bg-white border">
                        {this.renderTableContent()}
                    </tbody>
                </Table>
                {this.renderPaginate()}
            </div>
        );
    }
}

CustomGrid.propTypes = {
    gridConfig: PropTypes.object
}

export default CustomGrid;
