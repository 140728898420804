/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Input, CustomInput, FormGroup, Label } from 'reactstrap';
import CustomDropdown from './../common/custom-controls/CustomDropdown';
import { prizeDistributionFilter, salesChannel, demandTypes, status, timeFilter } from '../../utils/meta-data';
import { reportService } from '../../services/report-service';

class PrizeDistribution extends Component {
    INITIAL_STATE = {
        displayFilter: false,
        filter: [],
        groupBy: [],
        filterSelect: false,
        hiddenRow: '',
        operatorsList: '',
        lotteriesList: '',
        prizeTransferMethodList: '',
        salseInputField: {
        },
        dropDownInput: [],
        dropDownSelect: [],
        dropDownGroup: [],
        showGroupBy: true

    }
    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE
        }
    }

    componentDidMount = () => {
        let { operatorsList, lotteriesList, prizeTransferMethodList } = this.state;
        reportService.getOperatorsList().then((results) => {
            if (results.statusCode === 200) {
                operatorsList = results.data
                this.setState({
                    operatorsList: operatorsList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        reportService.getLotteryList().then((results) => {
            if (results.statusCode === 200) {
                lotteriesList = results.data
                this.setState({
                    lotteriesList: lotteriesList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        reportService.prizeTransferMethodList().then((results) => {
            if (results.statusCode === 200) {
                prizeTransferMethodList = results.data

                this.setState({
                    prizeTransferMethodList: prizeTransferMethodList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

    }


    // The sub-select function render here. Recreating the filter array and put it there. 
    // added 1000 to differentiate filter and group select 

    activeFilterSele(selected, type) {
        const { salseInputField, prizeTransferMethodList } = this.state;
        const id = type - 1000;
        var subSelect = '';
        var array = [...this.state.filter];

        let dropDownSelect = { ...this.state.dropDownSelect };
        dropDownSelect[id] = {
            id: selected.id,
            name: selected.name
        }

        switch (selected.id) {
            case 1: subSelect = (
                <Input type="date" name="dateRange" id="dateRange" value={salseInputField.dateRange} onChange={this.handleValueChange} />
            )
                break;
            case 2: subSelect = (
                <CustomDropdown type={'lotteriesList'} placeholder={{ name: 'Select an Option' }} options={this.state.lotteriesList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 3: subSelect = (
                <Input type="text" name="drawName" id="drawName" value={salseInputField.drawName} onChange={this.handleValueChange} />
            )
                break;
            case 4: subSelect = (
                <CustomDropdown type={'operatorsList'} placeholder={{ name: 'Select an Option' }} options={this.state.operatorsList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 5: subSelect = (
                <Input type="text" name="mobileNumber" id="mobileNumber" value={salseInputField.mobileNumber} onChange={this.handleValueChange} />
            )
                break;
            case 6: subSelect = (
                <CustomDropdown type={'salesChannel'} placeholder={{ name: 'Select an Option' }} options={salesChannel} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 7: subSelect = (
                <CustomDropdown type={'demandTypes'} placeholder={{ name: 'Select an Option' }} options={demandTypes} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 8: subSelect = (
                <Input type="number" name="winningAmount" id="winningAmount" value={salseInputField.winningAmount} onChange={this.handleValueChange} />
            )
                break;
            case 9: subSelect = (
                <CustomDropdown type={'prizeTransferMethodList'} placeholder={{ name: 'Select an Option' }} options={prizeTransferMethodList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 10: subSelect = (
                <CustomDropdown type={'status'} placeholder={{ name: 'Select an Option' }} options={status} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;

            default:
                break;
        }

        array[id] = (
            <Row style={{ paddingTop: '10px' }} key={type}>
                <Col md={4}>
                    <CustomDropdown type={type} placeholder={{ name: ' By' }} options={prizeDistributionFilter} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                </Col>
                <Col md={4} >
                    {subSelect}
                </Col>
                <Col md={3}></Col>
                <Col md={1} className='text-right'>
                    <button type="button" className="close" aria-label="Close" onClick={(e) => this.handleCloseFilter(id)} style={{ color: 'red' }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Col>
            </Row>
        )
        this.setState({ filter: array, dropDownSelect });
    }

    activeGroupSele(selected, type) {
        const { salseInputField, prizeTransferMethodList } = this.state;
        const id = type;
        var subSelect = '';
        var array = [...this.state.groupBy];

        let dropDownGroup = { ...this.state.dropDownGroup };
        dropDownGroup[id] = {
            id: selected.id,
            name: selected.name,
            isActive: false

        }

        switch (selected.id) {
            case 1: subSelect = (
                <CustomDropdown type={'timeFilterList'} placeholder={{ name: 'Select an Option' }} options={timeFilter} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 2: subSelect = (
                <CustomDropdown type={'lotteriesListGroup'} placeholder={{ name: 'Select an Option' }} options={this.state.lotteriesList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 3: subSelect = (
                <Input type="text" name="drawNameGroup" id="drawNameGroup" value={salseInputField.drawNameGroup} onChange={this.handleValueChange} />
            )
                break;
            case 4: subSelect = (
                <CustomDropdown type={'operatorsListGroup'} placeholder={{ name: 'Select an Option' }} options={this.state.operatorsList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 5: subSelect = (
                <Input type="text" name="mobileNumberGroup" id="mobileNumberGroup" value={salseInputField.mobileNumberGroup} onChange={this.handleValueChange} />
            )
                break;
            case 6: subSelect = (
                <CustomDropdown type={'salesChannelGroup'} placeholder={{ name: 'Select an Option' }} options={salesChannel} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 7: subSelect = (
                <CustomDropdown type={'demandTypesGroup'} placeholder={{ name: 'Select an Option' }} options={demandTypes} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 8: subSelect = (
                <Input type="number" name="winningAmountGroup" id="winningAmountGroup" value={salseInputField.winningAmountGroup} onChange={this.handleValueChange} />
            )
                break;
            case 9: subSelect = (
                <CustomDropdown type={'prizeTransferMethodListGroup'} placeholder={{ name: 'Select an Option' }} options={prizeTransferMethodList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            case 10: subSelect = (
                <CustomDropdown type={'statusGroup'} placeholder={{ name: 'Select an Option' }} options={status} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
            )
                break;
            default:
                break;
        }

        array[id] = (
            <Row style={{ paddingTop: '10px' }} id={id} key={id}>
                <Col md={4}>
                    <CustomDropdown type={id} placeholder={{ name: 'Group By' }} options={prizeDistributionFilter} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                </Col>
                <Col md={4}>
                    {subSelect}
                </Col>
                <Col md={1.5} style={{ marginTop: '8px' }}>As a Row</Col>
                <Col md={.3} style={{ marginTop: '8px', marginLeft: '4px' }}>
                    <CustomInput type="switch" id={'isActive-' + id} name={'isActive-' + id} onChange={(e) => this.handleActivation(id)} />
                </Col>
                <Col md={2} style={{ marginTop: '8px' }}>As a Column</Col>

                <Col md={.2} className='text-right' style={{ marginTop: '8px' }}>
                    <button type="button" className="close" aria-label="Close" style={{ color: 'red' }} onClick={(e) => this.handleCloseGroup(id)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Col>
            </Row>
        )
        this.setState({ groupBy: array, dropDownGroup });
    }

    handleCloseFilter(id) {
        let dropDownSelect = { ...this.state.dropDownSelect };
        dropDownSelect[id] = null;
        var array = [...this.state.filter];
        if (id > -1) {
            array[id] = null;
            this.setState({ filter: array, dropDownSelect });
        }
    }

    handleCloseGroup(id) {
        let dropDownGroup = { ...this.state.dropDownGroup };
        dropDownGroup[id] = null;
        var array = [...this.state.groupBy];
        if (id > -1) {
            array[id] = null;
            this.setState({ groupBy: array, dropDownGroup });
        }
    }

    handleValueChange = (e) => {
        let salseInputField = { ...this.state.salseInputField };
        salseInputField[e.target.name] = e.target.value;
        this.setState({
            salseInputField
        });
    }

    handleActivation = (id) => {
        let dropDownGroup = { ...this.state.dropDownGroup };
        dropDownGroup[id].isActive = !dropDownGroup[id].isActive;
        this.setState({
            dropDownGroup
        })
    }

    handleOnDropdownSelected = (selected, type) => {
        let dropDownInput = { ...this.state.dropDownInput };

        switch (type) {
            case 'reportType':
                if (selected.id === 2) {
                    dropDownInput.reportType = 'summary';
                    this.setState({
                        showGroupBy: false
                    })
                } else {
                    dropDownInput.reportType = 'detailed';
                    this.setState({
                        showGroupBy: true
                    })
                }
                break;

            case 'lotteriesList': dropDownInput.lotteriesList = selected.id;
                break;

            case 'operatorsList': dropDownInput.operatorsList = selected.id;
                break;

            case 'salesChannel': dropDownInput.salesChannel = selected.id;
                break;

            case 'demandTypes': dropDownInput.demandTypes = selected.id;
                break;

            case 'prizeTransferMethodList': dropDownInput.prizeTransferMethodList = selected.id;
                break;

            case 'status': dropDownInput.status = selected.id;
                break;

            case 'timeFilterList': dropDownInput.timeFilterList = selected.id;
                break;

            case 'lotteriesListGroup': dropDownInput.lotteriesListGroup = selected.id;
                break;

            case 'operatorsListGroup': dropDownInput.operatorsListGroup = selected.id;
                break;

            case 'salesChannelGroup': dropDownInput.salesChannelGroup = selected.id;
                break;

            case 'demandTypesGroup': dropDownInput.demandTypesGroup = selected.id;
                break;

            case 'prizeTransferMethodListGroup': dropDownInput.prizeTransferMethodListGroup = selected.id;
                break;

            case 'statusGroup': dropDownInput.statusGroup = selected.id;
                break;

            default:
                if (type >= 1000) {
                    this.activeFilterSele(selected, type)
                } if (type < 1000 && type >= 0) {
                    this.activeGroupSele(selected, type)
                }
                break;
        }
        this.setState({
            dropDownInput
        });
    }

    handleAddFilter = () => {
        const id = this.state.filter.length;
        const selectDraw = id + 1000;

        var filterModal = (
            <Row style={{ paddingTop: '10px' }} key={selectDraw} >
                <Col md={4}>
                    <CustomDropdown type={selectDraw} placeholder={{ name: 'Filter By' }} options={prizeDistributionFilter} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                </Col>
                <Col md={4} >

                </Col>
                <Col md={3}></Col>
                <Col md={1} className='text-right'>
                    <button type="button" className="close" aria-label="Close" onClick={(e) => this.handleCloseFilter(id)} style={{ color: 'red' }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Col>
            </Row>
        )

        this.setState({
            filter: [
                ...this.state.filter,
                filterModal
            ]
        });
    }

    renderGroupBy = () => {
        const id = this.state.groupBy.length;
        const selectDraw = id;
        this.setState({
            groupBy: [
                ...this.state.groupBy,
                <Row style={{ paddingTop: '10px' }} id={id} key={id}>
                    <Col md={4}>
                        <CustomDropdown type={selectDraw} placeholder={{ name: 'Group By' }} options={prizeDistributionFilter} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                    </Col>
                    <Col md={4}>
                    </Col>
                    <Col md={1.5} style={{ marginTop: '8px' }}></Col>
                    <Col md={.3} style={{ marginTop: '8px', marginLeft: '4px' }}>
                        {/* <CustomInput type="switch" id={'isActive-' + id} name={'isActive-' + id} /> */}
                    </Col>
                    <Col md={2} style={{ marginTop: '8px' }}></Col>

                    <Col md={.2} className='text-right' style={{ marginTop: '8px' }}>
                        <button type="button" className="close" aria-label="Close" style={{ color: 'red' }} onClick={(e) => this.handleCloseGroup(id)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Col>
                </Row>
            ]
        });

    }

    redirectToSummary = () => {
        const { dropDownInput, salseInputField, dropDownSelect, dropDownGroup } = this.state;
        console.log(dropDownInput)
        console.log(salseInputField, dropDownSelect, dropDownGroup)
        // this.props.history.push('/reports/sales/summary');
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const salesRepType = [
            {
                id: 1,
                name: 'Detailed Report'
            },
            {
                id: 2,
                name: 'Summary Report'
            }
        ]

        return (
            <Container fluid>
                {/* <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast> */}
                <div>
                    <div>
                        <Row>
                            <Col md={8}>
                                <label><h4>Generate Reports</h4></label>
                            </Col>
                        </Row>
                    </div>

                    <div id={'hari'}>

                    </div>

                    <div className="card">
                        <div className='card-body'>
                            <Row className="pt-3">
                                <Col md={5}>
                                    <FormGroup className="required">
                                        <Label for="fieldFormat">Report Type</Label>
                                        <CustomDropdown type={'reportType'} placeholder={{ name: 'Select Report Type' }} options={salesRepType} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />

                            <Row className="pt-3">
                                <Col md={2}>
                                    <h6>Filter by</h6>
                                </Col>
                                <Col md={10}>
                                    <hr />
                                </Col>
                            </Row>

                            <Row className="pt-3">
                                <Col md={10}>
                                    {this.state.filter.map(child => child)}
                                </Col>

                                <Col md={2} className='text-right'>
                                    <Button color="success" className="btn-rounded" size="sm" onClick={this.handleAddFilter}><i className="fas fa-plus"></i> Add a Filter </Button>
                                </Col>

                            </Row>

                            <Row className="pt-3" hidden={this.state.showGroupBy}>
                                <Col md={2}>
                                    <h6>Group by</h6>
                                </Col>
                                <Col md={10}>
                                    <hr />
                                </Col>
                            </Row>

                            <Row className="pt-3" hidden={this.state.showGroupBy}>
                                <Col md={10}>
                                    {this.state.groupBy.map(child => child)}
                                </Col>

                                <Col md={2} className='text-right' >
                                    <Button color="success" className="btn-rounded" size="sm" onClick={this.renderGroupBy}><i className="fas fa-plus"></i> Add a Group </Button>
                                </Col>

                            </Row>

                            <Row className="pt-3">
                                <Col md={2}>
                                    <h6>Display Columns</h6>
                                </Col>
                                <Col md={10}>
                                    <hr />
                                </Col>
                            </Row>

                            <Row className="pt-3" hidden>
                                <Col md={12}>
                                    <Row >
                                        <Col md={3} style={{ paddingTop: '10px' }}>
                                            <CustomInput type="switch" id="r" name="r" label="Activate User" />
                                        </Col>
                                        <Col md={3} style={{ paddingTop: '10px' }}>
                                            <CustomInput type="switch" id="r" name="r" label="Activate User" />
                                        </Col>
                                        <Col md={3} style={{ paddingTop: '10px' }}>
                                            <CustomInput type="switch" id="r" name="r" label="Activate User" />
                                        </Col>
                                        <Col md={3} style={{ paddingTop: '10px' }}>
                                            <CustomInput type="switch" id="r" name="r" label="Activate User" />
                                        </Col>
                                        <Col md={3} style={{ paddingTop: '10px' }}>
                                            <CustomInput type="switch" id="r" name="r" label="Activate User" />
                                        </Col>
                                        <Col md={3} style={{ paddingTop: '10px' }}>
                                            <CustomInput type="switch" id="r" name="r" label="Activate User" />
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row className="justify-content-end">
                                <Col md={4} className='text-right'>
                                    <Button color="primary" className="btn-rounded ml-3" outline > Save as a Template</Button>
                                    <Button color="primary" className="btn-rounded ml-3" onClick={() => this.redirectToSummary()} > Gen. Report </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </div>
            </Container>);
    }
}

export default PrizeDistribution;
