/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import SubmitButton from '../common/custom-controls/SubmitButton';
import { mobileValidation, smsRequestValidation } from '../../utils/validations';
import SimpleReactValidator from 'simple-react-validator';
import { subscriberService } from '../../services/subscriber-service';
import { STRING } from '../../store/constants/common';
class SingleUpload extends Component {
    validator = null;

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                telephone: {
                    message: STRING.MESSAGE.INVALID_MOBILE_NO,
                    rule: (val) => {
                        return mobileValidation(val);
                    },
                    required: true
                },
                smsRequest: {
                    message: STRING.MESSAGE.INVALID_SMS_REQUEST,
                    rule: (val) => {
                        return smsRequestValidation(val);
                    },
                    required: true
                }
            }
        });
        this.state = {
            submitted: false,
            toast: {
                type: '',
                message: ''
            },
            inputData: {
                mobileNo: '',
                message: ''
            }
        };
    }

    handleSubmit = () => {
        const { inputData } = this.state;

        if (this.validator.allValid()) {
            this.setState({ submitted: true });
            subscriberService.singleUpload(inputData).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        submitted: false,
                        toast: {
                            type: STRING.TOAST_TYPE.SUCCESS,
                            message: results.data.description
                        }
                    });
                } else {
                    this.setState({
                        submitted: false,
                        toast: {
                            type: STRING.TOAST_TYPE.WARNING,
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    submitted: false,
                    toast: {
                        type: STRING.TOAST_TYPE.WARNING,
                        message: error.message
                    }
                })
            });
        } else {
            this.validator.showMessages();
        }
    }

    handleFormValueChange = (e) => {
        let inputData = { ...this.state.inputData };
        inputData[e.target.name] = e.target.value;
        this.setState({
            inputData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { submitted, inputData, toast } = this.state;

        return (
            <React.Fragment>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type}
                    message={toast.message}></Toast>
                <Col md={12} className='singleUpload'>
                    <Row>
                        <Col md={4}>
                            <FormGroup className="required">
                                <Label for="mobileNo">Phone Number</Label>
                                <Input type="text" name="mobileNo" id="mobileNo" value={inputData.mobileNo}
                                    onChange={this.handleFormValueChange} placeholder="Phone Number" />
                                <span className="text-danger"><small>{this.validator.message('Phone number', inputData.mobileNo, 'required|telephone')}</small></span>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="required">
                                <Label for="message">Message</Label>
                                <Input type="text" name="message" id="message" value={inputData.message}
                                    onChange={this.handleFormValueChange} placeholder="SMS Action Template" />
                                <span className="text-danger"><small>{this.validator.message('Message', inputData.message, 'required|smsRequest')}</small></span>
                            </FormGroup>
                        </Col>
                        <Col md={4} >
                            <br />
                            <SubmitButton shouldDisable={submitted} submitted={submitted} buttonText='Process'
                                onSubmit={this.handleSubmit}></SubmitButton>
                        </Col>
                    </Row>
                </Col>
            </React.Fragment>
        );
    }

}

export default SingleUpload;