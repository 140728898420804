/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, CustomInput, ModalFooter, Table } from 'reactstrap';
import { subscriberService } from '../../services/subscriber-service';
import Toast from '../common/custom-controls/Toast';
import './../custom.css'
import { green } from '@material-ui/core/colors';
import { currencyConvertor, currencyConvertorNoSymbol } from '../../utils/currency-convertor';

class WinnersView extends Component {
    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        },
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.INITIAL_STATE,
            id: props.drawId,
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.winnersData
        }
        this.toggle = this.toggle.bind(this);
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    render() {
        const { toast, data } = this.state;

        return (
            <Modal size="lg" isOpen={this.state.showModal} className={'drawView-modal'} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <ModalHeader>
                    Winners Details
                </ModalHeader>
                <ModalBody className={'drawView-body'}>
                    <Row>
                        <Col>

                            <Row>
                                <Col md={3}>Draw Date</Col><Col md={9}>{data.drawDate}</Col>
                            </Row>
                            <hr />

                            <Row>
                                <Col md={3}>Mobile</Col><Col md={9}>{data.mobile}</Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={3}>Customer NIC</Col><Col md={9}>{data.cusNIC}</Col>
                            </Row>
                            <hr />


                            <Row>
                                <Col md={3}>Winning Chance</Col><Col md={9}>{data.drawType} </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={3}>Prize Type</Col><Col md={9}>{data.prizeType} </Col>
                            </Row>
                            <hr />

                            <Row>
                                <Col md={3}>Prize Description</Col><Col md={9}>{data.prizeDescription ? data.prizeDescription : '-'} </Col>
                            </Row>
                            <hr />

                            <Row>
                                <Col md={3}>Prize</Col><Col md={9}>{data.price === 'High Prize' ? '-' : data.price} </Col>
                            </Row>
                            <hr />

                            <Row>
                                <Col md={3}>Serial Number</Col><Col md={9}>{data.serialNumber}</Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={3}>Lottery Name</Col><Col md={9}>{data.lotteryName}</Col>
                            </Row>
                            <hr />

                        </Col>

                    </Row>

                    {data.status === 'Settled' ?
                        <Row>
                            <Col md={8}>
                                <br />
                                <h5>Settlement Details</h5>
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Method</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {data.settlements.map(settle => {
                                            return (
                                                //Need to add settlement amount insted of settlementMehtod
                                                <tr>
                                                    <td>{settle.settlementDate}</td>
                                                    <td>{currencyConvertorNoSymbol(settle.amount)}</td>
                                                    <td>{settle.settlementMethodName}</td>
                                                </tr>
                                            )
                                        })} */}
                                        <tr>
                                            <td>{data.settlemetDate}</td>
                                            <td>{data.price}</td>
                                            <td>{data.settlementMethodName}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </Col>

                        </Row> : ''
                    }


                    <Row className='text-right'>
                        <Col>
                            <Button outline color="secondary" onClick={this.handleClose}>Cancel</Button>
                        </Col>

                    </Row>

                </ModalBody>

            </Modal >
        )
    }
}

export default WinnersView;
