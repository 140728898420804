/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get, post, put } from '../utils/http';

const mapPermissions = (permissions) => {
    let userPermissions = [];

    permissions.forEach((permission) => {
        for (let prop in permission) {
            // eslint-disable-next-line default-case
            switch (prop) {
                case 'add':
                    if (permission.add && permission.add !== 'disabled') {
                        userPermissions.push(`Add_${permission.name.replace(' ', '')}`);
                    }
                    break;
                case 'edit':
                    if (permission.edit && permission.edit !== 'disabled') {
                        userPermissions.push(`Edit_${permission.name.replace(' ', '')}`);
                    }
                    break;
                case 'delete':
                    if (permission.delete && permission.delete !== 'disabled') {
                        userPermissions.push(`Delete_${permission.name.replace(' ', '')}`);
                    }
                    break;
                case 'view':
                    if (permission.view && permission.view !== 'disabled') {
                        userPermissions.push(`View_${permission.name.replace(' ', '')}`);
                    }
                    break;
            }
        }

    });

    return [...new Set(userPermissions)];
}

const mapUserData = (userData) => {
    return userData.map((element) => {
        const user = {
            id: element.id,
            email: element.email,
            firstName: element.firstName,
            lastName: element.lastName,
            fullName: `${element.firstName} ${element.lastName}`,
            isActive: element.isActive
        }
        return user;
    })
}

const getAll = () => {
    return new Promise((resolve, reject) => {
        get(`users`).then((results) => {
            results.data = mapUserData(results.data);
            resolve(results)
        }).catch((error) => {
            reject(error);
        })
    });
}

const getUser = (id) => {
    return get(`users/${id}`);
}

const save = (user) => {
    user.permissions = mapPermissions(user.permissions);
    if (user.id) {
        return put(`users/${user.id}`, user)
    } else {
        return post(`users`, user)
    }
}

const getUserPermission = () => {
    return get(`users/permissions`);
}

const checkUserPermission = (permissionName, permissionType) => {
    if (localStorage.getItem('permissions')) {
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        // console.log(permissions)
        let isPermission = false
        permissions.forEach(element => {
            if (element.name === permissionName) {
                isPermission = true
            }
        });
        return isPermission;
    } else {
        return false;

    }
}

const checkUserFullPermission = (permissionName, permissionType) => {
    if (localStorage.getItem('permissions')) {
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        // console.log(permissions)
        let isPermission = false
        permissions.forEach(element => {
            if (element.name === permissionName) {
                isPermission = element[permissionType];
            }
        });
        return isPermission;
    } else {
        return false;

    }
}

export const userService = {
    getAll,
    getUser,
    save,
    getUserPermission,
    checkUserPermission,
    checkUserFullPermission
};
