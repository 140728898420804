/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { signIn, signOut, checkUser } from './../../services/auth-service';
import { AUTHENTICATE, AUTHENTICATE_ERROR, UNAUTHENTICATE, CHECK_AUTHENTICATED_USER } from './../constants/action-types';

export const signInAction = (username, password) => {
    return (dispatch) => {

        return new Promise(async (resolve, reject) => {

            try {
                const response = await signIn(username, password);
                // console.log(response)
                if (response.statusCode === 200) {
                    dispatch({
                        type: AUTHENTICATE,
                        payload: {
                            isAuthenticated: true,
                            user: {
                                username: response.data.username,
                                firstName: response.data.firstName,
                                lastName: response.data.lastName
                            }
                        }
                    });

                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                    return resolve(username);
                } else {
                    let error = {
                        message: response.data.description
                    }
                    dispatch({
                        type: AUTHENTICATE_ERROR,
                        payload: {
                            isAuthenticated: false,
                            error: response.data.description
                        }
                    });
                    return reject(error);
                }


            } catch (error) {
                dispatch({
                    type: AUTHENTICATE_ERROR,
                    payload: {
                        isAuthenticated: false,
                        error: 'Invalid username or password'
                    }
                });
                return reject(error);
            }

        });
    }
}

export const signOutAction = (history) => {
    return async (dispatch) => {

        try {
            await signOut();
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            dispatch({
                type: UNAUTHENTICATE,
                payload: {
                    isAuthenticated: false,
                    user: {}
                }
            });
            history.push('/login');
        } catch (error) {
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            dispatch({
                type: UNAUTHENTICATE,
                payload: {
                    isAuthenticated: false,
                    user: {}
                }
            });
            history.push('/login');
        }

    }
}

export const checkUserAction = () => {
    return async (dispatch) => {

        try {
            if (!localStorage.getItem('token')) {
                dispatch({
                    type: AUTHENTICATE_ERROR,
                    payload: {
                        isAuthenticated: false,
                        error: 'Token not found'
                    }
                });
                return;
            }

            // const response = await checkUser();
            dispatch({
                type: CHECK_AUTHENTICATED_USER,
                payload: {
                    isAuthenticated: true,
                    hari: 'Got it'

                }
            });

        } catch (error) {
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            dispatch({
                type: AUTHENTICATE_ERROR,
                payload: {
                    isAuthenticated: false,
                    error: 'Session time out'
                }
            });
        }

    }
}


