/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, Container, Row, Col, Div, Button, Input, Label } from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone'
import Toast from '../common/custom-controls/Toast';
import { BASE_API } from '../../utils/configs';
import { subscriberService } from '../../services/subscriber-service';
import SubmitButton from '../common/custom-controls/SubmitButton';
import '../custom.css';
import { errorCode } from './../../utils/error-code';

class FileUpload extends Component {

    INITIAL_STATE = {
        showModal: false,
        submitted: false,
        toast: {
            type: '',
            message: ''
        },
        files: [],
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE,
            showModal: props.showModal,
            onClose: props.onClose,
            toast: {
                type: '',
                message: ''
            },
        };

        this.onDrop = (files) => {
            if (files.length === 1) {
                let acceptedFile = files[0];
                let fileExtention = acceptedFile.name.split('.').pop();
                let fileType = acceptedFile.type;
                if ((fileExtention === "CSV" || fileExtention === "csv") && (fileType === "text/csv" || fileType === "application/vnd.ms-excel" || fileType === "")) {
                    this.setState({ files })
                } else {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: 'Please upload CSV files only'
                        }
                    })
                }
            } else {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: 'Please Select only one file'
                    }
                })
            }
        };
    }

    handleSubmit = () => {
        const { files } = this.state;
        this.setState({ submitted: true });

        if (files.length) {
            const fileData = new FormData();
            let acceptedFile = files[0];
            fileData.append('file', acceptedFile);

            subscriberService.uploadInvalidFile(fileData).then((results) => {
                if (results.status === 200) {
                    this.setState({
                        ...this.INITIAL_STATE,
                        toast: {
                            type: 'success',
                            message: 'File has been uploaded'
                        }
                    });
                } else {
                    this.setState({
                        ...this.INITIAL_STATE,
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    });
                }
            }).catch((error) => {
                //This request sending through Axios, The response is different than others
                error = JSON.parse(JSON.stringify(error));
                if (error.length) {
                    let code = error.message.substring(error.message.length - 3)
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: errorCode[code]
                        }
                    });
                }
            })
        } else {
            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: 'Please Select a file'
                }
            });
        }
    }

    handleCancel = () => {
        this.setState({
            ...this.INITIAL_STATE,
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast, submitted, files } = this.state;

        return (
            <React.Fragment>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <section className="container">
                            <Row className='justify-content-center mt-5'>
                                <Col md={9}>
                                    <div {...getRootProps({ className: 'dropzone' })}
                                        style={{
                                            borderBlock: 'solid',
                                            outline: '4px dashed #0275d8',
                                            padding: '40px',
                                            textAlign: 'center',
                                            color: '#0275d8',
                                            height: '200px',
                                        }}>
                                        <input {...getInputProps()} accept=".csv" />
                                        {(files.length) ? <h4 className='mt-4'>{files[0].name + ' - ' + files[0].size}</h4> :
                                            <div>
                                                <h4>{'Drag & Drop CSV fie to upload'}</h4>
                                                <i className="fa fa-upload" style={{ fontSize: "36px" }}></i>
                                            </div>}
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    )}
                </Dropzone>
                <Row className='text-left drag-modal mt-5 col-12' >
                    <Col md={2} >
                        <Button color="secondary" onClick={this.handleCancel} > Cancel </Button>
                    </Col>
                    <Col md={3}>
                        <SubmitButton shouldDisable={submitted} submitted={submitted} onSubmit={this.handleSubmit} />
                    </Col>
                </Row>
            </React.Fragment>

        );
    }
}

export default FileUpload;