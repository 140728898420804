/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import SearchInput from '../common/custom-controls/SearchInput';
import CustomGrid from '../common/custom-controls/CustomGrid';
import CustomDropdown from './../common/custom-controls/CustomDropdown';
import { reportService } from '../../services/report-service';
import { drawService } from '../../services/draw-service';
import DragAndDrop from './DragAndDrop';
import DrawView from './DrawView'
import RaisePurchaseOrder from './RaisePurchaseOrder';
import ReturnTickets from './ReturnTickets';
import UploadResults from './UploadResults';
import UploadResultsSMSPreview from './UploadResultsSMSPreview';
import UploadWinners from './UploadWinners';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import PurchaseOrderView from './PurchaseOrderView'
import './../custom.css'
import { Ls } from 'dayjs';
import DrawModalView from './DrawModalView';
import { DRAW_STATUS } from '../../utils/meta-data';

class Draws extends Component {

    GRID_CONFIG = {
        columns: [
            {
                id: 1,
                name: 'drawDate',
                headerTitle: 'Draw date',
                canSort: true,
                width: '10%'
            },
            {
                id: 2,
                name: 'ticketsUploaded',
                headerTitle: 'Ticket Upload',
                canSort: true,
                useTemplate: true,
                width: '8%'
            },
            {
                id: 2,
                name: 'resultUploaded',
                headerTitle: 'Result Upload',
                canSort: true,
                useTemplate: true,
                width: '8%'
            },
            {
                id: 2,
                name: 'winnersUploaded',
                headerTitle: 'Winners Upload',
                canSort: true,
                useTemplate: true,
                width: '8%'
            },
            {
                id: 3,
                name: 'drawNumber',
                headerTitle: 'Draw Number',
                canSort: true,
                width: '10%'
            },
            {
                id: 4,
                name: 'lotteryName',
                headerTitle: 'Lottery Name',
                canSort: true,
                width: '20%'
            },
            {
                id: 5,
                name: 'status',
                headerTitle: 'Status',
                canSort: true,
                width: '10%'
            },
            {
                id: 6,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '20%',
                useTemplate: true,
                templates: [
                    {
                        type: 'ACTION',
                        template: ``
                    },
                    {
                        type: 'VIEW',
                        template: `<a href="/subscribers/:id" class="btn btn-info btn-sm"><i class="fas fa-eye"></i></a>`
                    },
                    {
                        type: 'PURCHASE-ORDER',
                        template: ``
                    },
                    {
                        type: 'EDIT-DRAW',
                        template: ``
                    }

                ]
            },

        ],
        gridData: [],
        meta: {
            pageSize: 10,
            showLoadingSpinner: true
        }

    }

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showDragModal: false,
            showRaisePOModal: false,
            showReturnTicketModal: false,
            showUploadResultsModal: false,
            showUploadResultsSMSPreview: false,
            showUploadWinnersModal: false,
            cancelDrawModal: false,
            gridConfig: this.GRID_CONFIG,
            userId: null,
            toast: {
                type: '',
                message: ''
            },
            gridData: [],
            lotteriesList: [],
            statusList: [],
            actionData: [],
            drawInputField: {
                lotteriesList: '',
                statusList: '',
                drawId: ''

            },
            drawData: [],
            drawResultsData: {},
            messagePreview: '',
            actionType: '',
            reloader: false,
            gridReload: false,
            showAddNewDraw: false,
        }
    }

    updateGridState = () => {
        let { gridConfig, gridData, drawInputField } = this.state;
        drawService.getDrawsBySearch(drawInputField).then((results) => {
            if (results.statusCode === 200) {
                gridConfig.gridData = results.data;
                gridConfig.meta.showLoadingSpinner = false;
                gridData = results.data;
                this.setState({ gridConfig, gridData });
            } else {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: results.data.description
                    }
                })
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            this.setState({
                gridConfig,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }
    componentWillMount() {
        // let { lotteriesList } = this.state;
        drawService.getAllDrawStatus().then((results) => {
            if (results.statusCode === 200) {
                var statusList = results.data
                this.setState({
                    statusList: statusList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        reportService.getLotteryList().then((results) => {
            if (results.statusCode === 200) {
                var lotteriesList = results.data
                this.setState({
                    lotteriesList: lotteriesList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }
    componentDidMount = () => {
        this.updateGridState();
    }


    renderGrid = () => {
        const { gridConfig } = this.state;

        if (gridConfig.gridData) {
            return (
                <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick}
                    selectHandler={this.handleResponce} toggleModal={'collapse'}></CustomGrid>
            )
        }
    }

    handleActionClick = (params) => {
        let drawId;
        let drawData;
        switch (params.type) {
            case 'ACTION':
                this.actionDropdownClick(params);

                break;
            case 'VIEW':
                drawId = params.data.id;
                drawData = params.data;
                this.setState({
                    showDrawViewModal: true,
                    drawId,
                    drawData,
                    toast: {
                        type: '',
                        message: ''
                    }
                })
                break;
            case 'PURCHASE-ORDER':
                drawId = params.data.id;
                drawData = params.data;
                this.setState({
                    showPurchaseOrderViewModal: true,
                    drawId,
                    drawData,
                    toast: {
                        type: '',
                        message: ''
                    }
                })
                break;
            case 'EDIT-DRAW':
                drawId = params.data.id;
                drawData = params.data;
                this.setState({
                    showAddNewDraw: true,
                    drawId,
                    drawData,
                    toast: {
                        type: '',
                        message: ''
                    }
                })
                break;
            default:
                break;
        }
    }

    handleValueChange = (e) => {
        let drawInputField = { ...this.state.drawInputField };
        drawInputField[e.target.name] = e.target.value;
        this.setState({
            drawInputField
        });

    }


    handleOnDropdownSelected = (selected, type) => {
        let drawInputField = { ...this.state.drawInputField };

        switch (type) {
            case 'lotteriesList':
                drawInputField.lotteriesList = selected.id;
                break;
            case 'statusList':
                drawInputField.statusList = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            drawInputField,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleSearch = () => {
        let drawInputField = { ...this.state.drawInputField };

        if (drawInputField.lotteriesList || drawInputField.statusList || drawInputField.drawId) {
            this.setState({ gridReload: true }, () => {
                this.gridLoader();
                this.updateGridState();
            })


        } else {
            this.setState({
                drawInputField,
                toast: {
                    type: 'warning',
                    message: 'Enter data to search'
                }
            });
        }

    }

    handleSubmitClose = (user, data = {}) => {

        if (user.message) {
            this.setState({
                toast: {
                    type: 'success',
                    message: user.message
                }
            });

            this.updateGridState();
        }

        if (user === 'success') {
            this.setState({
                toast: {
                    type: 'success',
                    message: 'Data has been saved.'
                }
            });

            this.updateGridState();
        }

        if (data.showPreview) {
            this.setState({
                messagePreview: data.sms,
                drawResultsData: data.resultsData,
                showUploadResultsSMSPreview: true
            });
        }
        else {
            this.setState({ showUploadResultsSMSPreview: false });
        }

        this.setState({
            showDragModal: false,
            showRaisePOModal: false,
            showDrawViewModal: false,
            showReturnTicketModal: false,
            showUploadResultsModal: false,
            showUploadWinnersModal: false,
            showPurchaseOrderViewModal: false,
            showAddNewDraw: false,
        });
    }

    handleDrawAction = (params) => {
        let { actionData, actionType } = this.state;

        if (params !== 'cancel') {

            drawService.actionStatusChange(actionType.id, actionData.id, actionData.lotteryId).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        toast: {
                            type: 'success',
                            message: 'Action has been done.'
                        }
                    })

                    this.updateGridState();
                }

                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        }

        this.setState({
            confirmModal: false

        });

    }

    actionDropdownClick = (params) => {
        console.log(params)
        switch (params.redirectTo) {
            case 'RAISE_PO':
                this.setState({ showRaisePOModal: true, actionData: params.data })
                break;

            case 'UPLOAD_TICKETS':
                this.setState({ showDragModal: true, actionData: params.data })
                break;

            case 'RETURN_TICKETS':
                this.setState({ showReturnTicketModal: true, actionData: params.data })
                break;

            case 'UPLOAD_RESULTS':
                this.setState({ showUploadResultsModal: true, actionData: params.data })
                break;

            case 'UPLOAD_WINNERS':
                this.setState({ showUploadWinnersModal: true, actionData: params.data })
                break;

            /**
             * DrawActions for changing the status
             */

            case 'CANCEL_DRAW':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: 7, msg: 'Cancel Draw' } })
                break;

            case 'START_SALES':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: 2, msg: 'Start Sale' } })
                break;

            case 'HOLD_SALES':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: 3, msg: 'Hold Sales' } })
                break;

            case 'HOLD_TICKETS':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: 3, msg: 'Hold Tickets' } })
                break;

            case 'CLOSE_SALES':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: 5, msg: 'Close Sales' } })
                break;

            case 'RESUME':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: 2, msg: 'Resume' } })
                break;
            case 'REMOVE_DRAW':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: DRAW_STATUS.REMOVE, msg: 'Remove Draw' } })
                break;
            case 'DELAY_DRAW':
                this.setState({ confirmModal: true, actionData: params.data, actionType: { id: DRAW_STATUS.DELAY, msg: 'Delay Draw' } })
                break;
            default:
                break;
        }
    }

    renderModal = () => {

        let { actionType } = this.state;

        if (this.state.showDragModal) {
            return (
                /**
                 * This is Upload Ticket Model
                 */
                <DragAndDrop showModal={this.state.showDragModal} dragData={this.state.actionData}
                    onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.showRaisePOModal) {
            return (
                <RaisePurchaseOrder showModal={this.state.showRaisePOModal} dragData={this.state.actionData}
                    onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.showReturnTicketModal) {
            return (
                <ReturnTickets showModal={this.state.showReturnTicketModal} dragData={this.state.actionData}
                    onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.showUploadResultsModal) {
            return (
                <UploadResults showModal={this.state.showUploadResultsModal} data={this.state.actionData}
                    onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.showUploadResultsSMSPreview) {
            return (
                <UploadResultsSMSPreview showModal={this.state.showUploadResultsSMSPreview}
                    message={this.state.messagePreview}
                    drawResultsData={this.state.drawResultsData} onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.showUploadWinnersModal) {
            return (
                <UploadWinners showModal={this.state.showUploadWinnersModal} dragData={this.state.actionData}
                    onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.showDrawViewModal) {
            return (
                <DrawView drawData={this.state.drawData} drawId={this.state.drawId}
                    showModal={this.state.showDrawViewModal} onClose={this.handleSubmitClose} />
            )
        }

        if (this.state.confirmModal) {
            return (
                <ConfirmModal isOpen={this.state.confirmModal} message={`Are you sure want to ${actionType.msg}?`}
                    onClose={this.handleDrawAction}></ConfirmModal>
            )
        }

        if (this.state.showPurchaseOrderViewModal) {
            return (
                <PurchaseOrderView drawData={this.state.drawData} drawId={this.state.drawId}
                    showModal={this.state.showPurchaseOrderViewModal} onClose={this.handleSubmitClose} />
            )
        }
        if (this.state.showAddNewDraw) {
            return (
                <DrawModalView drawId={this.state.drawId} drawData={this.state.drawData} showModal={this.state.showAddNewDraw} onClose={this.handleOnCloseNewDrawModal} onRefresh={this.handleRefresh}/>
            )
        }

    }

    // This is added to load spinner between grid filter
    gridLoader = () => {
        let { gridConfig } = this.state;
        gridConfig.gridData = [];
        gridConfig.meta.showLoadingSpinner = true;
        this.setState({
            gridConfig,
            gridReload: false,
        })
    }

    // Handle refresh button click here
    handleRefresh = () => {
        this.setState({
            reloader: true,
            drawInputField: {
                lotteriesList: '',
                statusList: '',
                drawId: ''

            }
        });
        setTimeout(() => {
            this.setState({
                reloader: false,
            });
            this.gridLoader();
            this.updateGridState();
        }, 1);
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    openAddNewDrawModal = () => {
        this.setState({
            showAddNewDraw: true,
            drawId: null,
            drawData: {}
        })
    }

    handleOnCloseNewDrawModal = () => {
        this.setState({
            showAddNewDraw: false
        });
    }

    render() {

        const { toast, lotteriesList, drawInputField, statusList, reloader, gridReload } = this.state;

        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type}
                    message={toast.message}></Toast>
                <Row >
                    <Col md={1}>
                        <label><h4>Draws</h4></label>
                    </Col>
                    <Col md={2}>
                        {reloader ? '' :
                            <CustomDropdown type={'lotteriesList'}
                                placeholder={{ name: 'Lottery Name' }}
                                options={lotteriesList}
                                handleOnSelected={this.handleOnDropdownSelected} />}
                    </Col>
                    <Col md={2}>
                        {reloader ? '' :
                            <CustomDropdown type={'statusList'}
                                placeholder={{ name: 'Status' }}
                                options={statusList}
                                handleOnSelected={this.handleOnDropdownSelected} />}
                    </Col>
                    <Col md={3}>
                        <InputGroup>
                            <Input type="number" name="drawId" id="drawId" value={drawInputField.drawId} onChange={this.handleValueChange} placeholder="Draw Number" />
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={this.handleSearch} block><i className="fas fa-search"></i> Search</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col md={2}>
                        <Button color="success" onClick={() => this.openAddNewDrawModal()} block><i className="fas fa-plus"></i> New Draw</Button>
                    </Col>
                    <Col md={2}>
                        <Button color='secondary' onClick={() => this.handleRefresh()}>
                            <i className="fas fa-redo"></i> Refresh
                        </Button>
                    </Col>

                </Row>
                <Row>
                    <Col md={12}>
                        {gridReload ? '' : this.renderGrid()}
                    </Col>
                </Row>
                {this.renderModal()}
            </Container>);
    }

}


export default Draws;
