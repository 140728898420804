/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

class Toast extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    componentWillReceiveProps = (newProps) => {
        if (newProps.isOpen !== this.state.isOpen) {
            this.setState({
                isOpen: newProps.isOpen
            })
        }
    }

    onDismiss = () => {
        this.setState({ isOpen: false });
        this.props.handleOnClose(false)
    }

    render() {
        const { type, message } = this.props;
        return (
            <Alert className="position-fixed text-center shadow-sm rounded" style={{ top: 0, width: '45%', left: '50%', marginLeft: '-22.5%', zIndex: 9999 }} color={type} isOpen={this.state.isOpen} toggle={this.onDismiss}>
                {message}
            </Alert>
        );
    }
}

Toast.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired
}

export default Toast;
