/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup } from 'reactstrap';

import UserModal from './UserModal';
import CustomGrid from '../../common/custom-controls/CustomGrid';
import Toast from '../../common/custom-controls/Toast';
import { userService } from '../../../services/user-service';
import SearchInput from '../../common/custom-controls/SearchInput';

class AdminUsers extends Component {

    GRID_CONFIG = {
        columns: [

            {
                id: 1,
                name: 'fullName',
                headerTitle: 'Name',
                canSort: true,
                width: '20%'
            },
            {
                id: 2,
                name: 'email',
                headerTitle: 'E-mail',
                canSort: true,
                width: '20%'
            },
            {
                id: 3,
                name: 'isActive',
                headerTitle: 'Status',
                canSort: true,
                useTemplate: true,
                width: '5%'
            },
            {
                id: 4,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '10%',
                useTemplate: true,
                templates: [
                    {
                        type: 'EDIT',
                        template: `<button class="btn btn-sm btn-primary"><i class="fas fa-pen"/></button>`
                    }
                ]
            }
        ],
        gridData: [],
        meta: {
            pageSize: 10,
            showLoadingSpinner: true
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            gridConfig: this.GRID_CONFIG,
            userId: null,
            toast: {
                type: '',
                message: ''
            },
            gridData: []
        }
    }

    toggleModal = (userId) => {
        this.setState({
            showModal: true,
            userId: userId ? userId : null,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleModalClose = (user) => {
        // console.log(user)
        if (user) {
            this.setState({
                toast: {
                    type: 'success',
                    message: 'Data has been saved.'
                }
            })
            this.updateGridState();
        }

        this.setState({ showModal: false });
    }

    renderModal = () => {
        if (this.state.showModal) {
            return (
                <UserModal userId={this.state.userId} showModal={this.state.showModal} onClose={this.handleModalClose} />
            )
        }
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        if (gridConfig.gridData) {
            return (
                <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick}></CustomGrid>
            )
        }
    }

    updateGridState = () => {
        let { gridConfig, gridData } = this.state;
        userService.getAll().then((results) => {
            if (results.statusCode === 200) {
                gridConfig.gridData = results.data;
                gridConfig.meta.showLoadingSpinner = false;
                gridData = results.data;
                this.setState({ gridConfig, gridData });
            }
        }).catch((error) => {
            gridConfig.meta.showLoadingSpinner = false;
            this.setState({
                gridConfig,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    componentDidMount = () => {
        this.updateGridState();
    }

    handleActionClick = (params) => {
        switch (params.type) {
            case 'EDIT':
                this.setState(prevState => ({
                    showModal: !prevState.showModal
                }));
                this.toggleModal(params.data.id);
                break;
            default:
                break;
        }
    }

    filterData = (filterText, filterColumn) => {
        let { gridConfig } = this.state;
        if (filterText) {
            gridConfig.gridData = this.state.gridData.filter((data) => {
                const columnData = data[filterColumn].toLowerCase();
                return columnData.indexOf(filterText.toLowerCase()) !== -1
            });
        } else {
            gridConfig.gridData = this.state.gridData;
        }

        this.setState({ gridConfig });
    };

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast } = this.state;
        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col sm={3}>
                        <label><h3>Admin Users</h3></label>
                    </Col>
                    <Col sm={6}>
                        <Form inline>
                            <FormGroup row>
                                <SearchInput onChange={this.filterData} name={'fullName'} placeholder={'Admin Name'} className={'mr-3'} />
                                {/* <Button color="primary" onClick={() => this.filterData('', 'fullName')}><i className="fas fa-search" /> Search</Button> */}
                            </FormGroup>

                        </Form>
                    </Col>
                    <Col sm={3}>
                        <Button color="success float-right" onClick={() => this.toggleModal()}><i className="fas fa-plus"></i> Add New User</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {this.renderGrid()}
                    </Col>
                </Row>
                {this.renderModal()}

            </Container>);
    }
}

export default AdminUsers;
