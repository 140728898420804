/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import queryString from 'query-string'

import { lotteryService } from '../../../services/lottery-service';
import LotteryBasics from './LotteryBasics';
import LotteryFormat from './LotteryFormat';
import LotteryDraws from './LotteryDraws';
import LotterySmsTemplates from './LotterySmsTemplates';
import Toast from '../../common/custom-controls/Toast';
import SubmitButton from '../../common/custom-controls/SubmitButton';
import { errorCode } from '../../../utils/error-code';

const STEP_HEADERS = [
    'Basic Information',
    'Lottery Format',
    'Lottery Draws',
    'Lottery SMS Templates'
]

class LotteryEdit extends Component {

    INITIAL_STATE = {
        step: '',
        lotteryId: '',
        showLoadingSpinner: true,
        submitted: false,
        toast: {
            type: '',
            message: ''
        },
        lotteryBasics: {},
        lotteryFields: [],
        lotteryDraws: [],
        smsTemplates: []
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE
        }
    }

    handleSubmit = () => {
        this.setState({ submitted: true });
        this.update();
    }

    handleExit = () => {
        this.redirectToLotteries();
    }

    redirectToLotteries() {
        this.props.history.push(`/lotteries`);
    }

    toastMessage(type, error) {
        if (type === 'success') {
            this.setState({
                toast: {
                    type: type,
                    message: 'Data has been saved.'
                }
            });
        } else if (type === 'warning') {
            this.setState({
                submitted: false,
                toast: {
                    type: type,
                    message: 'Try again.'
                }
            });
        } else {
            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: error.message ? error.message : errorCode[error.statusCode]
                }
            })
        }
    }

    update() {
        let { lotterId, step } = this.state;
        // eslint-disable-next-line default-case
        switch (step) {
            case '1':
                let lottery = Object.assign({}, this.lotteryBasics.validateAndRetrieveState());

                if (lottery) {

                    if (!lottery.isMainImageUpdated) {
                        lottery.mainImage = '';
                    }

                    if (!lottery.isMobileImageUpdated) {
                        lottery.mobileImage = '';
                    }

                    lotteryService.updateLottery(lottery).then((results) => {
                        if (results.statusCode === 200) {
                            this.toastMessage('success');
                            this.redirectToLotteries();
                        } else if (results.statusCode === 210) {
                            let error = {
                                message: results.data.description
                            }
                            this.toastMessage('error', error);
                        } else {
                            this.toastMessage('warning');
                        }
                    }).catch((error) => {
                        this.toastMessage('error', error);
                    });
                } else {
                    this.toastMessage('warning')
                }
                break;
            case '2':
                const lotteryFields = this.lotteryFields.validateAndRetrieveState();
                if (lotteryFields) {
                    lotteryService.updateLotteryFields(lotterId, lotteryFields).then((results) => {
                        if (results.statusCode === 200) {
                            this.toastMessage('success');
                            this.redirectToLotteries();
                        } else {
                            this.toastMessage('warning');
                        }
                    }).catch((error) => {
                        this.toastMessage('error', error);
                    });
                } else {
                    this.toastMessage('warning')
                }
                break;
            case '3':
                const lotteryDraws = this.lotteryDraws.validateAndRetrieveState();
                if (lotteryDraws) {
                    lotteryService.updateLotteryDraws(lotterId, lotteryDraws).then((results) => {
                        if (results.statusCode === 200 || results.statusCode === 201) {
                            this.toastMessage('success');
                            this.redirectToLotteries();
                        } else {
                            this.toastMessage('warning');
                        }
                    }).catch((error, results) => {
                        this.toastMessage('error', error);
                    });
                } else {
                    this.toastMessage('warning')
                }
                break;
            case '4':
                const smsTemplates = this.smsTemplates.validateAndRetrieveState();
                if (smsTemplates) {
                    lotteryService.updateLotterySmsTemplates(lotterId, smsTemplates).then((results) => {
                        if (results.statusCode === 200 || results.statusCode === 201) {
                            this.toastMessage('success');
                            this.redirectToLotteries();
                        } else {
                            this.toastMessage('warning');
                        }
                    }).catch((error) => {
                        this.toastMessage('error', error);
                    });
                }
                break;
        }
    }

    renderWizardStep = () => {
        const { step, lotteryBasics, lotteryFields, lotteryDraws, smsTemplates } = this.state;
        // eslint-disable-next-line default-case
        switch (step) {
            case '1':
                return <LotteryBasics lotteryBasics={lotteryBasics} ref={(ref) => this.lotteryBasics = ref} propertyReadOnly={true}></LotteryBasics>;
            case '2':
                return <LotteryFormat lotteryFields={lotteryFields} lotteryName={lotteryBasics.name} ref={(ref) => this.lotteryFields = ref}></LotteryFormat>;
            case '3':
                return <LotteryDraws lotteryDraws={lotteryDraws} lotteryName={lotteryBasics.name} ref={(ref) => this.lotteryDraws = ref} propertyReadOnly={true} lotteryBasics={lotteryBasics}></LotteryDraws>;
            case '4':
                return <LotterySmsTemplates smsTemplates={smsTemplates} lotteryName={lotteryBasics.name} ref={(ref) => this.smsTemplates = ref}></LotterySmsTemplates>;
        }
    }

    componentDidMount = () => {
        if (this.props.match.params) {

            const values = queryString.parse(this.props.location.search);
            const params = this.props.match.params;

            lotteryService.getLotteryById(params.id).then((results) => {

                if (results.statusCode === 200) {
                    const lotteryData = results.data;
                    this.setState({
                        showLoadingSpinner: false,
                        lotterId: params.id,
                        step: values.step,
                        lotteryBasics: lotteryData.lotteryBasics,
                        lotteryFields: lotteryData.lotteryFields,
                        lotteryDraws: lotteryData.lotteryDraws,
                        smsTemplates: lotteryData.smsTemplates
                    });
                }
            }).catch((error) => {
                this.setState({
                    showLoadingSpinner: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.redirectToLotteries();
        }
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { step, toast, showLoadingSpinner, submitted } = this.state;

        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <Row>
                    <Col sm={6}>
                        <h4>Edit Lottery - {STEP_HEADERS[(parseInt(step)) - 1]}</h4>
                    </Col>
                </Row>

                <div className="border bg-white p-4 mt-2 shadow-sm" style={{ borderRadius: '.5rem' }}>

                    {this.renderWizardStep()}

                    {showLoadingSpinner ? <Row className="pt-3">
                        <Col md={12} className="text-center">
                            <Spinner color="primary" />
                        </Col>
                    </Row> :
                        <Row className="pt-3">
                            <Col md={12} className="text-right">
                                <Button outline color="danger" className="mr-2" onClick={this.handleExit}>Cancel</Button>
                                <SubmitButton shouldDisable={submitted} submitted={submitted} onSubmit={this.handleSubmit}></SubmitButton>
                            </Col>
                        </Row>}
                </div>
            </Container >
        )
    }
}

export default LotteryEdit;
