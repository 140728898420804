/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get } from '../utils/http';

const getLotterySmsTemplates = () => {
    return get(`sms-templates/lottery`);
}

const getPackageSmsTemplates = () => {
    return get(`sms-templates/package`);
}

const getCommonSmsTemplates = () => {
    return get(`sms-templates/common`);
}


const getSingleLotterySmsTemplates = (name, id) => {
    return get(`sms-templates/${id}/template/LOTTERY/${name}`);
}

export const smsTemplateService = {
    getLotterySmsTemplates,
    getPackageSmsTemplates,
    getCommonSmsTemplates,
    getSingleLotterySmsTemplates
};
