/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { drawService } from '../../services/draw-service';
import Toast from '../common/custom-controls/Toast';
import './../custom.css'
import { GRID_LIMIT } from "../../utils/configs";
import ServerSideGrid from "../common/custom-controls/ServerSideGrid";
import { errorCode } from "../../utils/error-code";

class PurchaseOrderView extends Component {
    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        }

    }

    GRID_CONFIG = {
        columns: [
            {
                id: 1,
                name: 'orderNo',
                headerTitle: 'Order Number',
                canSort: false,
                width: '25%'
            },
            {
                id: 2,
                name: 'orderStatus',
                headerTitle: 'Order Status',
                canSort: false,
                width: '25%'
            },
            {
                id: 3,
                name: 'qtyOrdered',
                headerTitle: 'Ordered Quantity',
                canSort: false,
                width: '25%'
            },
            {
                id: 4,
                name: 'dateOrdered',
                headerTitle: 'Ordered Date',
                canSort: false,
                width: '25%'
            }
        ],
        gridData: [],
        meta: {
            pageSize: Number(GRID_LIMIT),
            showLoadingSpinner: true,
            totalItems: '',
            selectedPage: 1,
            order_by: '',
            short_by: ''
        }

    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.INITIAL_STATE,
            id: props.drawId,
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.drawData,
            gridConfig: this.GRID_CONFIG,
            gridData: [],
            drawData: [],
            serverSideData: ''
        }
        this.toggle = this.toggle.bind(this);
    }

    updateGridState = () => {
        const { data } = this.state;
        let { gridConfig, gridData } = this.state;
        drawService.getAllPurchaseOrdersForDraw(
            data.lotteryId,
            data.id,
            gridConfig.meta.pageSize,
            (gridConfig.meta.selectedPage - 1) * GRID_LIMIT)
            .then((results) => {
                if (results.statusCode === 200) {
                    setTimeout(() => {
                        gridConfig.gridData = results.data.data;
                        gridConfig.meta.totalItems = results.data.page.total;
                        gridConfig.meta.showLoadingSpinner = false;
                        gridData = results.data;
                        this.setState({ gridConfig, gridData });
                    }, 250);
                    //TODO: remove this timeout
                }
            }).catch((error) => {
                gridConfig.meta.showLoadingSpinner = false;
                this.setState({
                    gridConfig,
                    toast: {
                        type: 'warning',
                        message: error.message ? error.message : errorCode["403"]
                    }
                })
            });
    }

    //The grid response handled here
    handleResponce = (params) => {

        let { gridConfig } = this.state;

        gridConfig.meta.selectedPage = params.selectedPage;

        if (params.sortBy) {
            gridConfig.meta.order_by = params.sortBy;
        }

        if (params.sortOrder) {
            gridConfig.meta.short_by = params.sortOrder;
        }

        this.setState({ serverSideData: params, gridConfig });

        this.updateGridState();
    }

    componentDidMount = () => {
        this.updateGridState();
    }

    renderGrid = () => {
        const { gridConfig } = this.state;
        if (gridConfig.gridData) {
            return (
                <ServerSideGrid gridConfig={gridConfig}
                    handleOrder={this.handleResponce} handlePage={this.handleResponce}
                    toggleModal={'collapse'}></ServerSideGrid>
            )
        }
    }


    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    render() {
        const { toast, data, gridData } = this.state;
        return (
            <Modal size="lg" isOpen={this.state.showModal} className={'drawView-modal'} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type}
                    message={toast.message}></Toast>
                <div className='modal-header'>
                    <h5 className='modal-title'>
                        Purchase Orders For Draw No. &nbsp; {data.drawNumber}
                    </h5>
                </div>

                {gridData ?
                    <ModalBody>

                        <Row>
                            <Col md={12}>
                                <div>
                                    {this.renderGrid()}
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row className='text-right'>
                            <Col>
                                <Button outline color="secondary" onClick={this.handleClose}>Cancel</Button>
                            </Col>

                        </Row>

                    </ModalBody>
                    :
                    <ModalBody className={'drawView-body'}>

                        <Row className='text-right'>
                            <Col>
                                <Button outline color="secondary" onClick={this.handleClose}>Cancel</Button>
                            </Col>

                        </Row>

                    </ModalBody>
                }

            </Modal>
        )
    }
}

export default PurchaseOrderView;
