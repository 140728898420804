/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */
import React, { Component } from 'react';
import { Container, Form, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import logo from './../../assets/favicon.png';
import { signInAction } from './../../store/actions/auth.actions';
import SubmitButton from '../common/custom-controls/SubmitButton';
import Toast from '../common/custom-controls/Toast';
import { PROJECT_NAME } from "./../../utils/configs";

require('dotenv').config();
const INITIAL_STATE = {
    submitted: false,
    username: '',
    password: '',
    toast: {
        type: '',
        message: ''
    }
};

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    componentWillMount = () => {
        localStorage.removeItem('token');
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const user = {
            username: this.state.username,
            password: this.state.password,
        }

        this.setState({
            submitted: true
        });

        this.props.signInAction(user.username, user.password).then((response) => {
            // console.log(response)
            this.setState({
                ...INITIAL_STATE
            });
            this.props.history.push('/dashboard');
        }).catch((error) => {

            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: error.message ? error.message : 'Invalid username or password. Try again.'
                }
            });
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { username, password, submitted, toast } = this.state;
        const shouldDisable = username === '' || password === '' || submitted;
        return (
            <Container>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <div className="mx-auto text-center">

                    <div className="pb-2 pt-5 text-center">
                        <img className="d-block mx-auto mt-2 mb-2" src={logo} alt="lucky1-logo" width="150" height="150" />
                        <h1 className="mb-5 pb-2">{ PROJECT_NAME }</h1>
                        <h3>Login</h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col col-sm-8 col-md-6 col-lg-4">
                            <Form disabled={submitted} className="form-login" autoComplete="off">
                                <Input name="username" type="text" id="username" className="form-control mb-3" placeholder="Username or Email" autoFocus onChange={this.handleInputChange}
                                    value={username} />
                                <Input name="password" type="password" id="password" className="form-control mb-3" placeholder="Password" onChange={this.handleInputChange}
                                    value={password} />
                                <SubmitButton shouldDisable={shouldDisable} submitted={submitted} onSubmit={this.handleSubmit} buttonText={'SIGN IN'}></SubmitButton>
                            </Form>
                        </div>
                    </div>
                    <p className="mt-5 mb-3 text-muted">&copy; { PROJECT_NAME }</p>
                </div>
            </Container>
        );
    }
}

Login.propTypes = {
    signInAction: PropTypes.func.isRequired
}

export default connect(() => { return {} }, { signInAction })(Login);
