/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Nav, NavItem, NavLink, Fade, NavbarBrand } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { userService } from '../../../services/user-service'
import logo from '../../../assets/favicon.png';

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = { configsFadeIn: false, reportsFadeIn: false };
    }

    navigateTo = to => e => {
        e.preventDefault();
        this.props.history.push(`/${to}`);

        let hell = userService.checkUserPermission('Winners');
        console.log(hell)

    }

    toggleConfigs = (e) => {
        e.preventDefault();
        this.setState({
            configsFadeIn: !this.state.configsFadeIn
        });
    }

    toggleReports = (e) => {
        e.preventDefault();
        this.setState({
            reportsFadeIn: !this.state.reportsFadeIn
        });
    }

    navigateHome = (e) => {
        e.preventDefault();
        this.props.history.push('/dashboard');
    }

    render() {


        return (
            <Nav vertical className="pl-2">
                <NavLink href="/#" className="text-muted" onClick={this.navigateHome}>
                    <img className='logo-style' src={logo} alt="lucky1-logo" width="60" height="60" />
                </NavLink>

                {userService.checkUserPermission('Lottery', 'edit') ? <NavItem className="py-1">
                    <NavLink href="/#" className="text-muted" onClick={this.navigateTo('draws')}><i className="far fa-gem pr-2"></i> Draw</NavLink>
                </NavItem> : ''}

                {userService.checkUserPermission('Customer', 'edit') ?
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('subscribers')}><i className="fas fa-users pr-2"></i> Subscribers</NavLink>
                    </NavItem> : ''}

                {userService.checkUserPermission('BlackListUsers', 'edit') ?
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('blacklist')}><i className="fas fa-user-circle pr-2"></i> Blacklist Users</NavLink>
                    </NavItem> : ''}

                {userService.checkUserPermission('WinnersUpload', 'edit') ?
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('winners')}><i className="fas fa-trophy pr-2"></i> Winners</NavLink>
                    </NavItem> : ''}

                {/* {userService.checkUserPermission('Reports', 'edit') ?
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.toggleReports}><i className="fas fa-file-alt pr-2"></i> Reports <i className={this.state.reportsFadeIn ? 'fas fa-caret-down pl-2' : 'fas fa-caret-right pl-2'}></i></NavLink>
                    </NavItem> : ''} */}
                {this.state.reportsFadeIn ? <Fade className="pl-4" in={this.state.reportsFadeIn} timeout={100}>
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('reports/sales')}><i className="fas fa-flag pr-2"></i> Sales</NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('reports/prize-distribution')}><i className="fas fa-flag pr-2"></i> Prize Distribution</NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('reports/subscription')}><i className="fas fa-flag pr-2"></i> Subscription</NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('reports/templates')}><i className="fas fa-flag pr-2"></i> Templates</NavLink>
                    </NavItem>
                </Fade> : ''}

                {userService.checkUserPermission('Packages', 'edit') ?
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('packages')}><i className="fas fa-cube pr-2"></i> Packages</NavLink>
                    </NavItem> : ''}

                {userService.checkUserPermission('HighPrizeClaim', 'view') ?
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('prizeclaim')}><i className="fas fa-award pr-2"></i> High Prize Claim</NavLink>
                    </NavItem> : ''}

                {/* <NavItem className="py-1">
                    <NavLink href="/#" className="text-muted" onClick={this.navigateTo('campaigns')}><i className="far fa-calendar-check pr-2"></i> Campaign</NavLink>
                </NavItem> */}


                {(userService.checkUserPermission('SMSTemplate', 'edit') || userService.checkUserPermission('Lottery', 'edit') || userService.checkUserPermission('AdminUsers', 'edit')) ? <NavItem className="py-1">
                    <NavLink href="/#" className="text-muted" onClick={this.toggleConfigs}><i className="fas fa-cog pr-2"></i> Configurations <i className={this.state.configsFadeIn ? 'fas fa-caret-down pl-2' : 'fas fa-caret-right pl-2'}></i></NavLink>
                </NavItem> : ''}


                <Fade className="pl-4" in={this.state.configsFadeIn} timeout={10}>

                    {userService.checkUserPermission('SMSTemplate', 'edit') ?
                        <NavItem className="py-1">
                            <NavLink href="/#" className="text-muted" onClick={this.navigateTo('sms-templates')}><i className="fas fa-sms pr-2"></i> SMS Templates</NavLink>
                        </NavItem> : ''}

                    {userService.checkUserPermission('Lottery', 'edit') ?
                        <NavItem className="py-1">
                            <NavLink href="/#" className="text-muted" onClick={this.navigateTo('lotteries')}><i className="fas fa-ticket-alt pr-2"></i> Lotteries</NavLink>
                        </NavItem> : ''}


                    {/* <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('job-manager')}><i className="fas fa-tasks pr-2"></i> Job Manager</NavLink>
                    </NavItem> */}

                    {userService.checkUserPermission('AdminUsers', 'edit') ?
                        <NavItem className="py-1">
                            <NavLink href="/#" className="text-muted" onClick={this.navigateTo('admin-users')}><i className="fas fa-user-shield pr-2"></i> Admin Users</NavLink>
                        </NavItem> : ''}
                    {/* <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('global-config')}><i className="fas fa-globe-asia pr-2"></i> Global Config</NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('logs')}><i className="fas fa-paste pr-2"></i> Logs</NavLink>
                    </NavItem>
                    <NavItem className="py-1">
                        <NavLink href="/#" className="text-muted" onClick={this.navigateTo('invalid-sms')}><i className="fas fa-comment-slash pr-2"></i> Invalid SMS</NavLink>
                    </NavItem> */}
                </Fade>
            </Nav>
        );
    }
}

Navigation.protoTypes = {
    // TODO: define propTypes if there are any
}

export default withRouter(Navigation);
