/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */
require('dotenv').config();

export const BASE_API = process.env.REACT_APP_BASE_API;
export const STATIC_API = process.env.REACT_APP_STATIC_API;
export const TIME_OUT = process.env.REACT_APP_PRIZE_CLIME_OTP_TIME_OUT;
export const GRID_LIMIT = process.env.REACT_APP_GRID_LIMIT;
export const METADATA_COLUMN = ['salesType','chargeType'];
export const PROJECT_NAME = process.env.REACT_APP_WEBSITE_NAME;
export const LOTTERY_BOARDS = { NLB: "NLB", DLB: "DLB" };
export const LOTTERY_BOARD_LONG_NAME = process.env.REACT_APP_LOTTERY_BOARD_LONG_NAME;
export const LOTTERY_BOARD_SHORT_NAME = process.env.REACT_APP_LOTTERY_BOARD_SHORT_NAME;