/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

export const SUBSCRIPTION_OPTIONS = {
    SINGLE: 'SINGLE',
    BATCH: 'BATCH'
};

export const STRING = {
    TOAST_TYPE: {
        SUCCESS: 'success',
        WARNING: 'warning',
        PRIMARY: 'primary',
        DANGER: 'danger'
    },
    MESSAGE: {
        INVALID_MOBILE_NO: 'The Mobile Number is invalid',
        INVALID_SMS_REQUEST: 'The message format is invalid',
        CONFIRM_RETURN_TICKET: 'Are you sure want to Save this changes?',
    }
}
