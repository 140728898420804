/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../../../styles/css/symbols.css'

class CustomDropdown extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            selected: (props.placeholder) ? props.placeholder : props.options[0]
        }
    }


    componentWillReceiveProps = (newProps) => {
        if (this.state.selected && newProps.selectedId !== this.state.selected.id) {
            const selected = newProps.options.find((element) => {
                return element.id === newProps.selectedId;
            });
            if (selected) {
                this.setState({
                    selected
                });
            }
        }
    }

    handleChange = (value) => {
        this.setState({
            selected: value
        })
        this.props.handleOnSelected(value, this.props.type);
    }

    toggle = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {
        const { isOpen, selected } = this.state;
        const { options, disabled, placeholder } = this.props;

        return (
            <Dropdown isOpen={isOpen} toggle={this.toggle}>
                <DropdownToggle disabled={disabled} color={'white'} className="border w-100 btn-rounded">
                    <li style={{ display: 'inline-block', float: 'left' }}>
                        {selected ?
                            (placeholder && placeholder.name === 'Select a symbol' && selected.name !== 'Select a symbol') ?
                                <span className='signs'>{selected.name}</span> :
                                selected.name : ''}
                    </li>

                    <li style={{ display: 'inline-block', float: 'right' }}>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </li>
                </DropdownToggle>

                <DropdownMenu right className="w-100" style={{maxHeight:"350px", overflowY:"auto"}}>
                    {options.map((value, index) => {
                        if (!(value.status === 'InActive')) {
                            //Lottery field symbols
                            if (placeholder && placeholder.name === 'Select a symbol') {
                                return <DropdownItem active={selected && value.id === selected.id} key={index} onClick={() => this.handleChange(value)}>
                                    <span className='signs' style={{ width: '60px', float: 'left' }}>{value.name}</span>{value.id}
                                </DropdownItem>
                            } else {
                                return <DropdownItem active={selected && value.id === selected.id} key={index} onClick={() => this.handleChange(value)}>
                                    {value.name}
                                </DropdownItem>
                            }


                        }

                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

CustomDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    handleOnSelected: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default CustomDropdown;
