/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

class SubmitButton extends Component {

    handleSubmit = (e) => {
        this.props.onSubmit(e);
    }

    render() {
        const { shouldDisable, buttonText } = this.props;
        return (
            <Button color="primary" type="button" disabled={shouldDisable} onClick={this.handleSubmit}>
                {this.props.submitted ? <Spinner size="sm" color="light" /> : ''} {buttonText}
            </Button>
        );
    }
}

SubmitButton.defaultProps = {
    shouldDisable: false,
    buttonText: 'Submit',
}

SubmitButton.prototypes = {
    shouldDisable: PropTypes.bool,
    buttonText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired
}

export default SubmitButton;
