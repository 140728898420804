/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Form, Row, Col, FormGroup, Label, Input, CustomInput, ModalFooter, Button, Table, Spinner } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';

import './PackageModal.scss';
import SubmitButton from '../common/custom-controls/SubmitButton';
import CustomDropdown from '../common/custom-controls/CustomDropdown';
import CustomGridV2 from '../common/custom-controls/custome-grid-v2/CustomGridV2';
import packageService from '../../services/PackageService';
import { lotteryService } from '../../services/lottery-service';
import Toast from '../common/custom-controls/Toast';
import { demandTypes, days } from '../../utils/meta-data';
import { reportService } from '../../services/report-service';
import { smsTemplateService } from '../../services/sms-template-service';
import { isUndefined } from 'util';
import { errorCode } from './../../utils/error-code';

class PackageModal extends Component {

    INITIAL_STATE = {
        submitted: false,
        lotteryPackage: {
            isActive: true,
            isArchived: false,
            showLoadingSpinner: true,
            packageName: '',
            subscriptionKeyword: '',
            onDemandKeyword: '',
            subscriptionType: 1,
            lotteries: [],
            SMSTemplates: [],
        },
        gridConfig: {
            columns: [
                {
                    id: 1,
                    name: 'id',
                    headerTitle: '',
                    canSort: false,
                    width: '0%',
                    hidden: true
                },
                {
                    id: 2,
                    name: 'name',
                    headerTitle: 'Lottery Name',
                    canSort: true,
                    width: '50%'
                },
                {
                    id: 3,
                    name: 'dayName',
                    headerTitle: 'Day',
                    canSort: true,
                    width: '40%'
                },
                {
                    id: 4,
                    name: 'actions',
                    headerTitle: 'Actions',
                    canSort: false,
                    width: '10%',
                    useTemplate: true,
                    templates: [
                        {
                            type: 'DELETE',
                            template: `<i class="fas fa-trash-alt text-danger pr-2" style="font-size:20px"/>`
                        }
                    ]
                }
            ],
            gridData: [],
            meta: {
                pageSize: 5
            }
        },
        selectedDayId: 1,
        selectedLotteryId: '',
        lotteries: [],
        toast: {
            type: '',
            message: ''
        }
    };

    validator = null;

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                keywordsEqual: {
                    message: 'Subscription Keyword and On-demand Keyword must not be equal',
                    rule: () => {
                        return this.state.lotteryPackage.onDemandKeyword && this.state.lotteryPackage.subscriptionKeyword && this.state.lotteryPackage.onDemandKeyword !== this.state.lotteryPackage.subscriptionKeyword;
                    },
                    required: true
                }
            }
        });
        this.state = {
            ...this.INITIAL_STATE,
            showModal: props.showModal
        }
        this.toggle = this.toggle.bind(this);
    }

    //=============================== Event handling methods ===============================//

    /**
     * Triggers when user clicks on the cancel button.
     */
    closeButtonHandler = () => {
        this.props.onClose();
    };

    /**
     * Triggers this event when user clicks on the save button.
     * @param e
     * @returns {Promise<void>}
     */
    submitClickHandler = async (e) => {
        e.preventDefault();
        let { lotteryPackage, gridConfig } = this.state;

        const { packageId } = this.props;
        if (this.validator.allValid()) {
            this.setState({
                submitted: true
            });
            lotteryPackage.lotteries = gridConfig.gridData;
            if (packageId) {
                packageService.updatePackage(packageId, lotteryPackage).then((results) => {
                    if (results.statusCode === 200) {
                        this.props.onClose('success');
                    }

                    if (results.statusCode === 210) {
                        this.setState({
                            toast: {
                                type: 'warning',
                                message: results.data.description
                            }
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: error.message
                        }
                    })
                });
            }
            else {
                let smsTemplate = lotteryPackage.SMSTemplates;
                lotteryPackage.SMSTemplates.map((smsTemp, index) => {
                    smsTemplate[index].name = smsTemp.key;
                });

                lotteryPackage.SMSTemplates = smsTemplate;
                packageService.createPackage(lotteryPackage).then((results) => {
                    if (results.statusCode === 201) {
                        this.props.onClose('success');
                    }

                    if (results.statusCode === 210) {
                        this.setState({
                            toast: {
                                type: 'warning',
                                message: results.data.description
                            }
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: error.message
                        }
                    })
                });
            }
            this.setState({
                submitted: false
            });
        } else {
            this.validator.showMessages();
        }
    };

    /**
     * Triggers when user specify the text for package name or keyword
     * @param e
     */
    formValueChangeHandler = (e) => {
        let lotteryPackage = { ...this.state.lotteryPackage };
        lotteryPackage[e.target.name] = e.target.value;
        this.setState({
            lotteryPackage,
            toast: {
                type: '',
                message: ''
            }
        });
    };

    /**
     * Triggers when user select the type, lottery or the day from dropdown.
     * @param selected
     * @param type
     */
    dropDownSelectionHandler = (selected, type) => {
        console.log(selected, type)
        let { lotteryPackage, selectedLotteryId, selectedDayId } = this.state;
        switch (type) {
            case 'type':
                lotteryPackage.subscriptionType = selected.id;
                break;
            case 'lottery':
                selectedLotteryId = selected.id;
                break;
            case 'day':
                selectedDayId = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            lotteryPackage,
            selectedLotteryId,
            selectedDayId
        });
    };

    /**
     * Create SMSTemplate object or update the existing one when user specify the template text
     * @param e
     * @param index
     */
    // templateTextChangeHandler = (e, index) => {
    //     const { lotteryPackage } = this.state;
    //     //Get the template name
    //     const templateName = getTemplateName(index);
    //     //Check whether there is a SMSTemplate for user specified template text
    //     let smsTemplate = getSmsTemplate(lotteryPackage.SMSTemplates, index);

    //     console.log(smsTemplate)
    //     if (smsTemplate != null) {
    //         //Eg: e.target.name : en, ta or si, e.target.value : user specified template text
    //         smsTemplate[e.target.name] = e.target.value;
    //     }
    //     else {
    //         //Create new smsTemplate object and add to SMSTemplates
    //         smsTemplate = {};
    //         smsTemplate['key'] = templateName;
    //         smsTemplate[e.target.name] = e.target.value;
    //         lotteryPackage.SMSTemplates.push(smsTemplate);
    //     }
    //     this.setState({
    //         lotteryPackage
    //     });
    // };



    templateTextChangeHandler = (e, index) => {
        let { lotteryPackage } = this.state;


        lotteryPackage.SMSTemplates[index][e.target.name] = e.target.value;

        console.log(lotteryPackage.SMSTemplates)
        this.setState([lotteryPackage]);
    }
    /**
     * Triggers this event when user add lottery to package.
     */
    addPackageLotteryHandler = () => {
        let { selectedDayId, selectedLotteryId, gridConfig, lotteries } = this.state;
        if (selectedLotteryId) {
            const lottery = lotteries.find((lottery) => {
                return lottery.id === selectedLotteryId;
            });

            const day = days.find((day) => {
                return day.id === selectedDayId;
            });

            const packageLottery = gridConfig.gridData.find((pckgLottery) => {
                return pckgLottery.lotteryId === selectedLotteryId && pckgLottery.dayId === selectedDayId;
            });

            if (packageLottery) {
                return;
            }

            if(!gridConfig.gridData.some(data => ((data.day === selectedDayId) && (data.lotteryId === selectedLotteryId )))){
                gridConfig.gridData.push({
                    id: '',
                    lotteryId: lottery.id,
                    name: lottery.name,
                    day: day.id,
                    dayName: day.name,
                    isArchived: false
                });
            }
            else{
                this.setState({
                    toast: {
                        type: 'warning',
                        message: 'Cannot add multiple lottery with same day.'
                    }
                })
            }
            this.setState({
                gridConfig
            });
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Please select a lottery'
                }
            })
        }

    };

    gridActionClickHandler = (params) => {
        let { gridConfig } = this.state;
        switch (params.type) {
            case 'DELETE':
                // gridConfig.gridData.splice(params.index, 1);
                let index = (params.selectedPage - 1)*gridConfig.meta.pageSize + params.index;
                gridConfig.gridData[index].isArchived = true;
                if (gridConfig.gridData[index].id === ""){
                    gridConfig.gridData.splice(index,1);
                }
                // gridConfig.gridData[params.index].isArchived = true;
                // console.log(gridConfig.gridData)

                break;
            default:
                break;
        }

        this.setState({
            gridConfig
        })
    };

    showEditView = async () => {
        const { packageId } = this.props;
        if (packageId) {
            const { gridConfig, lotteries,lotteryPackage} = this.state;
            const result = await packageService.getPackage(packageId);
            result.data.lotteries.map((lp) => {
                const lottery = lotteries.find((lottery) => lottery.id === lp.lotteryId);
                const day = days.find((day) => {
                    return day.id === parseInt(lp.day);
                });

                gridConfig.gridData.push({
                    id: lp.id,
                    day: lp.day,
                    name: lottery.name,
                    lotteryId: lp.lotteryId,
                    dayName: day.name,
                    isArchived: lp.isArchived,
                    // isActive: lotteryPackage.isActive
                });
            });

            this.setState({ lotteryPackage: result.data, gridConfig: gridConfig });
        }
    };

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    handleActivation = () => {
        let { lotteryPackage , gridConfig } = this.state;
        lotteryPackage.isActive = !lotteryPackage.isActive;
        gridConfig.gridData.map((item)=>{
            item.isActive = lotteryPackage.isActive;
        });
        this.setState({ lotteryPackage , gridConfig});

        //======================Removed activation onclick======================//
        /** 
        packageService.updatePackageStatus(lotteryPackage.id, lotteryPackage.isActive).then((results) => {
            this.setState({
                toast: {
                    type: 'success',
                    message: 'Record has been updated.'
                }
            });
        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            });
        });

        */
    }

    //=============================== React life cycle methods ===============================//
    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    componentDidMount = async () => {
        const { lotteryPackage } = this.state;
        const { packageId } = this.props;
        console.log(lotteryPackage)
        if (!packageId) {
            smsTemplateService.getPackageSmsTemplates().then((result) => {
                lotteryPackage.SMSTemplates = result.data;
                this.setState({
                    lotteryPackage,
                    showLoadingSpinner: false
                });

            }).catch((error) => {
                // console.log(error);
            });
        } else if (packageId) {
            this.setState({
                showLoadingSpinner: false
            });
        }



        reportService.getLotteryList().then((results) => {
            if (results.statusCode === 200) {
                this.setState({
                    lotteries: results.data
                });
                this.showEditView();
            }
        }).catch((error) => {

        });
    };

    //=============================== render methods ===============================//

    renderGrid = () => {
        const { gridConfig } = this.state;
        return (
            <CustomGridV2 gridConfig={gridConfig} actionHandler={this.gridActionClickHandler} />
        )
    };

    renderTemplates = () => {
        const { lotteryPackage, showLoadingSpinner } = this.state;
        console.log(showLoadingSpinner)
        if (isUndefined(showLoadingSpinner) || showLoadingSpinner) {

            return (<Row className="py-3">
                <Col md={12}>
                    <Spinner color="primary" />
                </Col>
            </Row>)
        } else {
            return (
                lotteryPackage.SMSTemplates && lotteryPackage.SMSTemplates.map((template, index) => {

                    return (
                        <Row className="py-3">
                            <Col md={12}>
                                <Label for="unsubscribedFromPackage" style={{ textTransform: 'lowercase' }}>{template.key ? template.key.replace(/_/g, " ") : template.name.replace(/_/g, " ")} </Label>
                                <Input type="text" name="en" placeholder="English" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, index)} value={template ? template.en : ''} />
                                <Input type="text" name="ta" placeholder="Tamil" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, index)} value={template ? template.ta : ''} />
                                <Input type="text" name="si" placeholder="Sinhala" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, index)} value={template ? template.si : ''} />
                            </Col>
                        </Row>
                    )
                })
            )
        }
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { packageId } = this.props;
        const { toast, lotteryPackage, submitted, selectedDay, selectedLotteryId, lotteries } = this.state;
        const template1 = getSmsTemplate(lotteryPackage.SMSTemplates, 0);
        const template2 = getSmsTemplate(lotteryPackage.SMSTemplates, 1);
        const template3 = getSmsTemplate(lotteryPackage.SMSTemplates, 2);

        return (
            <Modal size="lg" isOpen={this.state.showModal} modalClassName={'package-modal'} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={!!toast.message} type={toast.type} message={toast.message} />
                <div className='modal-header'>
                    <h5 className='modal-title'>
                        {packageId ? 'Edit Package' : 'Add New Package'}
                    </h5>
                    <Col md={6} className="text-right">
                        <CustomInput disabled={packageId ? false : true} className="text-right" type="switch" id="isActive" name="isActive" checked={lotteryPackage.isActive} onChange={this.handleActivation} label="Activate" />
                    </Col>
                </div>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={7}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="required">
                                            <Label for="name">Package Name</Label>
                                            <Input type="text" name="packageName" id="name" value={lotteryPackage.packageName} onChange={this.formValueChangeHandler} placeholder="Package Name" size="200" />
                                            <span className="text-danger"><small>{this.validator.message('Package Name', lotteryPackage.packageName, 'required')}</small></span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="required">
                                            <Label for="bscriptionKeyword">Subscription Keyword</Label>
                                            <Input type="text" name="subscriptionKeyword" id="subscriptionKeyword" value={lotteryPackage.subscriptionKeyword} onChange={this.formValueChangeHandler} placeholder="Subscription Keyword" size="20" />
                                            <span className="text-danger"><small>{this.validator.message('Subscription Keyword', lotteryPackage.subscriptionKeyword, 'keywordsEqual')}</small></span>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className="required">
                                            <Label for="DemandKeyword">On-demand Keyword</Label>
                                            <Input type="text" name="onDemandKeyword" id="onDemandKeyword" value={lotteryPackage.onDemandKeyword} onChange={this.formValueChangeHandler} placeholder="On-demand Keyword" size="20" />
                                            <span className="text-danger"><small>{this.validator.message('On-demand Keyword', lotteryPackage.onDemandKeyword, 'keywordsEqual')}</small></span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="py-3">
                                    <Col md={12} >
                                        <div className="border-bottom" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={7}>
                                        <FormGroup className="required">
                                            <Label for="lotteryName">Lottery Name</Label>
                                            {lotteries && lotteries.length ?
                                                <CustomDropdown type={'lottery'} placeholder={{ name: 'Select a lottery' }} options={lotteries} selectedId={selectedLotteryId} handleOnSelected={this.dropDownSelectionHandler} />
                                                : ''
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <FormGroup className="required">
                                            <Label for="day">Day</Label>
                                            {days && days.length ?
                                                <CustomDropdown type={'day'} options={days} selectedId={selectedDay} handleOnSelected={this.dropDownSelectionHandler} />
                                                : ''
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                    <Col md={12} className="text-right">
                                        <Button color="success" onClick={this.addPackageLotteryHandler}><i className={this.isEditView ? 'fas fa-save' : 'fas fa-plus'} /> {this.isEditView ? 'Update' : 'Add'}</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {this.renderGrid()}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={5} className="border-left">
                                {this.renderTemplates()}
                                {/* <Row className="py-3">
                                    <Col md={12}>
                                        <Label for="unsubscribedFromPackage">Successfully Unsubscribed from Package</Label>
                                        <Input type="text" name="en" placeholder="English" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 0)} value={template1 ? template1.en : ''} />
                                        <Input type="text" name="ta" placeholder="Tamil" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 0)} value={template1 ? template1.ta : ''} />
                                        <Input type="text" name="si" placeholder="Sinhala" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 0)} value={template1 ? template1.si : ''} />
                                    </Col>
                                </Row>
                                <Row className="py-3">
                                    <Col md={12}>
                                        <Label for="packageRegistrationInstruction">Package Registration Instruction</Label>
                                        <Input type="text" name="en" placeholder="English" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 1)} value={template2 ? template2.en : ''} />
                                        <Input type="text" name="ta" placeholder="Tamil" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 1)} value={template2 ? template2.ta : ''} />
                                        <Input type="text" name="si" placeholder="Sinhala" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 1)} value={template2 ? template2.si : ''} />
                                    </Col>
                                </Row>
                                <Row className="py-3">
                                    <Col md={12}>
                                        <Label for="alreadyRegistered">Already Registered</Label>
                                        <Input type="text" name="en" placeholder="English" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 2)} value={template3 ? template3.en : ''} />
                                        <Input type="text" name="ta" placeholder="Tamil" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 2)} value={template3 ? template3.ta : ''} />
                                        <Input type="text" name="si" placeholder="Sinhala" className="mb-2" onChange={(e) => this.templateTextChangeHandler(e, 2)} value={template3 ? template3.si : ''} />
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="danger" onClick={this.closeButtonHandler}>Cancel</Button>
                    <SubmitButton shouldDisable={submitted} submitted={submitted} onSubmit={this.submitClickHandler} />
                </ModalFooter>
            </Modal>
        )
    }
}

//=============================== Supportive methods ===============================//

/**
 * Return the template name for the given index.
 * @param index
 * @returns {string}
 */
function getTemplateName(index) {
    let templateName = '';
    switch (index) {
        case 0:
            templateName = 'Successfully_Unsubscribed_From_Package';
            break;
        case 1:
            templateName = 'Package_Registration_Instruction';
            break;
        case 2:
            templateName = 'Already_Registered';
            break;
        default:
            break;
    }
    return templateName;
}

function getSmsTemplate(SMSTemplates, index) {
    const templateName = getTemplateName(index);
    return SMSTemplates.find((template) => {
        return template.name === templateName;
    });
}



//=============================== PropTypes ===============================//

PackageModal.propTypes = {
    packageId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
}

export default PackageModal;
