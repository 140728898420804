/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner, Form, FormGroup, CustomInput, Input } from 'reactstrap';
import { subscriberService } from '../../services/subscriber-service';
import Toast from '../common/custom-controls/Toast';
import SubscribersEdit from './SubscribersEdit';
import SubscribersNicEdit from './SubscribersNicEdit';
import IndividualPackageSummary from './IndividualPackageSummary';
import IndividualSubscriptionLog from './IndividualSubscriptionLog';
import SubscribeByCMS from './SubscribeByCMS';
import { errorCode } from './../../utils/error-code';
import { mobileValidation } from './../../utils/validations';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import NicChangeHistory from './NicChangeHistory';
import '../custom.css';
import Loader from './../common/custom-controls/Loader';

class SubscribersView extends Component {
    INITIAL_STATE = {
        showModal: false,
        submitted: false,
        showLoadingSpinner: true,
        subscribersData: {
            mobile: '',
            nic: '',
            isActive: true,
            permissions: [],
            mobileNumber: ''
        },
        toast: {
            type: '',
            message: ''
        },
        searchInputField: {
            mobile: ''
        },
        showUnsubscriptionModal: false,
        showSubscriptionModal: false      
    }
    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE
        }
    }

    redirectToNewUser(mobile) {
        subscriberService.getSubscribersByMobile(mobile).then((results) => {
            if (results.data.id) {
                this.props.history.push(`/subscribers/${results.data.id}`, { mobile: mobile});
                this.setState({
                    showLoadingSpinner: true
                })
                this.componentDidMount();
            }
        }).catch((error) => {
            this.setState({
                showLoadingSpinner: true,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    handleExit = () => {
        this.redirectToLotteries();
    }

    componentDidMount = () => {
        if (this.props.match.params && this.props.location.state) {
            const params = this.props.match.params;
            const mobile = this.props.location.state.mobile;

            subscriberService.getSubscribers(params.id).then((results) => {

                if (results.statusCode === 200) {
                    let subscribersData = results.data;
                    subscribersData.mobileNumber = mobile;
                    this.setState({
                        showLoadingSpinner: false,
                        subscribersData: subscribersData
                    });
                } else {
                    this.setState({
                        showLoadingSpinner: false,
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.redirectToLotteries();
        }
    }

    redirectToSubscribersEdit = (id) => {
        this.setState({
            showSubEditModal: true,
            subscriberId: id
        })
    }

    redirectToSubscribersNicEdit = (id) => {
        this.setState({
            SubscribersNicEditModal: true,
            subscriberId: id
        })
    }

    redirectToUnsubscribe = (id) => {
        this.setState({
            showUnsubscriptionModal: true,
        })
    }

    redirectToSubscribe = (id) => {
        this.setState({
            showSubscriptionModal: true,
        })
    }

    redirectToNicHistory = (id) => {
        this.setState({
            NicChangeHistoryModal: true,
            subscriberId: id
        })
    }

    handleModalClose = (user) => {
        if (user.message) {
            this.setState({
                toast: {
                    type: 'success',
                    message: user.message
                }
            })

            if (user.mobile) {
                this.redirectToNewUser(user.mobile);
            } else {
                this.componentDidMount();
            }
        }

        this.setState({
            showSubEditModal: false,
            SubscribersNicEditModal: false,
            NicChangeHistoryModal: false,
            showSubscriptionModal: false
        });
    }

    handleValueChange = (e) => {
        let searchInputField = { ...this.state.searchInputField };
        searchInputField[e.target.name] = e.target.value;
        this.setState({
            searchInputField
        });

    }

    //Unsubscription for the top button
    handleUnsubscriptionClose = (params) => {
        const { subscribersData } = this.state;

        if (params !== 'cancel') {
            subscriberService.allUnsubscribeById(subscribersData.id).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        showLoadingSpinner: true,
                        toast: {
                            type: 'success',
                            message: 'Successfully unsubscribed.'
                        }
                    })

                    this.componentDidMount();
                } else {
                    //Business logic error here
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });

        }
        this.setState({ showUnsubscriptionModal: false })
    }

    renderModal = () => {
        if (this.state.showSubEditModal) {
            return (
                <SubscribersEdit subscriberId={this.state.subscriberId} showModal={this.state.showSubEditModal} onClose={this.handleModalClose} />
            )
        }
        if (this.state.SubscribersNicEditModal) {
            return (
                <SubscribersNicEdit subscriberId={this.state.subscriberId} showModal={this.state.SubscribersNicEditModal} onClose={this.handleModalClose} />
            )
        }
        if (this.state.showUnsubscriptionModal) {
            return (
                <ConfirmModal isOpen={this.state.showUnsubscriptionModal} message={'Are you sure you want to Unsubscribe from all packages?'} onClose={this.handleUnsubscriptionClose}></ConfirmModal>

            )
        }
        if (this.state.NicChangeHistoryModal) {
            return (
                <NicChangeHistory subscriberId={this.state.subscriberId} showModal={this.state.NicChangeHistoryModal} onClose={this.handleModalClose} />
            )
        }
        if (this.state.showSubscriptionModal) {
            return (
                <SubscribeByCMS userData={this.state.subscribersData} showModal={this.state.showSubscriptionModal} onClose={this.handleModalClose} />
            )
        }
    }

    handleUpdate = () => {
        this.setState({ showLoadingSpinner: true })
        this.componentDidMount();
    };

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    handleOnSearch = (toast) => {
        if (toast.message) {
            this.setState({
                toast
            })
        }

    }

    handleSearch = () => {
        const { searchInputField } = this.state;

        if (searchInputField.mobile && mobileValidation(searchInputField.mobile)) {
            subscriberService.getSubscribersByMobile(searchInputField.mobile).then((results) => {
                if (results.statusCode === 200) {
                    this.props.history.push(`/subscribers/${results.data.id}`, { mobile: searchInputField.mobile});
                    this.setState({
                        showLoadingSpinner: true,
                        searchInputField: {
                            mobile: ''
                        }
                    })
                    this.componentDidMount();
                } else {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Mobile number is not valid'
                }
            })
        }
    }

    render() {
        const { toast, showLoadingSpinner, subscribersData, searchInputField } = this.state;

        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <div>
                    <Row>
                        <Col md={8}>
                            <label><h4>Subscribers</h4></label>
                        </Col>
                        <Col md={4} className="text-right pl-2">
                            <Form inline>
                                <FormGroup row>
                                    {/* onChange={this.filterData} */}
                                    <Input
                                        className='form-control-rounded'
                                        type="tel" name={'mobile'}
                                        maxLength="11" id="mobile"
                                        placeholder={'Mobile Number'}
                                        onChange={this.handleValueChange}
                                        value={searchInputField.mobile} />
                                    <span className="mr-3">
                                    </span>
                                    <Button className='btn-rounded' color="primary" onClick={() => this.handleSearch()}><i className="fas fa-search"></i> Search</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                </div>

                {showLoadingSpinner ?
                    <Row className="border bg-white px-3 pt-3 pb-2 mt-2 shadow-sm">
                        <Col md={12} className="text-center">
                            <Spinner color="primary" />
                        </Col>

                    </Row> :
                    <div>
                        <div className="border bg-white shadow-sm" style={{ borderRadius: '.5rem', cursor: 'pointer' }} >
                            <div className='ml-5 mr-3'>
                                <Row className="pt-3">
                                    <Col md={2}>
                                        <Row>
                                            <h6>MOBILE</h6>
                                        </Row>
                                        <Row>
                                            <h5>{subscribersData.mobile}</h5>
                                        </Row>

                                    </Col>
                                    <Col md={1}>
                                        <button className="btn btn-sm btn-primary" aria-expanded="false" onClick={() => this.redirectToSubscribersEdit(subscribersData.id)}>
                                            <i className="fas fa-pen"></i>
                                        </button>
                                    </Col>
                                    <Col md={2}>
                                        <Row>
                                            <h6>NIC</h6>
                                        </Row>
                                        <Row>
                                            <h5>{subscribersData ? subscribersData.NIC : '-'}</h5>
                                        </Row>

                                    </Col>
                                    <Col md={1}>
                                        <button className="btn btn-sm btn-primary" aria-expanded="false" onClick={() => this.redirectToSubscribersNicEdit(subscribersData.id)}>
                                            <i className="fas fa-pen"></i>
                                        </button>
                                    </Col>
                                    <Col md={2} className="text-right">
                                        {/* <button className="btn btn-md text-danger" aria-expanded="false" onClick={() => this.redirectToSubscribe(subscribersData.id)}>
                                            Subscribe
                                        </button> */}
                                    </Col>
                                    <Col md={2} className="text-right">
                                        <button className="btn btn-md text-danger" aria-expanded="false" onClick={() => this.redirectToNicHistory(subscribersData.id)}>
                                            Nic History
                                        </button>
                                    </Col>
                                    <Col md={2} className="text-right">
                                        <button className="btn btn-md text-danger" onClick={() => this.redirectToUnsubscribe(subscribersData.id)}>
                                            Unsubscribe All
                                    </button>
                                    </Col>

                                </Row>

                            </div>
                        </div>

                        <div style={{}} className="border bg-white shadow-sm mt-2">
                            <div className="classic-tabs" >
                                <ul className="nav tabs-primary border-bottom" id="myClassicTab" role="tablist" style={{ fontSize: '15px', padding: '5px', borderRadius: '.5rem' }}>
                                    <li className="nav-item" >
                                        <a className="nav-link waves-light active show text-muted" id="profile-tab-classic" data-toggle="tab" href="#package-summary"
                                            role="tab" aria-controls="profile-classic" aria-selected="true">Package Summary</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link waves-light text-muted" id="follow-tab-classic" data-toggle="tab" href="#subscribers-log" role="tab"
                                            aria-controls="follow-classic" aria-selected="false">Subscribers Log</a>
                                    </li>
                                    {/* <button onClick={this.handleUpdate}>Update</button> */}

                                </ul>

                                <div className="tab-content border-right border-bottom border-left rounded-bottom" id="myClassicTabContent">
                                    <div className="tab-pane fade active show" id="package-summary" role="tabpanel" aria-labelledby="profile-tab-classic">
                                        <IndividualPackageSummary onSearch={this.handleOnSearch} userData={subscribersData} />
                                    </div>
                                    <div className="tab-pane fade" id="subscribers-log" role="tabpanel" aria-labelledby="follow-tab-classic">
                                        <IndividualSubscriptionLog onSearch={this.handleOnSearch} userData={subscribersData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }

                {this.renderModal()}

            </Container >
        )
    }



}

export default SubscribersView;
