/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Row, Col, Input, FormGroup, Label, Table, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import { smsTemplateService } from '../../../services/sms-template-service';

class LotterySmsTemplates extends Component {

    INITIAL_STATE = {
        smsTemplates: [],
        showLoadingSpinner: true
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.INITIAL_STATE
        }
    }

    componentDidMount = () => {
        console.log(this.props.smsTemplates)
        if (this.props.smsTemplates.length > 0) {
            this.setState({
                smsTemplates: this.props.smsTemplates,
                showLoadingSpinner: false
            });
        } else {
            smsTemplateService.getLotterySmsTemplates().then((result) => {
                let smsTemplate = result.data;
                result.data.map((smsTemp, index) => {
                    smsTemplate[index].name = smsTemp.key;
                });

                this.setState({
                    smsTemplates: smsTemplate,
                    showLoadingSpinner: false
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    templateTextChangeHandler = (e, index) => {
        let { smsTemplates } = this.state;
        smsTemplates[index][e.target.name] = e.target.value;
        this.setState([smsTemplates]);
    }

    validateAndRetrieveState = () => {
        const { smsTemplates } = this.state;
        //add validations if there is any
        return smsTemplates;
    }

    renderTemplates = () => {
        const { smsTemplates } = this.state;

        if (this.state.showLoadingSpinner) {
            return (<tr className="text-center"><td colSpan="4"><Spinner color="primary" /></td></tr>);
        } else {
            return (
                smsTemplates && smsTemplates.map((template, index) => {
                    return <tr key={index}>
                        <th className="font-weight-normal" style={{ textTransform: 'lowercase' }}>{template.name.replace(/_/g, " ")}</th>
                        <td>
                            <Input type="textarea" rows="4" name="en" value={template.en} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="ta" value={template.ta} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                        <td>
                            <Input type="textarea" rows="4" name="si" value={template.si} onChange={(e) => this.templateTextChangeHandler(e, index)}></Input>
                        </td>
                    </tr>
                })
            )
        }
    }

    render() {
        const { lotteryName } = this.props;
        return (
            <div>
                <FormGroup>
                    <Label for="lotteryName">Lottery Name</Label>
                    <Input type="text" name="lotteryName" id="lotteryName" value={lotteryName} readOnly />
                </FormGroup>

                <Row className="pt-4 pb-2">
                    <Col md={12} >
                        <div ><h5>SMS Templates</h5></div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} >
                        <Table bordered className="text-muted">
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '1px' }}>SMS Name</th>
                                    <th style={{ borderBottom: '1px' }}>English</th>
                                    <th style={{ borderBottom: '1px' }}>Tamil</th>
                                    <th style={{ borderBottom: '1px' }}>Sinhala</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTemplates()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }
}

LotterySmsTemplates.propTypes = {
    lotteryName: PropTypes.string.isRequired,
    smsTemplates: PropTypes.array.isRequired
}

export default LotterySmsTemplates;
