/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, Container, Row, Col, Div, Button } from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone'
import Toast from '../common/custom-controls/Toast';
import { subscriberService } from '../../services/subscriber-service';
import { errorCode } from './../../utils/error-code';
const FileType = require('file-type');


class BlacklistUpload extends Component {

    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        }
    }

    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            if (files.length > 1) {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: 'Please Select only one file'
                    }
                })
            } else {
                this.setState({ files })
            }
        };

        this.state = {
            ...this.INITIAL_STATE,
            files: [],
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.dragData
        };
        this.toggle = this.toggle.bind(this);
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
    }


    handleSubmit = () => {
        const { files } = this.state;

        if (files.length) {
            const fileData = new FormData();
            const imagedata = files[0];

            fileData.append('file', imagedata);

            if (imagedata.type === 'text/csv' || imagedata.path.split('.').pop() === 'csv') {
                subscriberService.uploadBlacklistFile(fileData).then((results) => {
                    if (results.status === 200) {
                        this.setState({
                            ...this.INITIAL_STATE
                        });
                        this.handleClose('success');
                    } else if (results.status === 210) {
                        this.setState({
                            submitted: false,
                            toast: {
                                type: 'warning',
                                message: results.data.description
                            }
                        });
                    }
                }).catch((error) => {
                    error = JSON.parse(JSON.stringify(error));
                    let code = error.message.substring(error.message.length - 3)
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: errorCode[code]
                        }
                    });
                })
            } else {
                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: 'Please upload CSV files only'
                    }
                });
            }
        } else {
            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: 'Please Select a file'
                }
            });
        }
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast } = this.state;

        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        const dragText = (
            <div>
                <h5>Drag and Drop Blacklist file to upload</h5>
                <i className="fa fa-upload" style={{ fontSize: "36px" }}></i>
            </div>
        );

        const insideZone = (files.length) ? files : dragText;

        return (

            <Modal isOpen={this.state.showModal} toggle={this.toggle} >
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}> </Toast>
                <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <section className="container" style={{ backgroundColor: '#eeeeee', flex: '1', padding: '15px', }}>

                            <h4>Upload Blacklist File</h4>
                            <br />
                            <div {...getRootProps({ className: 'dropzone' })}
                                style={{ borderBlock: 'solid', outline: '3px dashed #0275d8', padding: '20px', textAlign: 'center', color: '#0275d8' }}>

                                <input {...getInputProps()} accept=".csv" />

                                {insideZone}

                            </div>
                            <br />

                            <Row style={{ padding: '10px' }}>
                                <Col>
                                    <Button color="secondary" onClick={this.handleClose} > Cancel </Button>
                                </Col>

                                <Col className='text-right'>
                                    <Button color="primary" onClick={this.handleSubmit} > Submit  </Button>
                                </Col>
                            </Row>

                        </section>
                    )}
                </Dropzone>
            </Modal>

        );
    }

}

export default BlacklistUpload;