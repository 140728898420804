/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import Toast from '../common/custom-controls/Toast';
import { errorCode } from "../../utils/error-code";
import CustomGrid from '../common/custom-controls/CustomGrid';
import { subscriberService } from '../../services/subscriber-service';
import '../custom.css';
import fileImage from './../../assets/filedefault.png'

class NicChangeFiles extends Component {


    INITIAL_STATE = {
        showModal: false,
        submitted: false,
        toast: {
            type: '',
            message: ''
        },
        gridConfig: this.GRID_CONFIG,
        currentIndex: null,
        fileUrls: [],
    }

    validator = null;

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE,
            id: props.subscriberId,
            showModal: props.showModal,
            onClose: props.onClose
        }
    }



    componentDidMount = () => {
        const { id } = this.props;

        subscriberService.nicChangeDocuments(id).then((results) => {

            if (results.statusCode === 200) {
                this.setState({ fileUrls: results.data.fileURL })
            } else {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: results.data.description
                    }
                })
            }
        }).catch((error) => {
            this.setState({

                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    openModal = (e, index) => {
        //TODO
        // this.setState({ currentIndex: index });
    }

    closeModal(e) {
        if (e != undefined) {
            e.preventDefault();
        }
        this.setState({ currentIndex: null });
    }

    renderImageContent(src, index) {
        return (
            <div>
                <img src={src} key={src} onClick={(e) => this.openModal(e, index)} />
            </div>
        )
    }

    renderGalleryModal = () => {
        const { currentIndex, fileUrls } = this.state;

        return (
            <div>
                <div className="modal-overlay" onClick={(e) => this.closeModal(e)}>
                    <div isOpen={!!currentIndex} className="modal">
                        <div className='modal-body'>
                            eihfiehw hfhwea whrohwe rhwerh
                        <img src={fileUrls[currentIndex].url} />
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    render() {
        const { currentIndex, fileUrls } = this.state;

        return (
            <div className="gallery-container">
                <div className="gallery-grid">
                    {fileUrls.map((src, index) => {
                        if (checkFileType(src.doumentName)) {
                            return (
                                <div key={index}>
                                    <a href={src.url} download target="_blank">
                                        <img src={src.url} key={src} onClick={(e) => this.openModal(e, index)} />
                                    </a>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index}>
                                    <a href={src.url} download target="_blank">
                                        <img src={fileImage} key={src} onClick={(e) => this.openModal(e, index)} />
                                    </a>
                                </div>
                            )
                        }
                    })}
                </div>

                {currentIndex ? this.renderGalleryModal() : ''}
            </div>

        )
    }
}

export default NicChangeFiles;


function checkFileType(fileName) {
    let fileExtention = fileName.split('.').pop();
    fileExtention = fileExtention.toLowerCase();
    return (
        fileExtention === 'jpeg' ||
        fileExtention === 'jpg' ||
        fileExtention === 'png'
    )
}