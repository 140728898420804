/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Input, Button } from 'reactstrap';
import { PROJECT_NAME } from "./../../utils/configs";
require('dotenv').config();
class ChangePassword extends Component {

    render() {
        return (
            <Container className="container text-center">
                <div className="pb-2 pt-5  text-center">
                    <img className="d-block mx-auto mt-2 mb-2" src="" alt="" width="72" height="72" />
                    <h1 className="mb-5 pb-2">{ PROJECT_NAME }</h1>
                    <h3>Change password</h3>
                </div>
                <div className="row justify-content-center">
                    <div className="col col-sm-8 col-md-6 col-lg-4">
                        <Form className="form-login" autoComplete="off">
                            <Input type="password" id="currentPassword" className="form-control mb-3" placeholder="Current Password" required autoFocus />
                            <Input type="password" id="newPassword" className="form-control mb-3" placeholder="New Password" required />
                            <Input type="password" id="confirmPassword" className="form-control mb-3" placeholder="Confirm Password" required />
                            <Button color="primary" block type="submit">Change password</Button>
                            <Link to="/" className="btn btn-light btn-block">Back</Link>

                            <p className="mt-5 mb-3 text-muted">&copy; { PROJECT_NAME }</p>
                        </Form>
                    </div>
                </div>
            </Container>
        );
    }
}

export default ChangePassword;
