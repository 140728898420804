/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    CustomInput,
    ModalFooter,
    Button,
    Table,
    Spinner
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';

import { nicPermissionTypes, permissions } from '../../../utils/meta-data';
import SubmitButton from '../../common/custom-controls/SubmitButton';
import CustomDropdown from '../../common/custom-controls/CustomDropdown';
import { merchantService } from '../../../services/merchant-service';
import { userService } from '../../../services/user-service';
import { reportService } from '../../../services/report-service';
import Toast from '../../common/custom-controls/Toast';
import { get } from '../../../utils/http';
import { mobileValidation } from '../../../utils/validations';
import { errorCode } from '../../../utils/error-code';

class UserModal extends Component {

    INITIAL_STATE = {
        submitted: false,
        showLoadingSpinner: true,
        userData: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            isMerchantUser: false,
            nicPermissionType: true,
            changePasswordChecked: false,
            merchantId: 1,
            password: '',
            confirmPassword: '',
            hasAllPermissions: false,
            isActive: false,
            permissions: [],
            operator: 0,
            validPermissions: []
        },
        toast: {
            type: '',
            message: ''
        }

    }

    validator = null;

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                confirmPassword: {
                    message: 'The :attribute must be equal to Password',
                    rule: (val) => {
                        return this.state.userData.password && this.state.userData.password === val;
                    },
                    required: true
                },
                password: {
                    message: 'The :attribute must have one Uppercase and Number',
                    rule: (val) => {
                        var re = /[A-Z].*\d|\d.*[A-Z]/
                        return re.test(val);
                    },
                    required: true
                },
                telephone: {
                    message: 'The Mobile Number is invalid',
                    rule: (val) => {
                        return mobileValidation(val);
                    },
                    required: true
                }
            }
        });
        this.state = {
            ...this.INITIAL_STATE,
            showModal: props.showModal,
            userId: props.userId
        }
        this.toggle = this.toggle.bind(this);

    }

    getModifiedPermissions(permissions, hasAllPermissions) {
        // permissions.forEach(permission => {
        //     permission.add = hasAllPermissions;
        //     permission.view = hasAllPermissions;
        //     permission.edit = hasAllPermissions;
        //     permission.delete = hasAllPermissions;
        // });

        const { validPermissions } = this.state;

        let allpermissions = validPermissions.map((element) => {
            const permissions = {
                add: element.value.includes("Add") ? hasAllPermissions : 'disabled',
                delete: element.value.includes("Delete") ? hasAllPermissions : 'disabled',
                edit: element.value.includes("Edit") ? hasAllPermissions : 'disabled',
                view: element.value.includes("View") ? hasAllPermissions : 'disabled',
                name: element.name
            }
            return permissions;

        });

        // console.log(permissions)
        return allpermissions;
    }

    checkAllPermissionsChecked(permissions) {
        let isAllChecked = true;
        permissions.forEach(permission => {
            if (!permission.add || !permission.view || !permission.edit || !permission.delete) {
                isAllChecked = false;
            }
            if (!isAllChecked) return;
        });
        return isAllChecked;
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleAllPermisionChange = () => {
        let userData = { ...this.state.userData };

        userData.hasAllPermissions = !userData.hasAllPermissions;
        userData.permissions = this.getModifiedPermissions(userData.permissions, userData.hasAllPermissions)

        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleNicMobilePermisionChange = () => {
        let userData = { ...this.state.userData };
        userData.nicPermissionType = !userData.nicPermissionType;

        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handlePermissionChange = (index, permissionType) => {
        let userData = { ...this.state.userData };
        userData.permissions[index][permissionType] = !userData.permissions[index][permissionType];
        userData.hasAllPermissions = this.checkAllPermissionsChecked(userData.permissions);

        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleOnDropdownSelected = (selected, type) => {
        let userData = { ...this.state.userData };

        switch (type) {
            case 'merchant':
                userData.merchantId = selected.id;
                break;
            case 'nicPermissionType':
                userData.nicPermissionType = selected.id;
                break;
            case 'operator':
                userData.operator = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleMerchantCheckChange = () => {
        let userData = { ...this.state.userData };
        userData.isMerchantUser = !userData.isMerchantUser;
        if (userData.isMerchantUser) {
            userData.merchantId = 1;
        } else {
            userData.merchantId = null;
        }
        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleActiveStatusChange = () => {
        let userData = { ...this.state.userData };
        userData.isActive = !userData.isActive;
        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleFormValueChange = (e) => {
        let userData = { ...this.state.userData };
        userData[e.target.name] = e.target.value;
        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }
    handleChangePassStatusChange = (e) => {
        let userData = { ...this.state.userData };
        userData.changePasswordChecked = !userData.changePasswordChecked;
        // console.log(userData.changePasswordChecked)
        this.setState({
            userData,
            toast: {
                type: '',
                message: ''
            }
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const userData = { ...this.state.userData };
        userData.nicPermissionType = (userData.nicPermissionType) ? 1 : 0;
        if (this.validator.allValid()) {
            this.setState({
                submitted: true
            });

            delete userData.confirmPassword;

            userService.save(userData).then((saveResponse) => {
                let response = JSON.parse(JSON.stringify(saveResponse));
                console.log(response);
                if (response && response.statusCode === 200) {
                    this.setState({
                        ...this.INITIAL_STATE
                    });
                    this.props.onClose(userData);
                } else {
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: response.data.description
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);

                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                });
            });
        } else {
            this.validator.showMessages();
        }

    }

    componentWillReceiveProps = ({ showModal, userId }) => {
        this.setState({
            showModal: showModal,
            userId: userId
        }, this.updateFieldData(userId));

    }

    toggle() {
        console.log('hit');

        this.setState({
            showModal: !this.state.showModal,
            ...this.INITIAL_STATE
        });
    }

    componentWillMount = () => {
        const { userData } = this.state;

        userService.getUserPermission().then((results) => {
            if (results.statusCode === 200) {
                userData['permissions'] = results.data.validPermissions.map((element) => {
                    const permissions = {
                        add: element.value.includes("Add") ? false : 'disabled',
                        delete: element.value.includes("Delete") ? false : 'disabled',
                        edit: element.value.includes("Edit") ? false : 'disabled',
                        view: element.value.includes("View") ? false : 'disabled',
                        name: element.name
                    }
                    return permissions;
                });

                this.setState({
                    userData,
                    validPermissions: results.data.validPermissions
                });
            }
        }).catch((error) => {
            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: error.message
                }
            });
        });

    }

    /**
     * Existing user permissions are handled according to show in the ui
     * Cos, All permissions are coming as True/Flase
     */
    editUserPermission = (permissions) => {
        const { userData } = this.state;
        let edited = userData.permissions.map((element, index) => {
            const permission = {
                add: (element.add === 'disabled') ? 'disabled' : permissions[index].add,
                edit: (element.edit === 'disabled') ? 'disabled' : permissions[index].edit,
                delete: (element.delete === 'disabled') ? 'disabled' : permissions[index].delete,
                view: (element.view === 'disabled') ? 'disabled' : permissions[index].view,
                name: element.name
            }
            return permission
        })

        return edited
    }

    updateFieldData = (userId) => {
        reportService.getOperatorsList().then((results) => {
            let operatorsList = {};
            if (results.statusCode === 200) {
                operatorsList = results.data
                if (userId) {
                    userService.getUser(userId).then((results) => {
                        if (results.statusCode === 200) {
                            let userData = results.data;

                            userData['permissions'] = this.editUserPermission(results.data.permissions)
                            userData.nicPermissionType = (userData.nicPermissionType === 1) ? true : false;
                            this.setState({
                                showLoadingSpinner: false,
                                userData,
                                operatorsList
                            })
                        }
                    }).catch((error) => {
                        this.setState({
                            submitted: false,
                            toast: {
                                type: 'warning',
                                message: error.message
                            }
                        });
                    });
                } else {
                    this.setState({
                        showLoadingSpinner: false,
                        operatorsList
                    })
                }
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    componentDidMount = () => {
        const { userId } = this.state;
        this.updateFieldData(userId)
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    renderUserPermissions() {
        const { userData } = this.state;

        return (
            <Table>
                <thead>
                    <tr>
                        <th className="border-0"></th>
                        <th className="border-0">Add</th>
                        <th className="border-0">Edit</th>
                        <th className="border-0">View</th>
                        <th className="border-0">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {userData && userData.permissions && userData.permissions.map((value, index) => {
                        return <tr key={index}>
                            <td className="py-1">{value.name.split(/(?=[A-Z][a-z])/).join(" ")}
                            </td>
                            <td className="py-1">
                                <CustomInput
                                    disabled={value.add === 'disabled'}
                                    id={"add" + index}
                                    type="checkbox"
                                    checked={(value.add && value.add !== 'disabled')}
                                    onChange={(e) => this.handlePermissionChange(index, 'add')} />
                            </td>
                            <td className="py-1">
                                <CustomInput
                                    disabled={value.edit === 'disabled'}
                                    id={"edit" + index}
                                    type="checkbox"
                                    checked={(value.edit && value.edit !== 'disabled')}
                                    onChange={(e) => this.handlePermissionChange(index, 'edit')} /></td>
                            <td className="py-1">
                                <CustomInput
                                    disabled={value.view === 'disabled'}
                                    id={"view" + index}
                                    type="checkbox"
                                    checked={(value.view && value.view !== 'disabled')}
                                    onChange={(e) => this.handlePermissionChange(index, 'view')} /></td>
                            <td className="py-1">
                                <CustomInput
                                    disabled={value.delete === 'disabled'}
                                    id={"delete" + index}
                                    type="checkbox"
                                    checked={(value.delete && value.delete !== 'disabled')}
                                    onChange={(e) => this.handlePermissionChange(index, 'delete')} /></td>
                        </tr>
                    })}
                </tbody>
            </Table>
        )
    }

    render() {
        const { toast, userData, submitted, metadata, operatorsList, showLoadingSpinner, showModal, userId } = this.state;

        return (
            <Modal size="lg" isOpen={this.state.showModal} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type}
                    message={toast.message}></Toast>
                <ModalHeader className="pb-1" >{userId ? 'Edit User' : 'Add New User'}</ModalHeader>
                <ModalBody>
                    {showLoadingSpinner ? <Row >
                        <Col md={12} className="text-center">
                            <Spinner color="primary" />
                        </Col>
                    </Row>
                        :
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="firstName">First Name</Label>
                                        <Input type="text" name="firstName" id="firstName" value={userData.firstName}
                                            onChange={this.handleFormValueChange} placeholder="First Name" />
                                        <span className="text-danger"><small>{this.validator.message('First Name', userData.firstName, 'required')}</small></span>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="lastName">Last Name</Label>
                                        <Input type="text" name="lastName" id="lastName" value={userData.lastName}
                                            onChange={this.handleFormValueChange} placeholder="Last Name" />
                                        <span className="text-danger"><small>{this.validator.message('Last Name', userData.lastName, 'required')}</small></span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="email">Email</Label>
                                        <Input type="email" name="email" id="email" value={userData.email}
                                            onChange={this.handleFormValueChange} placeholder="Email Address" />
                                        <span className="text-danger"><small>{this.validator.message('Email', userData.email, 'required|email')}</small></span>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="mobile">Mobile</Label>
                                        <Input type="tel" name="mobile" maxLength="11" id="mobile" value={userData.mobile}
                                            onChange={this.handleFormValueChange} placeholder="Mobile" />
                                        <span className="text-danger"><small>{this.validator.message('Mobile number', userData.mobile, 'required|telephone')}</small></span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row hidden>
                                <Col md={6}>
                                    <FormGroup>
                                        {/* <Label for="isMerchantUser"></Label> */}
                                        <CustomInput className="pb-2" type="switch" id="isMerchantUser"
                                            name="isMerchantUser" label="Merchant User"
                                            checked={userData.isMerchantUser}
                                            onChange={this.handleMerchantCheckChange} />
                                        {userData && metadata && metadata.merchants.length ?
                                            <CustomDropdown disabled={!userData.isMerchantUser} type={'merchant'}
                                                options={metadata.merchants} selectedId={userData.merchantId}
                                                handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                                            : ''}
                                    </FormGroup>
                                </Col>
                            </Row>

                            {userId !== 1 ?
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <CustomInput type="switch" id="nicPermissionType" name="nicPermissionType"
                                                checked={userData.nicPermissionType}
                                                onChange={this.handleNicMobilePermisionChange}
                                                label="NIC / Mobile Number Permission" />
                                        </FormGroup>
                                        {/* <FormGroup>
                                        <Label for="nicPermissionType">NIC / Mobile Number Permission</Label>
                                        {userData && metadata && metadata.nicPermissionTypes.length ?
                                            <CustomDropdown type={'nicPermissionType'} options={metadata.nicPermissionTypes} selectedId={userData.nicPermissionType} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                                            : ''
                                        }
                                    </FormGroup> */}
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <CustomInput type="switch" id="hasAllPermissions" name="hasAllPermissions"
                                                checked={userData.hasAllPermissions}
                                                onChange={this.handleAllPermisionChange} label="All Permission" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <CustomInput type="switch" id="isActive" name="isActive" label="Activate User"
                                                checked={userData.isActive}
                                                onChange={this.handleActiveStatusChange} />
                                        </FormGroup>
                                    </Col>
                                </Row> : ''}

                            {operatorsList ?

                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="sqr-btn">
                                            <Label for="mobile">Operators</Label>
                                            <CustomDropdown type={'operator'} placeholder={operatorsList[userData.operator]} options={operatorsList} handleOnSelected={this.handleOnDropdownSelected}></CustomDropdown>
                                        </FormGroup>
                                    </Col>
                                </Row> : ''}

                            <Row>
                                <Col md={9}>
                                    {this.renderUserPermissions()}
                                </Col>
                            </Row>
                            {userId ? '' : <Row>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="password">Password</Label>
                                        <Input type="password" name="password" id="password"
                                            onChange={this.handleFormValueChange} />
                                        <span className="text-danger"><small>{this.validator.message('Password', userData.password, 'password|min:8|max:15 ')}</small></span>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="confirmPassword">Confirm Password</Label>
                                        <Input type="password" name="confirmPassword" id="confirmPassword"
                                            onChange={this.handleFormValueChange} />
                                        <span className="text-danger"><small>{this.validator.message('Confirm Password', userData.confirmPassword, 'confirmPassword')}</small></span>
                                    </FormGroup>
                                </Col>
                            </Row>}

                            {userId ? <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="mobile">Change Password</Label>
                                        <CustomInput id={"changePasswordChecked"} name={"changePasswordChecked"}
                                            type="checkbox" checked={userData.changePasswordChecked}
                                            onChange={this.handleChangePassStatusChange} />
                                    </FormGroup>
                                </Col>
                            </Row> : ''}

                            {userData.changePasswordChecked ? <Row>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="password">Password</Label>
                                        <Input type="password" name="password" id="password"
                                            onChange={this.handleFormValueChange} />
                                        <span className="text-danger"><small>{this.validator.message('Password', userData.password, 'password|min:8|max:15 ')}</small></span>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="required">
                                        <Label for="confirmPassword">Confirm Password</Label>
                                        <Input type="password" name="confirmPassword" id="confirmPassword"
                                            onChange={this.handleFormValueChange} />
                                        <span className="text-danger"><small>{this.validator.message('Confirm Password', userData.confirmPassword, 'confirmPassword')}</small></span>
                                    </FormGroup>
                                </Col>
                            </Row> : ''}

                        </Form>}
                </ModalBody>
                <ModalFooter>
                    <Button outline color="danger" onClick={this.handleClose}>Cancel</Button>
                    <SubmitButton shouldDisable={submitted} submitted={submitted}
                        onSubmit={this.handleSubmit}></SubmitButton>
                </ModalFooter>
            </Modal>
        )
    }
}

UserModal.propTypes = {
    userId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
}

export default UserModal;
