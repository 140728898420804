/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import axios from 'axios';
import { get, put, post, remove, patch } from '../utils/http';

/**
 * For the release this original code changed
 * 
 * Name: PackageService
 * Description: This class is responsible to do the CRUD operations for packages.
 * Date: 09/11/2019
 */
class PackageService {

    /**
     * Create package
     * @param package
     */
    async createPackage(pckg) {
        // try {
        //     return await post('lottery-packages', pckg);
        // } catch (error) {
        //     console.log('error : ', error);
        //     return error;
        // }
        return await post('lottery-packages', pckg);
    }

    /**
     * Update the package by given packageId
     * @param packageId
     * @param pckg
     */
    async updatePackage(packageId, pckg) {
        // try {
        //     return await put('lottery-packages/' + packageId, pckg);
        // } catch (error) {
        //     console.log('error : ', error);
        //     return error;
        // }
        return await put('lottery-packages/' + packageId, pckg);
    }

    /**
     * Update the package status by given packageId
     * @param packageId
     * @param pckg
     */
    async updatePackageStatus(packageId, pckg) {
        try {
            return await patch('lottery-packages/' + packageId, pckg);
        } catch (error) {
            console.log('error : ', error);
            return null;
        }
    }

    /**
     * Delete the package by given packageId
     * @param packageId
     * @param pckg
     */
    async deletePackage(packageId) {
        console.log(packageId)
        try {
            return await remove('lottery-packages/' + packageId);
        } catch (error) {
            console.log('error : ', error);
            return null;
        }
    }

    /**
     * Get the package for given packageId
     * @param packageId
     */
    async getPackage(packageId) {
        try {
            return await get('lottery-packages/' + packageId);
        } catch (error) {
            console.log('error : ', error);
            return error;
        }
    }

    /**
     * Get all packages
     */
    async getPackages() {
        try {
            const result = await get('lottery-packages');
            return result.data;
        } catch (error) {
            console.log('error : ', error);
            return error;
        }
    }
}

export default new PackageService();
