/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, Container, Row, Col, Div, Button, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import { drawService } from '../../services/draw-service';
import { lotteryService } from '../../services/lottery-service';
import { smsTemplateService } from '../../services/sms-template-service';
import CustomDropdown from '../common/custom-controls/CustomDropdown';
import './../custom.css';
import { alphabetic } from '../../utils/meta-data';
import { smsTemplate } from '../../utils/sms-template';
import SimpleReactValidator from 'simple-react-validator';
import '../../styles/css/symbols.css';
import {SMS_TEMPLATE} from "../../utils/meta-data";

class UploadResults extends Component {

    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        },
        inputField: {
            special: ''
        },
        bonusInput: {},
        firstField: {

        },
        secondField: {

        },
        jsonFieldShow: false,
        jsonFielsName: ''
    }

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
        });
        this.state = {
            ...this.INITIAL_STATE,
            files: [],
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.data
        };
        this.toggle = this.toggle.bind(this);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleClose = (msg, data = {}) => {
        this.props.onClose(msg, data);
    }

    handleSubmit = () => {
        let { data, firstField, secondField, inputField, bonusInput } = this.state;
        let fieldRECD = {};
        let fieldSMS = {};

        /**
         * For mahajana sampatha and jathika sampatha the fields names are 'RECD'.
         * Here concat all the fields into one.
         */

        Object.keys(firstField).forEach((element, index) => {
            if (element.slice(0, 4) === 'RECD') {
                fieldRECD[element.slice(0, 4)] = (fieldRECD[element.slice(0, 4)] ? fieldRECD[element.slice(0, 4)] : '') + '' + firstField[element];
                fieldSMS[element.slice(0, 4)] = (fieldSMS[element.slice(0, 4)] ? fieldSMS[element.slice(0, 4)] : '') + firstField[element] + ' ';
            } else {
                fieldRECD[element] = firstField[element];
                fieldSMS[element] = firstField[element];
            }
        });

        firstField = fieldRECD

        let ticketResults = {
            lotteryId: data.lotteryId.toString(),
            drawId: data.id.toString(),
            jackpotPrize: inputField.jackpotPrize,
            result: {
                "1": firstField,
                "2": secondField,
                "BONUS": bonusInput,
                "SPECIAL": inputField.special
            }
        }

        let smsTemplateName = Object.keys(bonusInput).length ?
            SMS_TEMPLATE.ANNOUNCE_WINNING_RESULT_WITH_BONUS : SMS_TEMPLATE.ANNOUNCE_WINNING_RESULT;    

        // jackpotPrize should be compulsary
        if (this.validator.allValid()) {
            smsTemplateService.getSingleLotterySmsTemplates(smsTemplateName, data.lotteryId).then((results) => {
                if (results.statusCode === 200) {
                    //This is to get formatted result in the first field
                    let formattedResult = this.getFormattedResult(fieldSMS);
                    const { data } = this.state;
                    const parameters = {
                        lottery_name: data.lotteryName,
                        draw_date: data.drawDate,
                        draw_number: data.drawNumber,
                        wining_numbers: formattedResult,
                        special_numbers: inputField.special ? ' and Special numbers are ' + inputField.special : '',
                        next_main_prize: numberWithCommas(ticketResults.jackpotPrize),
                    };

                    //Bonus numbers need to add only for the bonus template
                    if (Object.keys(bonusInput).length) {
                        //This is to get formatted result in the bonus field
                        parameters.bonus_numbers = this.getFormattedResult(bonusInput)
                    }
                    const sms = smsTemplate.bindParametersToTemplate(results.data.en, parameters);
                    this.handleClose('processing', { showPreview: true, sms: sms, resultsData: ticketResults });
                }

                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.customErrorCode
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            
            this.validator.showMessages();
        }
    }

    componentDidMount = () => {
        const { data } = this.state;
        let bonusFields = []
        lotteryService.getLotteryById(data.lotteryId).then((results) => {

            if (results.statusCode === 200) {
                var lotteryDetails = results.data.lotteryBasics;
                for (let index = 0; index < lotteryDetails.bonusFields; index++) {
                    bonusFields[index] = `B${index + 1}`;
                }

                this.setState({
                    bonusFields: bonusFields
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });

        lotteryService.getAllLotteryFields(data.lotteryId).then((results) => {
            if (results.statusCode === 200) {
                var lotteriesFeilds = results.data

                this.setState({
                    lotteriesFeilds: lotteriesFeilds
                });
                this.handleLotteriesFeilds()
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    handleLotteriesFeilds = () => {
        const { lotteriesFeilds } = this.state;

        lotteriesFeilds.forEach(element => {
            if (element.formatType === 1 && element.isArchived === false) {
                this.setState({ alphabetShow: true, alphaName: element.name });
            }

            // json field handle here
            if (element.formatType === 4 && element.isArchived === false) {
                let key = Object.keys(element.values)[0]
                let jsonFieldData = element.values[key]
                let result = []

                Object.keys(jsonFieldData).map(element => {
                    result.push({
                        id: jsonFieldData[element],
                        name: element,
                    });
                })

                this.setState({ jsonFieldShow: true, jsonFielsName: element.name, jsonFieldData: result });
            }
        });
    }

    handleValueChange = (e) => {
        let inputField = { ...this.state.inputField };
        inputField[e.target.name] = e.target.value;
        this.setState({
            inputField
        });
    }

    handleFirstValueChange = (e) => {
        const{data}= this.state;

        
        let firstField = { ...this.state.firstField };
        firstField[e.target.name] = e.target.value;
        this.setState({
            firstField
        });
    }

    handleSecondValueChange = (e) => {
        let secondField = { ...this.state.secondField };
        secondField[e.target.name] = e.target.value;
        this.setState({
            secondField
        });
    }

    handleBonusValueChange = (e) => {
        let bonusInput = { ...this.state.bonusInput };
        bonusInput[e.target.name] = e.target.value;
        this.setState({
            bonusInput
        });
    }
    dropDownSelectionHandler = (selected, type) => {
        let { firstField, secondField, alphaName, jsonFielsName } = this.state;
        switch (type) {
            case 'alpha_1':
                firstField[alphaName] = selected.id;
                break;
            case 'jsonField':
                firstField[jsonFielsName] = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            firstField,
            secondField,
        });
    };

    handleSecondClick = () => {
        this.setState({
            secondChanceFeild: true
        });
    }

    getFormattedResult(results) {

        let resultSet = '';

        for (let result in results) {
            resultSet += results[result] + ' ';

        }
        resultSet = resultSet.trim();

        return resultSet;
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {

        const {
            toast,
            firstField,
            alphabetShow,
            lotteriesFeilds,
            inputField,
            bonusFields,
            bonusInput,
            alphaName,
            jsonFieldShow,
            jsonFieldData,
            jsonFielsName
        } = this.state;

        const { data } = this.props;

        console.log(jsonFieldData);
        

        return (
            <Modal isOpen={this.state.showModal} className='raisePO' toggle={this.toggle} >
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>

                <Row style={{ padding: '10px', paddingLeft: '30px' }}>
                    <h5>Upload Result for Draw : &nbsp; {data.drawNumber}</h5>
                </Row>

                <Row style={{ padding: '5px', paddingLeft: '15px' }}>
                    <Col md={4}>
                        <label>Draw No : &nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.drawNumber}</div>
                    </Col>
                    <Col md={8}>
                        <label>Lottery : &nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.lotteryName}</div>
                    </Col >

                    <Col md={12}>
                        <label>Draw Date :&nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.drawDate}</div>
                    </Col>
                </Row>

                <Row style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Col md={12}>
                        <FormGroup className="required">
                            <Label for="lotteryName">Jackpot Prize</Label>
                            <Input type="number" id='jackpotPrize' name='jackpotPrize' value={inputField.jackpotPrize} onChange={this.handleValueChange} />
                            <span className="text-danger"><small>{this.validator.message('Jackpot prize', inputField.jackpotPrize, 'required')}</small></span>
                        </FormGroup>
                    </Col>
                </Row>

                {alphabetShow ?
                    <Row style={{ padding: '10px', paddingLeft: '15px' }}>
                        <Col md={12}>
                            <Label for="lotteryName">Select English Letter</Label>
                            <CustomDropdown type={'alpha_1'} placeholder={{ name: 'Select a letter' }} options={alphabetic} selectedId handleOnSelected={this.dropDownSelectionHandler} />
                            <span className="text-danger"><small>{this.validator.message('English Letter', firstField[alphaName], 'required')}</small></span>
                        </Col>
                    </Row> : ''}

                {jsonFieldShow ?
                    <Row style={{ padding: '10px', paddingLeft: '15px' }}>
                        <Col md={12}>
                            <Label for="lotteryName">Select Symbol</Label>
                            <CustomDropdown type={'jsonField'} placeholder={{ name: 'Select a symbol' }} options={jsonFieldData} handleOnSelected={this.dropDownSelectionHandler} />
                            <span className="text-danger"><small>{this.validator.message('Symbol', firstField[jsonFielsName], 'required')}</small></span>
                        </Col>
                    </Row> : ''}

                <Row style={{ padding: '5px', paddingLeft: '30px' }} >
                    <Label for="lotteryName">Winning Number List</Label>
                </Row>

                <Row style={{ padding: '5px', paddingLeft: '30px' }} >
                    {lotteriesFeilds && lotteriesFeilds.map((value, index) => {
                        if (value.formatType === 2) {
                            let name = (value.name === 'RECD') ? value.name + '' + (index < 10 ? '0' + index : index) : value.name;
                            
                                return <Col md={2} key={index}>
                                <FormGroup>
                                    <Label for="lotteryName">{value.name}</Label>
                                    <Input className='upload-result' type="text" id={name} name={name} value={firstField.name} onChange={this.handleFirstValueChange} />
                                    <span className="text-danger"><small>{this.validator.message('', firstField[name], 'required')}</small></span>
                                </FormGroup>
                            </Col>
                            
                            
                        }
                    })}
                     </Row>

                {data.isSpecialDraw ? <Row style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="lotteryName">Special Draw Numbers</Label>
                            <Input type="text" id='special' name='special' value={inputField.special} onChange={this.handleValueChange} />
                        </FormGroup>
                    </Col>
                </Row> : ''}

                {bonusFields && bonusFields.length ?
                    <Row style={{ padding: '5px', paddingLeft: '30px' }} className='upload-result'>
                        <Label for="lotteryName">BONUS</Label>
                    </Row> : ''}

                <Row style={{ padding: '5px', paddingLeft: '30px' }} className='upload-result'>

                    {bonusFields && bonusFields.map((value, index) => {
                        let name = value;
                        return <Col md={2} key={index}>
                            <FormGroup>
                                <Label for="lotteryName">{value}</Label>
                                <Input type="text" id={name} name={name} value={bonusInput.name} onChange={this.handleBonusValueChange} />
                            </FormGroup>
                        </Col>
                    })}
                </Row>

                <Row style={{ padding: '25px' }}>
                    <Col >
                        <Button color="secondary" onClick={this.handleClose} > Cancel </Button>
                    </Col>

                    <Col className='text-right'>
                        <Button color="primary" onClick={this.handleSubmit} > Submit  </Button>
                    </Col>
                </Row>
            </Modal>

        );
    }

}

export default UploadResults;

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}