/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup } from 'reactstrap';
import PackageModal from './PackageModal';
import packageService from '../../services/PackageService';
import CustomGrid from '../common/custom-controls/CustomGrid';
import Toast from '../common/custom-controls/Toast';
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import SearchInput from '../common/custom-controls/SearchInput';

class Packages extends Component {

    packageIdToBeDeleted = 0;

    GRID_CONFIG = {
        columns: [
            {
                id: 1,
                name: 'id',
                headerTitle: 'Id',
                canSort: true,
                width: '10%',
                hidden: true
            },
            {
                id: 2,
                name: 'packageName',
                headerTitle: 'Package Name',
                canSort: true,
                width: '20%'
            },
            {
                id: 3,
                name: 'subscriptionKeyword',
                headerTitle: 'Sub-Key',
                canSort: true,
                width: '10%'
            },
            {
                id: 4,
                name: 'onDemandKeyword',
                headerTitle: 'OD-Key',
                canSort: true,
                width: '10%'
            },
            // {
            //     id: 5,
            //     name: 'typeDescription',
            //     headerTitle: 'Type',
            //     canSort: true,
            //     width: '15%'
            // },
            {
                id: 6,
                name: 'isActive',
                headerTitle: 'Status',
                canSort: true,
                useTemplate: true,
                width: '10%'
            },
            {
                id: 7,
                name: 'actions',
                headerTitle: 'Actions',
                canSort: false,
                width: '15%',
                useTemplate: true,
                templates: [
                    {
                        type: 'EDIT',
                        template: `<button class="btn btn-sm btn-primary"><i class="fas fa-pen"/></button>`
                    },
                    /**
                     * Detele function is not needed for s1
                     * But Need to fix later
                     */

                    // {
                    //     type: 'DELETE',
                    //     template: `<i class="fas fa-trash-alt text-danger" style="font-size:25px"/>`
                    // }
                ]
            }
        ],
        gridData: [],
        meta: {
            pageSize: 8,
            showLoadingSpinner: true
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            showPackageModal: false,
            showDeleteConfirm: false,
            gridConfig: this.GRID_CONFIG,
            packageId: null,
            toast: {
                type: '',
                message: ''
            },
            gridData: []
        }
    }

    /**
     * This event will be called when user clicks on the close or save package dialog.
     * When user clicks on the save button lotteryPackage object will be passed to this method.
     * @param lotteryPackage
     * @returns {Promise<void>}
     */
    modalCloseHandler = async (lotteryPackage) => {
        if (lotteryPackage) {
            this.setState({
                toast: {
                    type: 'success',
                    message: 'Data has been saved.'
                }
            });
            this.updateGridState();
        }
        this.setState({ showPackageModal: false });
    };

    renderGrid = () => {
        const { gridConfig } = this.state;
        if (gridConfig.gridData) {
            return (
                <CustomGrid gridConfig={gridConfig} actionHandler={this.handleActionClick} />
            )
        }
    };

    renderPackageModal = () => {
        if (this.state.showPackageModal) {
            return (
                <PackageModal packageId={this.state.packageId} showModal={this.state.showPackageModal} onClose={this.modalCloseHandler} />
            )
        }
    };

    updateGridState = async () => {
        let { gridConfig, gridData } = this.state;

        const result = await packageService.getPackages();
        if (result) {
            result.map((p) => {
                p.typeDescription = p.subscriptionType === 1 ? 'On-Demand' : 'Subscription';
            });
            gridData = result;
            gridConfig.gridData = gridData;
        }
        gridConfig.meta.showLoadingSpinner = false;
        this.setState({ gridConfig, gridData });
    };

    // componentDidMount = async () => {
    //     this.updateGridState();
    // };

    componentWillMount = async () => {
        this.updateGridState();
    }

    handleActionClick = (params) => {
        switch (params.type) {
            case 'EDIT':
                this.setState(prevState => ({
                    showPackageModal: !prevState.showPackageModal
                }));
                this.openPackageModal(params.data.id);
                break;
            case 'DELETE':
                this.packageIdToBeDeleted = params.data.id;
                this.setState(prevState => ({
                    showDeleteConfirm: !prevState.showDeleteConfirm,
                    toast: {
                        type: '',
                        message: ''
                    }
                }));
                break;
            case 'ACTIVATE':

                break;
            default:
                break;
        }
    };

    /**
     * Filter the grid data based on the user input for the search text
     * @param filterText
     * @param filterColumn
     */
    filterData = (filterText, filterColumn) => {
        let { gridConfig } = this.state;
        if (filterText) {
            gridConfig.gridData = this.state.gridData.filter((data) => {
                const columnData = data[filterColumn].toLowerCase();
                return columnData.indexOf(filterText.toLowerCase()) !== -1
            });
        } else {
            gridConfig.gridData = this.state.gridData;
        }

        this.setState({ gridConfig });
    };

    handleDeleteConfirm = async (params) => {
        if (params !== 'cancel') {
            packageService.deletePackage(this.packageIdToBeDeleted).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        toast: {
                            type: 'success',
                            message: 'Record has been deleted.'
                        }
                    });
                    this.updateGridState();
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                });
            });
        }
        this.setState({ showDeleteConfirm: false })
    };

    renderConfirmModal = () => {
        const { showDeleteConfirm } = this.state;
        if (showDeleteConfirm) {
            return (
                <ConfirmModal isOpen={showDeleteConfirm} message={'Are you sure you want to delete this record?'} onClose={this.handleDeleteConfirm} />
            )
        }
    };

    openPackageModal = (packageId) => {
        this.setState({
            showPackageModal: true,
            packageId: packageId ? packageId : null,
            toast: {
                type: '',
                message: ''
            }
        });
    };

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast } = this.state;
        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={!!toast.message} type={toast.type} message={toast.message} />
                <Row className="justify-content-between">
                    <Col sm={2}>
                        <label><h3>Packages</h3></label>
                    </Col>
                    <Col sm={3} className="text-right">
                        <Button color="success" size="sm" className="btn-rounded" onClick={() => this.openPackageModal()}><i className="fas fa-plus" /> Add New Package</Button>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={3}>
                        <Form>
                            <FormGroup>
                                <SearchInput onChange={this.filterData} name={'packageName'} placeholder={'Package Name'} />
                                {/* <Button color="primary" onClick={() => this.filterData('', 'packageName')}><i className="fas fa-search" /> Search</Button> */}
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {this.renderGrid()}
                    </Col>
                </Row>
                {this.renderPackageModal()}
                {this.renderConfirmModal()}
            </Container>);
    }
}

export default Packages;
