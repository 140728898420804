import { element } from "prop-types";

export const mobileValidation = (mobile) => {
    var re = /(^((947|07|7)(0|1|2|4|5|6|7|8)[0-9]{7})$)/    //Check valid mobile number
    return re.test(mobile);
};

export const smsRequestValidation = (msg) => {
    var stringSeparatedBySpace = /(^(\w+ ?)*$)/
    var nicSL = /^(?:19|20)?\d{2}(?:[0-35-8]\d\d(?<!(?:000|500|36[7-9]|3[7-9]\d|86[7-9]|8[7-9]\d)))\d{4}(?:[vVxX])$/
    var nameArr = msg.split(' ');
    let isNotEmpty = true;
    
    nameArr.map(element => {
        if (!element) {
            isNotEmpty = false;
        }
    })

    return (stringSeparatedBySpace.test(msg)    
        && isNotEmpty
        && (nameArr.length >= 2 || nameArr.length <= 3)) 
};