/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import { Col, Container, Row } from 'reactstrap';
import { checkUserAction } from './../store/actions/auth.actions';
import Login from './auth/Login';
import Dashboard from './dashboard/Dashboard';
import ChangePassword from './auth/ChangePassword';
import Header from './common/header/Header';
import Navigation from './common/navigation/Navigation';
import PrivateRoute from './common/auth/PrivateRoute';
import AdminUsers from './configurations/admin-users/AdminUsers';
import Lotteries from './configurations/lotteries/Lotteries';
import LotteryNew from './configurations/lotteries/LotteryNew';
import SmsTemplates from './configurations/sms-templates/SmsTemplates';
import Draws from './draw/Draws';
import Subscribers from './subscribers/Subscribers';
import SubscribersView from './subscribers/SubscribersView';
import Winners from './winners/Winners';
import Packages from './packages/Packages';
import Campagins from './campaign/Campaigns';
import LotteryEdit from './configurations/lotteries/LotteryEdit';
import LotteryView from './configurations/lotteries/LotteryView';
import Sales from './reports/Sales';
import SalesSummary from './reports/SalesSummary';
import PrizeDistribution from './reports/PrizeDistribution';
import Subscription from './reports/Subscription';
import AllSubscribers from './subscribers/AllSubscribers';
import PrizeClaim from './prizeclaim/PrizeClaim';
import PurchaseOrderView from "./draw/PurchaseOrderView";
import CustomerBlackList from './blacklist/CustomerBlackList';

class App extends Component {

    componentWillMount = () => {
        if (localStorage.getItem('token')) {
            // console.log(localStorage.getItem('token'))
            this.props.checkUserAction();
        }
    }

    renderHeader = () => {
        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            return (
                <Row>
                    <Col md={12}>
                        <Header></Header>
                    </Col>
                </Row>
            )
        }
    }

    renderNavigation = () => {
        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            return (
                <Col md={2} className="d-none d-md-block bg-white sidebar shadow-sm">
                    <div className="sidebar-sticky">
                        <Navigation></Navigation>
                    </div>
                </Col>
            )
        }
    }

    renderPrivateRoutes = () => {
        const { isAuthenticated } = this.props;

        // console.log(isAuthenticated)
        return (
            <Col md={9} className={isAuthenticated ? 'ml-sm-auto col-lg-10 py-4 px-4' : ''}>
                <Row >
                    <Col md={12}>
                        <Switch>
                            <PrivateRoute exact path='/change-password' component={ChangePassword} />
                            <PrivateRoute exact path="/dashboard" component={Dashboard} />
                            <PrivateRoute exact path="/" component={Dashboard} />
                            <PrivateRoute exact path="/draws" component={Draws} />
                            <PrivateRoute exact path="/subscribers" component={Subscribers} />
                            <PrivateRoute exact path="/subscribers/all-subscribers" component={AllSubscribers} />
                            <PrivateRoute exact path="/subscribers/:id" component={SubscribersView} />
                            <PrivateRoute exact path="/winners" component={Winners} />
                            <PrivateRoute exact path="/packages" component={Packages} />
                            <PrivateRoute exact path="/campaigns" component={Campagins} />
                            <PrivateRoute exact path='/admin-users' component={AdminUsers} />
                            <PrivateRoute exact path='/sms-templates' component={SmsTemplates} />
                            <PrivateRoute exact path='/lotteries' component={Lotteries} />
                            <PrivateRoute exact path='/lotteries/new' component={LotteryNew} />
                            <PrivateRoute exact path='/lotteries/:id' component={LotteryView} />
                            <PrivateRoute exact path='/lotteries/:id/edit' component={LotteryEdit} />
                            <PrivateRoute exact path='/reports/sales' component={Sales} />
                            <PrivateRoute exact path='/reports/sales/summary' component={SalesSummary} />
                            <PrivateRoute exact path='/reports/prize-distribution' component={PrizeDistribution} />
                            <PrivateRoute exact path='/reports/subscription' component={Subscription} />
                            <PrivateRoute exact path='/prizeclaim' component={PrizeClaim} />
                            <PrivateRoute exact path='/blacklist' component={CustomerBlackList} />
                            {/* <Redirect to="/login" /> */}
                        </Switch>

                    </Col>
                </Row>
            </Col>
        )
    }

    render() {

        const { isAuthenticated } = this.props;

        return (
            <Container fluid className="px-0">

                {isAuthenticated ?
                    <Router>
                        {this.renderHeader()}
                        <Row>
                            {this.renderNavigation()}
                            {this.renderPrivateRoutes()}
                        </Row>
                    </Router> :
                    <Router>
                        {this.renderPrivateRoutes()}
                        <Route exact path="/login" component={Login} />
                    </Router>}

                {/* <Router>
                    {this.renderHeader()}
                    <Row>
                        {this.renderNavigation()}
                        {this.renderPrivateRoutes()}
                    </Row>
                    <Route exact path="/login" component={Login} />
                </Router> */}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        isAuthenticated: auth.isAuthenticated
    };
}

export default connect(mapStateToProps, { checkUserAction })(App);
