/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, CustomInput, ModalFooter, Table } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';
import { subscriberService } from '../../services/subscriber-service';
import Toast from '../common/custom-controls/Toast';
import SubmitButton from '../common/custom-controls/SubmitButton';
import { errorCode } from "../../utils/error-code";

class SubscribersEdit extends Component {
    INITIAL_STATE = {
        showModal: false,
        submitted: false,
        subscribersData: {
            mobile: '',
            nic: '',
            isActive: true,
        },
        toast: {
            type: '',
            message: ''
        },
    }

    validator = null;

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                mobile: {
                    message: 'The :attribute must be a valid mobile number',
                    rule: (val) => {
                        var re = /(^((947|07|7)(0|1|2|4|5|6|7|8)[0-9]{7})$)/    //Check valid mobile number
                        return re.test(val);
                    },
                    required: true
                }
            }
        });
        this.state = {
            ...this.INITIAL_STATE,
            id: props.subscriberId,
            showModal: props.showModal,
            onClose: props.onClose
        }
        this.toggle = this.toggle.bind(this);
    }

    handleClose = (msg) => {
        let data = { message: 'Mobile number changed successfully' }
        if (msg === 'success') {
            this.props.onClose(data);
        } else {
            this.props.onClose('close');
        }
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }


    handleActiveStatusChange = () => {
        let subscribersData = { ...this.state.subscribersData };
        subscribersData.isActive = !subscribersData.isActive;
        this.setState({
            subscribersData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleFormValueChange = (e) => {
        let subscribersData = { ...this.state.subscribersData };
        subscribersData[e.target.name] = e.target.value;
        this.setState({
            subscribersData,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const subscribersData = { ...this.state.subscribersData };
        delete subscribersData['NIC'];      //NIC update handle separately

        if (this.validator.allValid()) {
            this.setState({
                submitted: true
            });

            subscriberService.save(subscribersData).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        ...this.INITIAL_STATE
                    });
                    this.handleClose('success');
                } else {
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                });
            });
        } else {
            this.validator.showMessages();
        }

    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    componentDidMount = () => {
        const { id } = this.state;
        if (id) {
            subscriberService.getSubscribers(id).then((results) => {
                if (results.statusCode === 200) {
                    const subscribersData = results.data;
                    this.setState({
                        showLoadingSpinner: false,
                        subscribersId: id,
                        subscribersData: subscribersData,
                    });
                } else {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    showLoadingSpinner: false,
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.redirectToSubscribers();
        }
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    render() {
        const { toast, subscribersData, submitted } = this.state;
        return (
            <Modal size="md" isOpen={this.state.showModal} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>
                <div className='modal-header'>
                    <h5 className='modal-title'>
                        Edit Customer
                    </h5>
                    <Col md={6} className="text-right">
                        <CustomInput type="switch" id="isActive" name="isActive" label="Activate" checked={subscribersData.isActive} onChange={this.handleActiveStatusChange} />
                    </Col>
                </div>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <FormGroup className="required">
                                    <Label for="mobile">Mobile</Label>
                                    <Input type="text" name="mobile" maxLength="11" id="mobile" value={subscribersData.mobile} onChange={this.handleFormValueChange} placeholder="Mobile" />
                                    <span className="text-danger"><small>{this.validator.message('Mobile number', subscribersData.mobile, 'mobile')}</small></span>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="danger" onClick={this.handleClose}>Cancel</Button>
                    <SubmitButton shouldDisable={submitted} submitted={submitted} onSubmit={this.handleSubmit}></SubmitButton>
                </ModalFooter>
            </Modal>
        )
    }
}

SubscribersEdit.propTypes = {
    subscriberId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
}

export default SubscribersEdit;
