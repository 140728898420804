/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, Container, Row, Col, Div, Button } from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone'
import Toast from '../common/custom-controls/Toast';
import { BASE_API } from '../../utils/configs';
import { lotteryService } from '../../services/lottery-service';
import { errorCode } from './../../utils/error-code';
import SubmitButton from '../common/custom-controls/SubmitButton';

class DragAndDrop extends Component {

    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        }
    }

    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            if (files.length > 1) {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: 'Please Select only one file'
                    }
                })
            } else {
                this.setState({ files })
            }
        };

        this.state = {
            ...this.INITIAL_STATE,
            files: [],
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.dragData
        };
        this.toggle = this.toggle.bind(this);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
    }

    handleSubmit = () => {
        const { data } = this.state;
        this.setState({ submitted: true });

        if (this.state.files.length) {
            const fileData = new FormData();
            const imagedata = this.state.files[0];

            fileData.append('file', imagedata);
            fileData.append('drawId', data.id);
            fileData.append('drawNumber', data.drawNumber);
            fileData.append('lotteryId', data.lotteryId);

            lotteryService.uploadDrawFiles(fileData).then((results) => {
                // console.log(results)

                if (results.status === 200) {
                    this.setState({
                        ...this.INITIAL_STATE
                    });
                    let msg = {
                        message: 'Data is being uploaded in background, you will get an email once completed.'
                    }
                    this.handleClose(msg);
                } else {
                    this.setState({
                        submitted: false,
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    });
                }


            }).catch((error) => {
                //This request sending through Axios, The response is different than others
                error = JSON.parse(JSON.stringify(error));
                let code = error.message.substring(error.message.length - 3)
                this.setState({
                    submitted: false,
                    toast: {
                        type: 'warning',
                        message: errorCode[code]
                    }
                });
            })

        } else {
            this.setState({
                submitted: false,
                toast: {
                    type: 'warning',
                    message: 'Please Select file'
                }
            });
        }
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    render() {
        const { toast, data, submitted } = this.state;

        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        const dragText = (
            <div>
                <h5>Drag & Drop Tickets file to upload</h5>
                <i className="fa fa-upload" style={{ fontSize: "36px" }}></i>
            </div>
        );

        const insideZone = (files.length) ? files : dragText;

        return (

            <Modal isOpen={this.state.showModal} toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}> </Toast>
                <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <section className="container" style={{ backgroundColor: '#eeeeee', flex: '1', padding: '15px', }}>

                            <h4>Upload Tickets</h4>
                            <br />
                            <div {...getRootProps({ className: 'dropzone' })}
                                style={{ borderBlock: 'solid', outline: '3px dashed #0275d8', padding: '20px', textAlign: 'center', color: '#0275d8' }}>

                                <input {...getInputProps()} accept=".dbf" />

                                {insideZone}

                            </div>
                            <br />

                            <Row style={{ padding: '10px' }}>
                                <Col>
                                    <Button color="secondary" onClick={this.handleClose} > Cancel </Button>
                                </Col>

                                <Col className='text-right'>
                                    <SubmitButton shouldDisable={submitted} submitted={submitted} color="primary" onSubmit={this.handleSubmit} > Submit  </SubmitButton>
                                </Col>
                            </Row>

                        </section>
                    )}
                </Dropzone>
            </Modal>

        );
    }

}

export default DragAndDrop;