/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Container, Button, Row, Col, Form, FormGroup, CustomInput, Input, Label } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import { winnersService } from '../../services/winners-service';
import { customerService } from '../../services/customer-service';

import CustomDropdown from './../common/custom-controls/CustomDropdown';
import './../custom.css'
import { isBigIntLiteral } from '@babel/types';
import { LOTTERY_BOARDS, TIME_OUT, LOTTERY_BOARD_LONG_NAME, LOTTERY_BOARD_SHORT_NAME, PROJECT_NAME } from './../../utils/configs';
import '../../styles/css/symbols.css';
import nlbLogo from './../../assets/nlbLogo.png';
import dlbLogo from './../../assets/dlbLogo.png';
import { days } from '../../utils/meta-data';
import moment from 'moment';
import {reportService} from '../../services/report-service';

const LOTTERY_BOARD_LOGO = (LOTTERY_BOARD_SHORT_NAME === LOTTERY_BOARDS.NLB) ? nlbLogo : dlbLogo;

class PrizeClaim extends Component {
    INITIAL_STATE = {
        toast: {
            type: '',
            message: ''
        },
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE,
            currentCount: TIME_OUT,
            inputField: '',
            selectedLottery: '',
            lotteriesList: [],
            serialVarificaion: true,
            OTPVarificaion: false,
            winnersData: false,
            template: '',
            winnersDetails: '',
            customerDetails:''
        }

    }

    componentWillMount() {
        reportService.getLotteryList().then((results) => {
            if (results.statusCode === 200) {
                var lotteriesList = results.data
                this.setState({
                    lotteriesList: lotteriesList
                });
            }

        }).catch((error) => {
            this.setState({
                toast: {
                    type: 'warning',
                    message: error.message
                }
            })
        });
    }

    handleOnDropdownSelected = (selected, type) => {
        let selectedLottery = { ...this.state.selectedLottery };

        switch (type) {
            case 'lotteriesList':
                selectedLottery = selected.id;
                break;
            default:
                break;
        }

        this.setState({
            selectedLottery,
            toast: {
                type: '',
                message: ''
            }
        });
    }

    handleValueChange = (e) => {
        let inputField = { ...this.state.inputField };
        inputField[e.target.name] = e.target.value;
        this.setState({
            inputField,
            toast: {
                type: '',
                message: ''
            }
        });

    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        });
        //clear interval
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
    }

    timeCount() {
        this.intervalId = setInterval(this.timer.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    componentWillReceiveProps = () => {
        // this.props.history.push('/prizeclaim');
        this.setState({
            toast: {
                type: '',
                message: ''
            },
            serialVarificaion: true,
            OTPVarificaion: false,
            winnersData: false,
            inputField: ''
        })
    }

    handleClose = () => {
        this.props.history.push('/prizeclaim');

        this.setState({
            toast: {
                type: '',
                message: ''
            },
            inputField: '',
            serialVarificaion: true,
            OTPVarificaion: false,
            winnersData: false,
        })

        clearInterval(this.intervalId);
        // this.componentDidMount();
    }

    handleBack = () => {

        this.setState({
            toast: {
                type: '',
                message: ''
            },
            serialVarificaion: true,
            OTPVarificaion: false,
            winnersData: false,
            selectedLottery: ''
        })
        clearInterval(this.intervalId);
        // this.componentDidMount();
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }

    }

    handleSubmit = () => {

        const { inputField, selectedLottery } = this.state;
        // let serial = {
        //     serialNumber: parseInt(inputField.serialNumber)
        // }
        if (inputField.serialNumber && selectedLottery) {
            let requestBody = {
                serialNumber: inputField.serialNumber,
                lotteryId: selectedLottery
            };
            winnersService.serialNumberVarification(requestBody).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        serialVarificaion: false,
                        OTPVarificaion: true,
                        currentCount: TIME_OUT,
                        toast: {
                            type: 'success',
                            message: results.data.smsSentStatus
                        }
                    });

                    this.timeCount();
                }
                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Missing input values.'
                }
            })
        }

    }

    handleOTPSubmit = () => {

        const { inputField, selectedLottery } = this.state;
        let otpAndLotteryId = {
            otpCode: parseInt(inputField.otpCode),
            lotteryId: selectedLottery
        }

        if (inputField.otpCode) {
            winnersService.otpVarification(inputField.serialNumber, otpAndLotteryId).then((results) => {
                let winnersDetails = results.data;
                if (results.statusCode === 200) {
                    this.setState({
                        serialVarificaion: false,
                        OTPVarificaion: false,
                        winnersData: true,
                        toast: {
                            type: 'success',
                            message: 'The OTP verified successfully'
                        },
                        winnersDetails
                    });

                    customerService.getCustomerDetails(winnersDetails.customerId).then((cusData)=>{
                        let customerDetails = cusData.data;
                        if (cusData.statusCode === 200) {
                            inputField.customerName = customerDetails.name;
                            inputField.customerAddress = customerDetails.address;

                            this.setState({customerDetails, inputField});
                            this.templateUpdate();
                        }
                    }).catch((error) => {
                        this.setState({
                            toast: {
                                type: 'warning',
                                message: error.message
                            }
                        })
                    });
                }

                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Enter a valid OTP number'
                }
            })
        }

    }

    handleResend = () => {
        const { inputField, selectedLottery } = this.state;
        let requestBody = {
            serialNumber: inputField.serialNumber,
            lotteryId: selectedLottery
        };
        if (inputField.serialNumber) {
            winnersService.resendSerialNumberVarification(requestBody).then((results) => {
                if (results.statusCode === 200) {
                    this.setState({
                        toast: {
                            type: 'success',
                            message: results.data.smsSentStatus
                        },
                        currentCount: TIME_OUT
                    });

                    this.timeCount();
                }

                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }
            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Re-Enter a valid Serial Number'
                },
                currentCount: TIME_OUT

            })

            this.componentDidMount();
        }
    }

    handleCustomerDetailsSubmit =() =>{
        const state=this.state
        const { inputField, winnersDetails } = this.state;
        if(inputField.customerAddress||inputField.customerName){
            let data={
                address:inputField.customerAddress,
                name:inputField.customerName
            }
            winnersService.updateCustomerDetails(winnersDetails.customerId, data).then((results) => {
                let customerDetails = results.data;
                if (results.statusCode === 200) {
                    this.setState({customerDetails})
                    this.templateUpdate();
                    this.setState({
                        toast: {
                            type: 'success',
                            message: 'Winner details successfully saved'
                        }
                    })
                }

                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });

        }else{
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Re-Enter a valid Name and Address'
                },
                currentCount: TIME_OUT

            })
        }
    }

    handleClear = () => {
        this.setState({
            inputField: {
                serialNumber: ''
            }
        })
    }

    printOrder = () => {
        var content = document.getElementById("printme");

        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    redirectToSummary = () => {
        this.props.history.push('/subscribers/all-subscribers');
    }

    templateUpdate = () => {
        const { winnersDetails, inputField, customerDetails } = this.state;
        let win_res1 = '';
        let win_res2 = '';
        let winning_chance_list = '';

        if (winnersDetails) {
            winnersDetails.winningResult[1] && Object.keys(winnersDetails.winningResult[1]).map(index => {
                if (winnersDetails.JsonList && Object.keys(winnersDetails.JsonList)[0] === index) {
                    let jsonData = winnersDetails.JsonList[index];
                    let symbol = '';

                    switch (Object.keys(winnersDetails.JsonList)[0]) {
                        case 'LAGNA':
                            Object.keys(jsonData).map(element => {
                                if (jsonData[element] === winnersDetails.winningResult[1][index]) {
                                    symbol = element
                                }
                            })

                            //TODO: Need to add symbol here
                            win_res1 += `<td style="padding: 15px; border: 1px #ededed solid;font-weight: 700;">`
                                + `<span class='signs'>${symbol}</span>`
                                + `<span style="font-size: 12px;"> ${winnersDetails.winningResult[1][index]}</span></td>`
                            break;

                        default:
                            win_res1 += `<td style="padding: 15px; border: 1px #ededed solid;font-weight: 700;">${winnersDetails.winningResult[1][index]}</td>`
                            break;
                    }

                } else if (index === 'RECD') { //Here MS & JS having field name RECD contains all winning values, Separating here
                    Object.keys(winnersDetails.winningResult[1][index]).map(i => {
                        win_res1 += `<td style="padding: 15px; border: 1px #ededed solid;font-weight: 700;">${winnersDetails.winningResult[1][index][i]}</td>`
                    })

                } else {
                    win_res1 += `<td style="padding: 15px; border: 1px #ededed solid;font-weight: 700;">${winnersDetails.winningResult[1][index]}</td>`
                }

            })

            winnersDetails.winningResult[2] && Object.keys(winnersDetails.winningResult[2]).map(index => {
                win_res2 += `<td style="padding: 15px; border: 1px #ededed solid;font-weight: 700;">${winnersDetails.winningResult[2][index]}</td>`
            })


            winnersDetails.winningChanceList && Object.keys(winnersDetails.winningChanceList).map(win_index => {
                winning_chance_list += `<tr>
                                       <td style="padding: 5px; border: 1px solid rgb(237, 237, 237);">${winnersDetails.winningChanceList[win_index].drawType}</td>
                                       <td style="padding: 5px; border: 1px solid rgb(237, 237, 237);">${winnersDetails.winningChanceList[win_index].prizeType}</td>
                                       <td style="padding: 5px; border: 1px solid rgb(237, 237, 237);">${winnersDetails.winningChanceList[win_index].prizeDescription}</td>
                                        </tr>`

            })

            let matchingDay = days.filter(day => {
                return day.id === winnersDetails.drawDay
            })

            // Begin Template
            const template = `
                <div id="printthis">
                                    <div style="margin: auto; width: 100%; max-width: 700px; border: 1px solid rgb(237, 237, 237); padding: 20px; font-family: sans-serif; font-size: 12px; font-weight: 700;">
                                        <div style="text-align: center;">
                                            <h5 style="font-weight: 700;">SMS Lottery Sales &amp; Distribution Solution</h5>
                                            <h6 style="font-weight: 700;">zMessenger (Pvt) Ltd ${winnersDetails.shortCode}</h6>
                                            <p>Winner Verification &amp; Money Claim Form (20,000.00 LKR Upward)</p>
                                            <h4 style="font-weight: 700;">${LOTTERY_BOARD_LONG_NAME}</h4>
                                            <div style="width: 100%; display: flex; justify-content: flex-end;">
                                                <div style="flex: 0 0 40%; text-align: left; font-weight: 700;">Reference:</div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div style="text-align: center;">
                                            <h4 style="font-weight: 700;">${winnersDetails.lotteryName}</h4>
                                            <p>${matchingDay[0].name}</p>
                                        </div>
                                        <div style="display: flex; flex-direction: row;">
                                            <table style="flex: 0 0 30%; margin: 0px 15px;">
                                                <tbody>
                                                    <tr>
                                                        <td style="padding: 5px;">Draw No:</td>
                                                        <td style="padding: 5px;">${winnersDetails.drawNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding: 5px;">Draw date:</td>
                                                        <td style="padding: 5px;">${winnersDetails.drawDate}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style="flex: 0 0 70%; display: flex; align-items: center;">
                                                <div style="padding: 10px; flex: 0 0 70%;">
                                                    <h5 style="font-weight: 700;">${winnersDetails.ticketNumber}</h5>
                                                    <div className="barcode">
                                                        <img style="max-width: 50%;" src="https://barcode.tec-it.com/barcode.ashx?data=${inputField.serialNumber}" alt="Bar Code" />
                                                    </div>
                                                </div>
                                                <div className="qr"style="padding: 5px; flex: 0 0 30%;">
                                                    <img style="max-width: 50%;" src="https://barcode.tec-it.com/barcode.ashx?data=${inputField.serialNumber}&code=QRCode&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&codepage=Default&qunit=Mm&quiet=0&eclevel=L" alt="QR Code" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div style="text-align: center;"><h5 style="font-weight: 700;">Winner's Details</h5></div>
                                        <div className="winnerDetails" style="display: flex; justify-content: space-between; flex-flow: row wrap;">
                                            
                                            <table style="flex: 0 0 100%;">
                                            <tbody>
                                                <tr>
                                                    <td style="padding: 5px;"><strong>Winner's Name: </strong></td>
                                                    <td style="padding: 5px;">${customerDetails.name}</td>
                                                    <td style="padding: 5px;"><strong>Generated date: </strong></td>
                                                    <td style="padding: 5px;">${moment().format('YYYY-MM-DD')}</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 5px;"><strong>Address: </strong></td>
                                                    <td style="padding: 5px;">${customerDetails.address}</td>
                                                    <td style="padding: 5px;"><strong>NIC Number: </strong></td>
                                                    <td style="padding: 5px;">${customerDetails.NIC}</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 5px;"><strong>Mobile: </strong></td>
                                                    <td style="padding: 5px;">${customerDetails.mobile}</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 5px;"><strong>Prize Value: </strong></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <table style="margin-bottom: 15px; width: 100%;">
                                                <thead>
                                                    <tr>
                                                        <th style="background-color: rgb(237, 237, 237); padding: 5px; width: 35%; border: 1px solid rgb(237, 237, 237);"><strong>Chance</strong></th>
                                                        <th style="background-color: rgb(237, 237, 237); padding: 5px; width: 35%; border: 1px solid rgb(237, 237, 237);"><strong>Type</strong></th>
                                                        <th style="background-color: rgb(237, 237, 237); padding: 5px; width: 30%; border: 1px solid rgb(237, 237, 237);"><strong>Description</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${winning_chance_list}
                                                </tbody>
                                            </table>
                                        </div>
                                        <h5 style="font-weight: 700;">${LOTTERY_BOARD_SHORT_NAME} Counters Division Use Only</h5>
                                        <hr/>
                                        <div>
                                            <table style="width: 100%;">
                                                <tbody>
                                                <tr>
                                                    <td width="20%" style="padding: 8px;">Submitted Date</td>
                                                    <td width="20%" style="padding: 8px;">:&nbsp;&nbsp;<span style="display: block; border-bottom: 1px dotted rgb(0, 0, 0);"></span></td>
                                                    <td width="30%" style="padding: 8px;">Verified by(${LOTTERY_BOARD_SHORT_NAME} Counters)</td>
                                                    <td width="30%" style="padding: 8px;">:&nbsp;&nbsp;<span style="display: block; border-bottom: 1px dotted rgb(0, 0, 0);"></span></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <table style="width: 100%;">
                                                <tbody>
                                                <tr>
                                                    <td width="40%" style="padding: 8px;" colSpan="2">
                                                        <span style="display: block; border-bottom: 1px dotted rgb(0, 0, 0);"></span>
                                                        <span style="display: block; text-align: center;">Customer Signature</span>
                                                    </td>
                                                    <td width="30%" style="padding: 8px;">Authorized by<br />(FO/ AGM-F)/ (DGM-F)</td>
                                                    <td width="30%" style="padding: 8px;">:&nbsp;&nbsp;<span style="display: block; border-bottom: 1px dotted rgb(0, 0, 0);"></span></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                        <h5 style="font-weight: 700;">${LOTTERY_BOARD_SHORT_NAME} Finance Division Use Only</h5>
                                        <hr/>
                                        <div>
                                            <table style="width: 100%;">
                                                <tbody>
                                                <tr>
                                                    <td width="20%" style="padding: 8px;">Received Money</td>
                                                    <td colSpan="3" style="padding: 8px;">
                                                        <div style="display: flex; align-items: center; flex-direction: row;">
                                                            <span style="padding: 8px;">Cash</span>
                                                            <span style="padding: 8px; display: inline-block; border: 1px solid rgb(0, 0, 0); width: 30px; height: 30px;"></span>
                                                            <span style="padding: 8px;">Cheque</span>
                                                            <span style="padding: 8px; display: inline-block; border: 1px solid rgb(0, 0, 0); width: 30px; height: 30px;"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <table style="width: 100%;">
                                                <tbody>
                                                <tr>
                                                    <td width="30%" style="padding: 8px;">Amount</td>
                                                    <td width="30%" style="padding: 8px;">:&nbsp;&nbsp;<span style="display: block; border-bottom: 1px dotted rgb(0, 0, 0);"></span></td>
                                                    
                                                    <td width="30%" style="padding: 8px; text-align: center;" rowSpan="2">
                                                        <span style="display: block; border-bottom: 1px dotted rgb(0, 0, 0);"></span>
                                                        <span style="display: block;">Handed over<br />( Cash / Cheque )<br/>(Cash Counter Staff/ Shroff)</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="30%" style="padding: 8px;">Customer Signature</td>
                                                    <td width="30%" style="padding: 8px;">:&nbsp;&nbsp;<span style="display: block; border-bottom: 1px dotted rgb(0, 0, 0);"></span></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr/>
                                        <div style="display: flex; flex-direction: row;">
                                            <div style="flex: 0 0 50%; align-items: center;justify-content: center;display: flex;">
                                                <img src="${LOTTERY_BOARD_LOGO}" alt="${LOTTERY_BOARD_LONG_NAME}"/>
                                            </div>
                                            <div style="flex: 0 0 50%; align-items: center;justify-content: center;display: flex;">
                                                <img src="${winnersDetails.appLogoUrl}" alt="${PROJECT_NAME}"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                `;

            // End Template
            this.setState({
                template
            })
        }



    }

    render() {
        const { toast, inputField, serialVarificaion, OTPVarificaion, winnersData, winnersDetails, template, currentCount, lotteriesList } = this.state;

        let timeSec = (currentCount >= 10) ? currentCount : "0" + currentCount;

        return (
            <Container fluid>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}> </Toast>
                <Row>
                    <Col md={8}>
                        <label><h4>High Prize Verification</h4></label>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="card">
                            <div className="card-body">
                                {serialVarificaion ?
                                    <Row className="justify-content-center">
                                        <Col lg="3">
                                            <Label className = "mb-3">Lottery Name</Label>
                                            <CustomDropdown type={'lotteriesList'}
                                            placeholder={{ name: 'Lottery Name' }}
                                            options={lotteriesList}
                                            handleOnSelected={this.handleOnDropdownSelected} />
                                        </Col>
                                        <Col lg="6">
                                            <Form>
                                                <div className="form-group resend_btn">
                                                    <Button size="lg" color="link" className="float-right" onClick={this.handleClear} >
                                                        <i className="fas fa-cut" aria-hidden="true"></i>
                                                    </Button>
                                                    <Label>Serial Number</Label>
                                                    <Input type="number" className="form-control-rounded" name={'serialNumber'} onChange={this.handleValueChange} value={inputField.serialNumber} placeholder={'Serial Number'}></Input>
                                                </div>
                                            </Form>
                                            <div className="text-right" >
                                                <Button color="primary" className="btn-rounded mt-2" onClick={this.handleSubmit} >Submit</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    : ''}
                                {OTPVarificaion ?
                                    <Row className="justify-content-center">
                                        <Col lg="6">

                                            <Form>
                                                <div className="form-group resend_btn">
                                                    <Label>OTP</Label>
                                                    {currentCount ?
                                                        // <span className="float-right" disabled >Resend OTP in: {timeSec}</span>
                                                        <Button color="link" className="float-right" disabled > Resend OTP in: {timeSec}  </Button>
                                                        :
                                                        // <a className="float-right button" color="info" onClick={this.handleResend} >Resend</a>
                                                        <Button color="link" className="float-right" onClick={this.handleResend} > Resend  </Button>
                                                    }
                                                    <Input className="form-control-rounded" type="number" name={'otpCode'} onChange={this.handleValueChange} value={inputField.otpCode} placeholder={'OTP Number'}></Input>
                                                </div>

                                            </Form>
                                            <Row>
                                                <Col className='text-left'>
                                                    <Button color="secondary" className="btn-rounded mt-2" onClick={this.handleBack} > Back  </Button>
                                                </Col>

                                                <Col className='text-right'>
                                                    <Button color="primary" className="btn-rounded mt-2" onClick={this.handleOTPSubmit} > Submit  </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    : ''}


                                {winnersData ?
                                    <div>

                                        <Row md={12} style={{ padding: '60px' }}>
                                            <Col md={4}>
                                                <Col className='text-left'>
                                                    <Label>Name</Label>
                                                    <Input size="lg" className="form-control-rounded" type="text" name={'customerName'} onChange={this.handleValueChange} value={inputField.customerName} placeholder={'Customer Name'}></Input>
                                                </Col>
                                                <Col className='text-left' style={{ marginTop: '5px' }}>
                                                    <Label>Address</Label>
                                                    <Input size="lg" className="form-control-rounded" type="textarea" name={'customerAddress'} onChange={this.handleValueChange} value={inputField.customerAddress} placeholder={'Customer Address'}></Input>

                                                </Col>
                                                <Col className='text-right' style={{ marginTop: '10px' }}>
                                                    <Button color="primary" size="sm" className="btn-rounded" onClick={this.handleCustomerDetailsSubmit} > Add  </Button>
                                                </Col>
                                            </Col>
                                            <Col md={8}>
                                                <Row style={{paddingTop: 10, paddingBottom: 10}}>
                                                    <Col className='text-right'>
                                                        <Button color="primary" size="sm" onClick={this.printOrder} > <i className="fas fa-print"></i> Print</Button>
                                                    </Col>
                                                </Row>

                                                <div className="content" dangerouslySetInnerHTML={{ __html: template }}></div>


                                                <div id='printme' className="content" dangerouslySetInnerHTML={{ __html: template }}></div>

                                            </Col>
                                        </Row>
                                        <Row style={{ padding: '25px' }}>
                                            <Col className='text-left'>
                                                <Button color="secondary" onClick={this.handleClose} > Cancel  </Button>
                                            </Col>
                                        </Row>


                                    </div>
                                    : ''}
                                <iframe id="ifmcontentstoprint" hidden style={{ height: '0px', width: '0px', position: ' absolute' }}></iframe>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >);
    }
}



export default PrizeClaim;
