/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import { get, post, put, remove, patch } from '../utils/http';



const getLotteryList = () => {
    return get(`reports/lotteries`);
}

const getOperatorsList = () => {
    return get(`reports/operators`);
}

const prizeTransferMethodList = () => {
    return get(`reports/prize-transfer-methods`);
}

const getPackageList = () => {
    return get(`reports/packages`);
}

const save = (subscriber) => {
    if (subscriber.id) {
        return put(`customers/${subscriber.id}`, subscriber)
    } else {
        return post(`customers`, subscriber)
    }
}


const updateSubscribers = (subscriber) => {
    return put(`customers/${subscriber.id}`, subscriber);
}

const archiveSubscribers = (subscriberId) => {
    return put(`customers/archive/${subscriberId}`);
}

const archiveBulkSubscribers = (bulkSubscriberId, status) => {
    return put(`customers/archive/${status}/${bulkSubscriberId}`, bulkSubscriberId);
}

const deleteSubscribers = (subscriberId) => {
    return remove(`customers/${subscriberId}`);
}

const deleteBulkSubscribers = (bulkSubscriberId) => {
    return remove(`customers/archive/${bulkSubscriberId}`);
}

export const reportService = {
    getLotteryList,
    getOperatorsList,
    prizeTransferMethodList,
    getPackageList
};

