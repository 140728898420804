/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, Container, Row, Col, Div, Button, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone'
import Toast from '../common/custom-controls/Toast';
import { BASE_API } from '../../utils/configs';
import './../custom.css'

class RaisePurchaseOrder extends Component {

    INITIAL_STATE = {
        showModal: false,
        toast: {
            type: '',
            message: ''
        },
        raiseInputField: []
    }

    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            this.setState({ files })
        };

        this.state = {
            ...this.INITIAL_STATE,
            files: [],
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.dragData
        };
        this.toggle = this.toggle.bind(this);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleClose = () => {
        this.props.onClose('msg');
    }

    handleSubmit = () => {
        console.log('Hello');
    }

    handleValueChange = (e) => {
        let raiseInputField = { ...this.state.raiseInputField };
        raiseInputField[e.target.name] = e.target.value;
        this.setState({
            raiseInputField
        });

    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    render() {

        const { data, raiseInputField } = this.state;

        return (
            <Modal isOpen={this.state.showModal} className='raisePO' toggle={this.toggle}>
                <Row style={{ padding: '10px', paddingLeft: '30px' }}>
                    <h5>Raise Purchase Order</h5>
                </Row>

                <Row style={{ padding: '5px', paddingLeft: '15px' }}>
                    <Col md={4}>
                        <label>Draw No : &nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.drawNumber}</div>
                    </Col>
                    <Col md={8}>
                        <label>Lottery : &nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.lotteryName}</div>
                    </Col >
                    {/* <Col md={4}>
                        <label>Draw Name :</label>
                        <div style={{ display: 'inline-block' }}>{}</div>
                    </Col> */}

                    <Col md={12}>
                        <label>Draw Date :&nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.drawDate}</div>
                    </Col>

                    {/* <Col style={{ display: 'inline' }}><h6>Draw No :</h6> <span>888</span></Col>
                    <Col><h6></h6> <span>888</span></Col>
                    <Col><h6>Draw Name :</h6> <span>888</span></Col>
                    <Col><h6>Draw Date :</h6> <span>888</span></Col> */}
                </Row>


                <Row style={{ paddingLeft: '15px', paddingTop: '10px' }}>
                    <Col md={5}>
                        <Button className='sqr-btn' color="secondary" onClick={this.handleClose} > Ticket Sold : 20002 </Button>
                    </Col>

                    <Col md={7}>
                        <Button className='sqr-btn' color="primary" onClick={this.handleSubmit} > Ticket Remaining : 522  </Button>
                    </Col>
                </Row>

                <Row style={{ padding: '5px', paddingLeft: '30px', paddingRight: '30px', paddingTop: '35px' }}>
                    <label>Number of Tickets to order</label>
                    <Input type="number" name="noOfTickets" id="noOfTickets" value={raiseInputField.noOfTickets} onChange={this.handleValueChange} placeholder="Number of Tickets to order" />

                </Row>

                <Row style={{ padding: '25px' }}>
                    <Col >
                        <Button color="secondary" onClick={this.handleClose} > Cancel </Button>
                    </Col>

                    <Col className='text-right'>
                        <Button color="primary" onClick={this.handleSubmit} > Submit  </Button>
                    </Col>
                </Row>
            </Modal>

        );
    }

}

export default RaisePurchaseOrder;