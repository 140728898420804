/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { Modal, FormGroup, Row, Col, Div, Button, CustomInput, Label, ModalFooter, Input } from 'reactstrap';
import Toast from '../common/custom-controls/Toast';
import { drawService } from '../../services/draw-service';
import './../custom.css'
import ConfirmModal from '../common/custom-controls/ConfirmModal';
import { STRING } from '../../store/constants/common';

class ReturnTickets extends Component {

    INITIAL_STATE = {
        showModal: false,
        showConfirmModal: false,
        toast: {
            type: '',
            message: ''
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.INITIAL_STATE,
            files: [],
            showModal: props.showModal,
            onClose: props.onClose,
            data: props.dragData,
            inputField: {
                noOfTickets: props.dragData.isTicketReturn ? '' : 0,
                isTicketReturn: props.dragData.isTicketReturn
            }
        };
        this.toggle = this.toggle.bind(this);
    }

    handleToggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }

    handleClose = (msg) => {
        this.props.onClose(msg);
        this.setState({
            ...this.INITIAL_STATE
        })
    }

    handleConfirmModalClose = (params) => {
        if (params !== 'cancel') {
            const { data, inputField } = this.state;
            let ticketReturn = {
                lotteryId: data.lotteryId,
                drawId: data.id,
                returnCount: inputField.noOfTickets,
                isTicketReturn: inputField.isTicketReturn
            }
            drawService.ticketReturnManual(ticketReturn).then((results) => {
                // console.log(results)
                if (results.statusCode === 200) {
                    this.handleClose(results.data);
                }

                if (results.statusCode === 210) {
                    this.setState({
                        toast: {
                            type: 'warning',
                            message: results.data.description
                        }
                    })
                }

            }).catch((error) => {
                this.setState({
                    toast: {
                        type: 'warning',
                        message: error.message
                    }
                })
            });
        }
        this.setState({ showConfirmModal: false })
    }

    handleSubmit = () => {
        const { inputField } = this.state;
        if (inputField.noOfTickets || !inputField.isTicketReturn) {
            this.setState({
                showConfirmModal: true
            })
        } else {
            this.setState({
                toast: {
                    type: 'warning',
                    message: 'Enter number of tickets to return'
                }
            })
        }

    }

    handleValueChange = (e) => {
        let inputField = { ...this.state.inputField };
        inputField[e.target.name] = e.target.value;
        this.setState({
            inputField
        });

    }

    handleChangePassStatusChange = (e) => {
        let inputField = { ...this.state.inputField };
        // inputField.isTicketReturn = !inputField.isTicketReturn;
        // inputField.noOfTickets = inputField.isTicketReturn ? '' : 0;
        this.setState({
            inputField,
        });
    }

    handleToastClose = (status) => {
        if (!status) {
            this.setState({
                toast: {
                    type: '',
                    message: ''
                }
            })
        }
    }

    componentWillReceiveProps = ({ showModal }) => {
        this.setState({
            showModal: showModal
        });
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    renderConfirmModal = () => {
        const { showConfirmModal } = this.state;
        if (showConfirmModal) {
            return (
                <ConfirmModal isOpen={showConfirmModal} message={STRING.MESSAGE.CONFIRM_RETURN_TICKET} onClose={this.handleConfirmModalClose} />
            )
        }
    }

    render() {

        const { toast, data, inputField } = this.state;

        return (
            <Modal isOpen={this.state.showModal} className='raisePO' toggle={this.toggle}>
                <Toast handleOnClose={this.handleToastClose} isOpen={toast.message ? true : false} type={toast.type} message={toast.message}></Toast>

                <Row style={{ padding: '10px', paddingLeft: '30px' }}>
                    <h5>Return Tickets</h5>
                </Row>

                <Row style={{ padding: '5px', paddingLeft: '15px' }}>
                    <Col md={4}>
                        <label>Draw No : &nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.drawNumber}</div>
                    </Col>
                    <Col md={8}>
                        <label>Lottery : &nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.lotteryName}</div>
                    </Col >

                    <Col md={12}>
                        <label>Draw Date :&nbsp;</label>
                        <div style={{ display: 'inline-block' }}>{data.drawDate}</div>
                    </Col>
                </Row>

                {/* <Row form className="p-3">
                    <Col md={12}>
                        <FormGroup>
                            <CustomInput type="checkbox" id="isTicketReturn" label="Ticket return enabled"
                                onChange={this.handleChangePassStatusChange} checked={inputField.isTicketReturn} />
                        </FormGroup>
                    </Col>
                </Row> */}

                <Row style={{ padding: '5px', paddingLeft: '30px', paddingRight: '30px', paddingTop: '10px' }}>
                    <label>Number of Tickets to Return</label>
                    <Input type="number" name="noOfTickets" id="noOfTickets" value={inputField.noOfTickets} onChange={this.handleValueChange} placeholder="Number of Tickets to Return" />
                    {/* <Input disabled={!inputField.isTicketReturn} type="number" name="noOfTickets" id="noOfTickets" value={inputField.noOfTickets} onChange={this.handleValueChange} placeholder="Number of Tickets to Return" /> */}
                </Row>

                <Row style={{ padding: '25px' }}>
                    <Col >
                        <Button color="secondary" onClick={this.handleClose} > Cancel </Button>
                    </Col>

                    <Col className='text-right'>
                        <Button color="primary" onClick={this.handleSubmit} > Submit  </Button>
                    </Col>
                </Row>
                {this.renderConfirmModal()}
            </Modal>

        );
    }

}

export default ReturnTickets;